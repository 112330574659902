import { IonButton, IonModal } from "@ionic/react";
import * as DetectRTC from "detectrtc";
import React from "react";

function loadDetectRTC() {
  return new Promise<void>((resolve) => {
    DetectRTC.load(() => {
      (window as any).DetectRTC = DetectRTC;
      resolve();
    });
  });
}
interface PresupportErrorProps {
  isPresupportError: boolean;
  setIsPresupportError: (isPresupportError: boolean) => void
}

export const PresupportError = ({ isPresupportError, setIsPresupportError }: PresupportErrorProps) => {
  return (
    <IonModal
      isOpen={isPresupportError}
      onDidDismiss={() => setIsPresupportError(false)}
      className="custom-modal [&>.ion-delegate-host]:p-6 rounded-xl"
    >
      <div className="text-center text-base font-semibold mb-4">
        Iremos solicitar acesso à sua câmera e microfone! Para prosseguir, você
        deve PERMITIR o acesso!
      </div>
      <IonButton
        id="pre-support-check-btn"
        fill="solid"
        shape="round"
        expand="block"
        className="mb-0 mt-3 font-semibold"
        onClick={() => setIsPresupportError(false)}
        style={{ "--background": "#482F9F" }}
        color="primary"
      >
        Cancelar
      </IonButton>
    </IonModal>
  );
};

const permissionModal = async (setIsPresupportError: (isPresupportError: boolean) => void) => {
  const permissions = navigator.mediaDevices.getUserMedia({ audio: true, video: true,});
  permissions
    .then((stream) => {
      setIsPresupportError(false);
      return true;
    })
    .catch((err) => {
      setIsPresupportError(true);
      console.error(`${err.name} : ${err.message}`);
      return false;
    });
};

export const checkconnection = async (setIsPresupportError: (isPresupportError: boolean) => void) => {
  await loadDetectRTC();
  const supportsWebRTC = DetectRTC.isWebRTCSupported;
  if (!supportsWebRTC) {
    return await permissionModal(setIsPresupportError);
  } else {
    const isPermissionAlreadyGranted = DetectRTC.isWebsiteHasWebcamPermissions && DetectRTC.isWebsiteHasMicrophonePermissions;
    const hasRequiredMediaOnDevice = DetectRTC.hasWebcam && DetectRTC.hasMicrophone;
    const videoInputDevices = DetectRTC.videoInputDevices && DetectRTC.videoInputDevices[0]?.deviceId;
    if (isPermissionAlreadyGranted && hasRequiredMediaOnDevice && videoInputDevices) {
      setIsPresupportError(false);
      return true;
    } else {
      return await permissionModal(setIsPresupportError);
    }
  }
};
