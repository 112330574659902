import React from "react";
import { IonLabel, useIonToast, IonImg } from "@ionic/react";
import Layout from "../../../components/Layout";
import CustomFormik from "../../../components/UI/Formik";
import { patientLoginInputFields as formInputFields } from "./formFields";
import * as Yup from "yup";
import Grid from "../../../components/Grid";
import RightGrid from "../../../components/Grid/RightGrid";
import LeftGrid from "../../../components/Grid/LeftGrid";
import { IMG } from "../../../styles/assets";
import moment from "moment";
import { corporation, navigateToRoute, useDidMountHook } from "../../../utils/helper";
import authService from "../../../services/auth-service";
import { useState } from "react";
import storeService from "../../../services/store.service";
import { LOGIN_PATIENT } from "../../../Redux/action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { AxiosError, AxiosResponse } from "axios";

const PatientLogin = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isTokenLoading, setIsTokenLoading] = useState<boolean>(false);
  const [isTokenError, setIsTokenError] = useState<boolean>(false);

  const [present] = useIonToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useDidMountHook(() => {
    patientTokenValidate();
  })

  const patientTokenValidate = () => {
    const query = new URLSearchParams(location.search);
    const token = query.get("token");
    const isPA = query.get("is_pa") || "";
    const isPaid = query.get("is_paid") || "";
    const isSpeciality = query.get("is_speciality") || "";
    if (token && isPA) {
      setIsTokenLoading(true);
      authService.patientToken(token, { isPaid: isPaid, isPA, isSpeciality })
        .then(async (res) => {
          setIsTokenLoading(false);
          const { data } = res;
          if (data?.isActive === false) {
            await storeService.set("patient_signup", data);
            navigateToRoute(navigate, "/patient/signup", true);
          } else {
            await storeService.set("user", data);
            await storeService.set("isPatientLink", true);
            await storeService.set("currentUserType", "patient");
            dispatch({ type: LOGIN_PATIENT, payload: { user: { ...data, profilePic: data?.avatar?.url || data?.avatar }, isPatientLink: true, currentUserType: "patient" } });
            if (data?.isPA && data?.isPA === "true") {
              const user = { corporation, cpf: data.cpf, isPatientlink: true };
              const encodedUser = await btoa(JSON.stringify(user));
              window.location.href = `home?user=${encodedUser}`;
            } else navigateToRoute(navigate, "/home");
          }
        })
        .catch((err: AxiosError) => {
          setIsTokenError(true);
          console.error("error from auth token of avus", err);
          if (err?.response?.status === 401) {
            present({ message: "O token expirou", duration: 3000 });
            window.location.href = "https://www.avus.com.br/";
          }
        })
    }
  }

  const submitLogin = (values: { cpf?: string; birthdate?: Date | string }, setSubmittng: (val: boolean) => void) => {
    const data = {
      cpf: values?.cpf?.replace(/-|_|\./g, ""),
      corporation,
      birthdate: moment(values?.birthdate, "DD/MM/YYYY").format("YYYY-MM-DD")
    };
    authService
      .patientLogin(data)
      .then(async (res: AxiosResponse) => {
        setSubmittng(false);
        setLoading(false);
        const { data: { result: { user }, isOnboardProcess } } = res;
        if (user) {
          if (user?.isActive === false || isOnboardProcess) {
            await storeService.set("patient_signup", user);
            navigateToRoute(navigate, "/patient/signup", true);
          } else {
            await storeService.set("user", user);
            await storeService.set("patient_token", user.token);
            await storeService.set("isPatientLink", false);
            await storeService.set("currentUserType", "patient");
            dispatch({ type: LOGIN_PATIENT, payload: { user: {...user, profilePic: user?.avatar?.url || user?.avatar }, isPatientLink: false, currentUserType: "patient" } });
            navigateToRoute(navigate, "/home", true);
          }
        } else if (isOnboardProcess) {
          await storeService.set("patient_signup", data);
          navigateToRoute(navigate, "/patient/signup", true);
        } else present({ message: "dados do usuário não encontrados", duration: 3000 });
      })
      .catch(async (error: { response: { data: { message: string } } }) => {
        setLoading(false);
        setSubmittng(false);
        if (error?.response?.data?.message === "Usuário não encontrado. Favor confirmar os dados cadastrais!") {
          await storeService.set("patient_signup", {
            cpf: data.cpf,
            birthdate: data.birthdate,
            newUser: true,
          });
          navigateToRoute(navigate, "/patient/signup", true);
        }
        present({
          message: error?.response?.data?.message ,
          duration: 3000,
        });
        // setLoginError("Detalhes do usuário inválidos");
      });
  };

  const validationSchema = Yup.object().shape({
    cpf: Yup.string().required("Digite o número do seu CPF"),
    birthdate: Yup.string().required("Digite sua data de nascimento")
      .test("birthdate", "A data de nascimento não pode ser uma data futura.",(value) => {
        const todayDate = moment(new Date(), "DD/MM/YYYY").format("YYYY-MM-DD")
        const valueDate = moment(value, "DD/MM/YYYY").format("YYYY-MM-DD")
        return moment(todayDate).diff(moment(valueDate), "days") >= 0;
      })
      .test("birthdate", "A data de nascimento deve ser pelo menos 120 anos atrás",(value) => {
        const todayDate =  moment().subtract(120, "years").toDate()
        const valueDate = moment(value, "DD/MM/YYYY").format("YYYY-MM-DD")
        return moment(todayDate).diff(moment(valueDate), "years") <= 0;
      })
  });

  return (
    <Layout loading={loading || isTokenLoading} routeName="patientlogin">
      {!isTokenLoading && !isTokenError && (
        <>
          <Grid className="[&>ion-row]:max-w-[1050px] [&>ion-row]:w-full mt-0 h-[calc(10vh-177px)] flex md:items-center p-0 overflow-y-auto bg-cover relative px-4 bg-grad">
            <LeftGrid
              src={IMG.homeScreenImg}
              ImgClassname="lg:max-w-[500px] md:absolute bottom-0 w-full hidden md:block"
              leftclassName="static"
            />
            <RightGrid className="pt-12 md:pt-0">
              <div className="max-w-[384px] mx-auto mb-6 md:mb-0 [&>form>.input-wrapper>ion-input]:!pr-4">
                <IonLabel className="text-white left-10 font-bold text-3xl block">
                  Bem-vindo
                </IonLabel>
                <IonLabel className="text-white text-xl font-light max-w-xs mt-3 mb-5 block">
                  Para iniciarmos seu atendimento, por favor nos informe seus dados.
                </IonLabel>
                <IonImg src={IMG.homeScreenImg} alt="" className="block md:hidden max-w-[280px] mx-auto"/>
                <CustomFormik
                  handleSubmit={submitLogin}
                  formInputFields={formInputFields}
                  initialValues={{ cpf: "", birthdate: "" }}
                  validationSchema={validationSchema}
                  btnName="Entrar"
                />
              </div>
            </RightGrid>
          </Grid>
        </>
      )}
    </Layout>
  );
};

export default PatientLogin;
