import { IonCol, IonLabel, IonRow, useIonToast } from "@ionic/react";
import Grid from "../../../components/Grid";
import LeftGrid from "../../../components/Grid/LeftGrid";
import RightGrid from "../../../components/Grid/RightGrid";
import Layout from "../../../components/Layout";
import CustomFormik from "../../../components/UI/Formik";
import { IMG } from "../../../styles/assets";
import { useState } from "react";
import { patientSignupInputFields2 as formInputFields, patientInsertInterface, validationSchemas } from "./formFields";
import { navigateToRoute, useDidMountHook } from "../../../utils/helper";
import storeService from "../../../services/store.service";
import { useNavigate } from "react-router";
import { AxiosResponse } from "axios";
import authService from "../../../services/auth-service";
import moment from "moment";

export const PatientRegister = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [signupPatient, setSignupPatient] = useState<any>();
  const navigate = useNavigate();
  const [present] = useIonToast();

  useDidMountHook(async () => {
    const patient_signup = await storeService.get("patient_signup");
    setSignupPatient(patient_signup);
  });

  const submitSignup = async (values: { name?: string; biologicalSex?: string; gender?: string }, setSubmitting: (submitting: boolean) => void) => {
    try {
      setSubmitting(true);
      setLoading(true);
      const data: patientInsertInterface = {
        name: signupPatient.name,
        email: signupPatient.email,
        cpf: signupPatient.cpf,
        phone: signupPatient.phone,
        countryCode: signupPatient.countryCode,
        isActiveUser: true,
        nickname: values.name || "",
        gender: values.gender || "",
        bioLogicalGender: values.biologicalSex || "",
        isDependent: false,
        birthdate: moment(signupPatient.birthdate, "DD/MM/YYYY").format("YYYY-MM-DD")
      }
      const resp: AxiosResponse = await authService.signupPatient(data);
      if (resp) {
        await storeService.removeKey("patient_signup");
        navigateToRoute(navigate, "/patient/login", true);
      }
      setLoading(false)
    } catch (err: any) {
      setLoading(false)
      present({ message: err?.response?.data?.message, duration: 3000 });
    }
  };

  return (
    <Layout loading={loading} routeName="patientregister">
      <Grid className="patient-signup [&>ion-row]:w-full !py-6 !px-0 !items-start [&>ion-row>ion-img]:ml-3 [&>ion-row>ion-img]:mt-0 md:[&>ion-row>ion-img]:hidden" handleBackClick={() => navigateToRoute(navigate, "/", true)} backIcon={true}>
        <IonRow className="md:px-0 mx-auto w-full max-w-[1320px]">
          <LeftGrid src={IMG.userInfoImg} backIcon={true} onBackClick={() => {
            navigateToRoute(navigate, "/patient/signup", true)
          }} />
          <RightGrid className="p-4 lg:p-0 mx-auto md:mt-10">
            <IonLabel className="font-bold text-2xl text-secondary block max-w-xs mb-2">
              Por favor, complete seu cadastro
            </IonLabel>
            <IonLabel className="max-w-xs text-[#4f4f4f] font-normal text-xl mb-4 block">
              Agora, preencha os dados pessoais
            </IonLabel>

            <IonRow>
              <IonCol size="12" className="lg:[&>form>.input-wrapper]:w-1/2 [&>form>.input-wrapper>ion-label]:text-ternary lg:[&>form>ion-select]:w-1/2  lg:[&>form>ion-button]:float-right lg:[&>form>ion-button]:w-1/2 lg:[&>form>ion-button]:pl-10 lg:[&>form>ion-button]:-mt-16">
                <CustomFormik
                  handleSubmit={submitSignup}
                  formInputFields={formInputFields}
                  initialValues={{ name: "", biologicalSex: "", gender: "" }}
                  validationSchema={validationSchemas}
                  btnName="Cadastrar"
                />
              </IonCol>
            </IonRow>
          </RightGrid>
        </IonRow>
      </Grid>
    </Layout>
  );
};
