import { IonCol, IonLabel, IonRow, useIonToast } from "@ionic/react";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router";
import Grid from "../../../components/Grid";
import LeftGrid from "../../../components/Grid/LeftGrid";
import RightGrid from "../../../components/Grid/RightGrid";
import Layout from "../../../components/Layout";
import { Button } from "../../../components/UI/Button";
import APP_CONFIG from "../../../config/app.config";
import authService from "../../../services/auth-service";
import storeService from "../../../services/store.service";
import { IMG } from "../../../styles/assets";
import { navigateToRoute, useDidMountHook } from "../../../utils/helper";

const TwoFactorAuthScreen = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [counter, setCounter] = useState(20);
  const [isError, setIsError] = useState<boolean>(false);
  const [present] = useIonToast();

  useDidMountHook(() => {
    sendOtp()
  })

  const sendOtp = async () => {
    try {
      const signupData = await storeService.get("operator_signup")
      const payload = { cpf: signupData?.cpf, type: "phone" };
      const resp: AxiosResponse = await authService.otpSend(payload);
      const { data: { success } } = resp
      if (success) {
        const signup = { ...signupData, type: "phone" };
        storeService.set("operator_signup", signup);
      }
    } catch (e: any) {
      console.error("[Error]: Two Factor Screen", e)
      present({ message: e?.response?.data?.message, duration: 5000 });
    }
  }

  const VerifyUserOtp = async () => {
    try {
      const signUpData = await storeService.get("operator_signup");
      const payload = { cpf: signUpData?.cpf, otp };
      const res: AxiosResponse = await authService.otpVerify(payload);
      const { data: { result } } = res;
      if (result) {
        const signup = { ...signUpData, ...result };
        storeService.set("operator_signup", signup);
        navigateToRoute(navigate, "/password", true);
      }
      setOtp("");
    } catch (e: any) {
      console.error("[Error]: otp verify", e);
      present({ message: e?.response?.data?.message, duration: 5000 });
    }
  };

  useEffect(() => {
    const timer: any = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const resendOtp = async () => {
    try {
      setCounter(20);
      setOtp("");
      setIsError(false);
      const signUpData = await storeService.get("operator_signup");
      const payload = signUpData?.cpf;
      const resp = await authService.otpResend(payload);
      const { data: { message } } = resp
      present({ message, duration: 3000 });
    } catch (e: any) {
      console.error("[Error]: resend otp", e);
      present({ message: e?.response?.data?.message, duration: 5000 });
    }
  };

  const onHandleCustom = (value: string) => {
    setIsError(false);
    setOtp(value);
  };

  return (
    <Layout routeName="twofactorauthscreen">
      <Grid className="[&>ion-row]:w-full items-start !py-6 !px-0 [&>ion-row>ion-img]:ml-3 [&>ion-row>ion-img]:mt-0 md:[&>ion-row>ion-img]:hidden" handleBackClick={() => navigateToRoute(navigate, "/", true)} backIcon={true}>
        <IonRow className="md:px-0 mx-auto w-full max-w-[1320px] md:items-center md:[&>.left-grid-wrapper>ion-label]:absolute md:[&>.left-grid-wrapper>ion-label]:top-5 md:[&>.left-grid-wrapper]:static">
          <LeftGrid src={IMG.loginFactorImg} backIcon={true} />
          <RightGrid>
            <Grid className="">
              <IonRow className=" w-full">
                <IonLabel className="max-w-full m-auto bg-boxgreenlabel shadow-[0_16px_24px_-16px_#4ca344] shadow-boxgreenlabelshadow rounded-[12px] text-[14px] font-semibold text-center text-white  align-middle mt-4 mt-md-0 mb-md-5 pt-4 pb-4 pl-12 pr-12 !md:m-0 !sm:m-0 !md:p-0 !sm:p-0">
                  Código enviado por SMS
                </IonLabel>
              </IonRow>
              <IonRow className="w-full mt-10 justify-center">
                <IonLabel className="font-bold text-2xl leading-8 text-center text-ternary">
                  Digite o código
                  <span className="w-full block">de validação</span>
                </IonLabel>

              </IonRow>
              <IonRow className="mt-[50px] outline-0 lg:pt-0 md:mt-[40px] ml-auto mr-auto">
                <OtpInput
                  placeholder=""
                  value={otp}
                  onChange={(value: string) => onHandleCustom(value)}
                  numInputs={APP_CONFIG.OTP_SIZE}
                  inputType={"number"}
                  renderInput={(props: any) => <input {...props} id="otp-input" className={`!w-[45px] h-[45px] md:!w-[55px] md:h-[51px] ml-2 bg-[#f4f5f7] font-inter rounded-xl text-[#0a7b2d] text-2xl focus:outline-none ${isError ? "error" : ""}`} />}
                />
              </IonRow>

              <IonRow className="w-full mt-[20px] justify-center">
                <IonCol size="12">
                  <Button
                    color="primary"
                    backgroundColor=" primaryBtn"
                    className=" font-medium mt-2 mb-2 max-w-[340px] mx-auto text-center align-middle justify-center flex"
                    text={`Continuar${" "}`}
                    id="two-factor-btn"
                    imgSrc={IMG.rightArrow}
                    disabled={(!otp || otp?.length < 6) ? true : false}
                    handleClick={VerifyUserOtp}
                  />
                </IonCol>
                <IonCol
                  size="12"
                  className=" text-center align-middle justify-center"
                  onClick={() => counter === 0 && resendOtp()}
                >
                  <IonLabel className="mb-0 link-btn  justify-center content-center">
                    <IonLabel className=" cursor-pointer text-ternary">
                      {counter > 0 ? `Reenvie o código em ${counter} segundos` : "Reenviar código"}
                    </IonLabel>
                  </IonLabel>
                </IonCol>
              </IonRow>
            </Grid>
          </RightGrid>
        </IonRow>
      </Grid>
    </Layout>
  );
};

export default TwoFactorAuthScreen;
