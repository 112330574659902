import { IonCol, IonLabel, IonRow, useIonToast } from "@ionic/react";
import { isEmpty } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/reducer";
import { CustomCard } from "../../components/UI/Card";
import { EmptyMessage } from "../../components/UI/EmptyMessage";
import { CustomList } from "../../components/UI/List";
import Share from "../../components/WebShare";
import Viewfile from "../../components/WebView";
import { appColor, deleteIcon, disabledColor, examFileIcon, examResultIcon, examShareIcon, uploadIcon } from "../../styles/svg/exam-svg";
import { dateFormat } from "../../utils/helper";
import { ExamsItemType, ExamsListProps, examResultType } from "./types";

const ExamsList: React.FC<ExamsListProps> = ({ data, loading, deleteExam, setIsShowModal, setExamRef, activeTab }) => {
  const { mQuery } = useSelector((state: RootState) => state.auth);

  const [present] = useIonToast();

  return (
    <div className="">
      <CustomList className="exam-list">
        {!isEmpty(data) ? (
          data.map((item: ExamsItemType) => (
            <CustomCard key={item.id} className="!shadow-lg !shadow-gray-100 !max-w-full border border-solid border-[#dfdfdf] !mb-5">
              <IonRow>
                <IonCol size="12" className="p-0">
                  <IonRow className="">
                    <IonCol size="12" className="flex sm:items-center justify-between p-0 flex-col sm:flex-row exam-detail-content">
                      <IonLabel className="flex items-center text-[#757575] font-medium">
                        <span className="inline-block mr-2">
                          {examFileIcon( activeTab === "future" ? appColor : disabledColor)}
                        </span>
                        {dateFormat(item.date)}
                      </IonLabel>
                      {item.type !== "outer" ? (
                        <div className={`flex justify-between w-full ${!mQuery ? "pl-8" : "justify-around w-full"} `}>
                          <Viewfile
                            label="Ver pedido"
                            present={present}
                            fileName={item.fileName}
                            fileUrl={item.file}
                            className="underline"
                          />
                          <Share
                            label="Compartilhar pedido"
                            present={present}
                            fileName={item.fileName}
                            fileurl={item.file}
                          />
                        </div>
                      ) : null}
                    </IonCol>
                    <IonCol size="12" className="p-0 mt-2">
                      <IonLabel className="block w-100 font-normal xl:text-lg text-[#262626]">
                        {item.title}
                      </IonLabel>
                    </IonCol>
                  </IonRow>

                  {item.type === "outer" ? (
                    <IonRow className="border-t border-t-[#bdbdbd] pt-3 mt-4 ">
                      <IonCol size="" className="p-0">
                        <Viewfile
                          label={item.file_name}
                          present={present}
                          img={examResultIcon(activeTab === "future" ? appColor : disabledColor)}
                          fileName={item.file_name}
                          fileUrl={item.file_url}
                        />
                      </IonCol>
                      <IonCol size="3" className="p-0">
                        <IonLabel className="flex items-center justify-end text-sm">
                          <div className="cursor-pointer items-center ml-4 w-9 h-9 rounded-md bg-[#e2f1fd] flex">
                            <Share
                              label=""
                              sendIcon={examShareIcon()}
                              present={present}
                              fileurl={item.file_url}
                              fileName={item.file_name}
                            />
                          </div>
                          <span
                            className="cursor-pointer flex justify-center items-center ml-4 w-9 h-9 rounded-md bg-[#e2f1fd]"
                            onClick={() => deleteExam(item.id, item.id, item.type)}
                          >
                            {deleteIcon()}
                          </span>
                        </IonLabel>
                      </IonCol>
                    </IonRow>
                  ) : null}

                  {item?.examResult?.length > 0 &&
                    item?.examResult?.map((e: examResultType) => (
                      <IonRow
                        key={e.id}
                        className="mt-4 pt-4 border-t border-t-[#bdbdbd]"
                      >
                        <IonCol size="12" sizeMd="9" className="p-0">
                          <IonLabel className="flex items-center justify-between">
                            <Viewfile
                              label={e?.file?.name}
                              present={present}
                              img={examResultIcon(activeTab === "future" ? appColor : disabledColor)}
                              fileName={e?.file?.name}
                              fileUrl={e?.file?.url}
                            />
                          </IonLabel>
                        </IonCol>

                        <IonCol size="12" sizeMd="3" className="p-0">
                          <IonLabel className="flex items-center justify-end text-sm">
                            <div className="cursor-pointer flex items-center ml-4 w-9 h-9 rounded-md bg-[#e2f1fd]">
                              <Share
                                label=""
                                sendIcon={examShareIcon(activeTab === "future" ? appColor : disabledColor)}
                                present={present}
                                fileName={e?.file?.name}
                                fileurl={e?.file?.url}
                              />
                            </div>
                            <span
                              onClick={() => deleteExam(item.id, e.id, item.type)}
                              className="cursor-pointer flex justify-center items-center ml-4 w-9 h-9 rounded-md bg-[#e2f1fd]"
                            >
                              {deleteIcon()}
                            </span>
                          </IonLabel>
                        </IonCol>
                      </IonRow>
                    ))}

                  {item.type !== "outer" ? (
                    <IonRow className="pt-3 mt-4 border-t border-t-[#bdbdbd]">
                      <IonCol className="p-0">
                        <IonLabel
                          className={`flex items-center tracking-wider cursor-pointer ${activeTab === "future" ? "text-[#135b9e]" : "text-[#757575]"} `}
                          onClick={() => {
                            setIsShowModal(true);
                            setExamRef(item);
                          }}
                        >
                          {uploadIcon(activeTab === "future" ? appColor : disabledColor)}
                          <span className="block ml-2">Salvar resultado</span>
                        </IonLabel>
                      </IonCol>
                    </IonRow>
                  ) : null}
                </IonCol>
              </IonRow>
            </CustomCard>
          ))
        ) : !loading ? (
          <EmptyMessage tab={"exams"} />
        ) : null}
      </CustomList>
    </div>
  );
};

export default ExamsList;
