import { IonLoading } from "@ionic/react";
import React from "react";

interface Props {
  showLoading?: boolean;
  setShowLoading?: (isOpen: boolean) => void;
  msgText?: string
}

const CustomLoader : React.FC<Props> = ({ showLoading = false, setShowLoading, msgText = "Obtendo detalhes..."}) => {
  return (
    <div className="loader-main">
      <IonLoading
        animated={true}
        className="my-loader-class dark:text-white"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading?.(false)}
        message={msgText}
        backdropDismiss={false}
        mode="ios"
        spinner="circles"
      />
    </div>
  );
}

export default CustomLoader;
