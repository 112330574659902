export const appColor = "#1059B2";
export const disabledColor = "#828282"

export const medicinePillIcon = (color = appColor) => (
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.1393 37.2509C19.2962 37.2554 18.4608 37.091 17.6822 36.7676C16.9036 36.4441 16.1977 35.9681 15.606 35.3675C14.4111 34.168 13.7402 32.5439 13.7402 30.8509C13.7402 29.1578 14.4111 27.5337 15.606 26.3342L20.606 21.3342L20.8393 21.1009L25.8393 16.1009C27.0408 14.9242 28.6572 14.2678 30.3389 14.2735C32.0206 14.2792 33.6324 14.9466 34.826 16.1313C36.0195 17.3161 36.6987 18.923 36.7168 20.6046C36.7349 22.2862 36.0904 23.9074 34.9226 25.1175L29.9226 30.1175C29.8352 30.2411 29.7213 30.3436 29.5893 30.4175L24.7226 35.3175C24.1252 35.9279 23.4123 36.4132 22.6254 36.7451C21.8385 37.077 20.9933 37.249 20.1393 37.2509ZM17.0893 27.7675C16.4836 28.3768 16.072 29.1518 15.9062 29.9947C15.7404 30.8376 15.8279 31.7108 16.1577 32.5041C16.4875 33.2973 17.0448 33.9752 17.7593 34.4521C18.4738 34.9291 19.3135 35.1838 20.1726 35.1842C20.7425 35.1886 21.3074 35.0787 21.8341 34.8612C22.3608 34.6436 22.8386 34.3228 23.2393 33.9175L27.5893 29.5675L21.456 23.4175L17.0893 27.7675ZM29.0393 28.1009L33.4393 23.7175C34.1845 22.8841 34.5816 21.7966 34.5489 20.6791C34.5162 19.5616 34.0561 18.4993 33.2635 17.7109C32.4708 16.9225 31.4059 16.4681 30.2883 16.4415C29.1706 16.4148 28.0853 16.8178 27.256 17.5675L22.8893 21.9509L29.0393 28.1009Z"
      fill={color}
    />
    <path
      d="M22.1887 32.8677C22.0507 32.8725 21.9133 32.8466 21.7865 32.7918C21.6597 32.737 21.5466 32.6548 21.4554 32.551C21.3631 32.4563 21.2906 32.3441 21.2419 32.2211C21.1933 32.0981 21.1695 31.9666 21.1721 31.8344C21.1784 31.5663 21.2855 31.3104 21.4721 31.1177L23.5887 29.0344C23.7814 28.8478 24.0373 28.7407 24.3054 28.7344C24.4428 28.7339 24.5789 28.7618 24.705 28.8163C24.8312 28.8708 24.9448 28.9507 25.0387 29.0511C25.1345 29.1442 25.2106 29.2557 25.2626 29.3788C25.3145 29.5018 25.3413 29.6341 25.3413 29.7677C25.3413 29.9013 25.3145 30.0336 25.2626 30.1567C25.2106 30.2798 25.1345 30.3912 25.0387 30.4844L22.9221 32.5677C22.8287 32.663 22.7171 32.7386 22.594 32.7902C22.471 32.8417 22.3388 32.868 22.2054 32.8677H22.1887Z"
      fill={color}
    />
    <path
      d="M9.72355 26.8173C8.45613 26.8185 7.21702 26.4424 6.1641 25.7369C5.11119 25.0314 4.29212 24.0284 3.81126 22.8558C3.33039 21.6831 3.2095 20.3939 3.46399 19.1522C3.71847 17.9106 4.33681 16.7729 5.24022 15.8839L10.2402 10.8839C10.3569 10.7339 10.4069 10.6839 10.4736 10.6506L15.4736 5.6506C16.6774 4.47688 18.2947 3.82401 19.976 3.8331C21.6573 3.84219 23.2675 4.51252 24.4585 5.6992C25.6496 6.88587 26.3258 8.49358 26.3411 10.1748C26.3564 11.8561 25.7095 13.4758 24.5402 14.6839L19.5402 19.6839C19.4747 19.8192 19.3621 19.9259 19.2236 19.9839L14.2569 24.9173C13.6654 25.5193 12.9599 25.9975 12.1815 26.3237C11.4031 26.6499 10.5675 26.8177 9.72355 26.8173ZM6.67355 17.3506C5.86022 18.1639 5.4033 19.267 5.4033 20.4173C5.4033 21.5675 5.86022 22.6706 6.67355 23.4839C7.48689 24.2973 8.59 24.7542 9.74022 24.7542C10.8904 24.7542 11.9936 24.2973 12.8069 23.4839L17.1736 19.1173L11.0236 12.9839L6.67355 17.3506ZM18.6069 17.6839L23.0069 13.2839C23.7878 12.4597 24.2156 11.3628 24.1987 10.2275C24.1819 9.0922 23.7218 8.00846 22.9168 7.20777C22.1117 6.40707 21.0255 5.95288 19.8901 5.9422C18.7548 5.93152 17.6602 6.36519 16.8402 7.1506L12.4736 11.5339L18.6069 17.6839Z"
      fill={color}
    />
    <path
      d="M15.8387 12.5012C15.5713 12.5144 15.309 12.425 15.1053 12.2512C15.0096 12.1581 14.9335 12.0466 14.8815 11.9235C14.8295 11.8004 14.8027 11.6682 14.8027 11.5346C14.8027 11.401 14.8295 11.2687 14.8815 11.1456C14.9335 11.0225 15.0096 10.9111 15.1053 10.8179L17.222 8.73457C17.3154 8.63929 17.4269 8.56365 17.55 8.51213C17.6731 8.46061 17.8052 8.43424 17.9387 8.43457C18.2115 8.44157 18.4724 8.5483 18.672 8.73457C18.8584 8.9299 18.9625 9.18955 18.9625 9.45958C18.9625 9.7296 18.8584 9.98925 18.672 10.1846L16.5553 12.2512C16.4619 12.3465 16.3504 12.4222 16.2273 12.4737C16.1042 12.5252 15.9721 12.5516 15.8387 12.5512V12.5012Z"
      fill={color}
    />
  </svg>
);

export const medicineViewIcon = (color = appColor) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 19.5C15.4183 19.5 19 15.9183 19 11.5C19 7.08172 15.4183 3.5 11 3.5C6.58172 3.5 3 7.08172 3 11.5C3 15.9183 6.58172 19.5 11 19.5Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.0004 21.5004L16.6504 17.1504"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 8.5V14.5"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 11.5H14"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const downloadMedicineIcon = (color = appColor) => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_835_51380)">
      <path
        d="M6.76172 12.873L9.76172 15.873L12.7617 12.873"
         stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.76172 9.12305V15.873"
         stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4226 13.6905C17.0746 13.232 17.5636 12.5776 17.8185 11.8224C18.0735 11.0671 18.0811 10.2503 17.8404 9.49037C17.5996 8.73047 17.123 8.06706 16.4796 7.59642C15.8363 7.12577 15.0597 6.87237 14.2626 6.873H13.3176C13.092 5.9939 12.6699 5.17743 12.0832 4.48506C11.4964 3.79268 10.7602 3.24244 9.92999 2.87576C9.09979 2.50908 8.19722 2.33552 7.29023 2.36813C6.38324 2.40075 5.49547 2.6387 4.69375 3.06407C3.89203 3.48943 3.19725 4.09113 2.66172 4.82387C2.1262 5.5566 1.76387 6.40128 1.60203 7.29431C1.44019 8.18734 1.48304 9.10544 1.72737 9.97951C1.9717 10.8536 2.41113 11.6608 3.01258 12.3405"
         stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_835_51380">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(0.761719 0.123047)"
        />
      </clipPath>
    </defs>
  </svg>
);
