import { IonCol, IonLabel, IonText, useIonToast } from "@ionic/react";
import { AxiosResponse } from "axios";
import { useState } from "react";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import Grid from "../../../components/Grid";
import Layout from "../../../components/Layout";
import CustomFormik from "../../../components/UI/Formik";
import Modal from "../../../components/UI/Modal";
import authService from "../../../services/auth-service";
import storeService from "../../../services/store.service";
import { corporation, navigateToRoute, useDidMountHook } from "../../../utils/helper";
import { operatorSignupInputFields as formInputFields } from "./formFields";
import AppointmentConfirmation from "@irontrainers/chat-bot/dist/Pages/AppointmentConfirmation"
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { RESET } from "../../../Redux/action";
// import AppointmentModal from "@irontrainers/chat-bot/dist/Components/AppointmentModal/AppointmentModal"

const OperatorSignup = () => {
  const [isPhoneNumber, setIsPhoneNumber] = useState<boolean>(false);
  const [isAppointment, setIsAppointment] = useState(false);

  const [present] = useIonToast();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search.substring(1));
  const appointmentToken = queryParams.get("message") || "";

  const dispatch = useDispatch();
  useDidMountHook(async() => {
    if (!isEmpty(appointmentToken)) {
      setIsAppointment(true);
      await storeService.remove();
      await localStorage.removeItem("persist:root");
      dispatch({ type: RESET })
    } else {
      storeService.get("operator_login").then((res) => {
        if (res) {
          navigateToRoute(navigate, "/patient/login", true);
          storeService.get("user").then((res) => {
            if (res) navigateToRoute(navigate, "/home", true);
          });
        }
      });
    }
  });

  const submitLogin = (values: { email?: string; cpf?: string }, setSubmitting: (submitting: boolean) => void) => {
    setSubmitting(true);
    const cpf = values?.cpf?.replace(/-|_|\./g, "");
    const data = {
      email: values.email,
      cpf: cpf,
    };
    authService
      .operatorSignUp(data)
      .then(async (res: AxiosResponse) => {
        const { data: { result: { user } } } = res;
        await storeService.set("operator_signup", user);
        setSubmitting(false);
        if (user && !user.isActiveUser) {
          if (!user.phone.length) setIsPhoneNumber(true);
          else navigateToRoute(navigate, "/login-factor", true);
        } else {
          present({ message: "dados do usuário não encontrados", duration: 3000 });
        }
      })
      .catch((err: { response: { data: { message: string } } }) => {
        console.error("[Error]: operator signup", err);
        setSubmitting(false);
        present({ message: err?.response?.data?.message, duration: 3000 });
      });
  };

  const validationSchema = Yup.object().shape({
    cpf: Yup.string().required("Nº cpf é obrigatório").test("len", "Nº cpf é obrigatório", (val) => val.length >= 10),
    email: Yup.string().email("Email inválido").required("E-mail é obrigatório"),
  });

  const navigateToLogin = () => navigateToRoute(navigate, "/login", true);

  return (
    <Layout routeName="operatorsignup">
      {/* <AppointmentModal isCancel={false} isOpen={true} setIsopen={()=>{console.log("Ddd")}} /> */}
      {!isAppointment &&
        <Grid className={"overflow-y-auto bg-cover bg-grad"}>
          <IonCol className="mx-auto py-6 py-md max-w-sm">
            <IonText className="text-white text-center font-bold text-3xl w-100 block mb-3">
              Operador de Saúde
            </IonText>
            <IonLabel className="text-white text-xl font-light w-100 text-center mb-9 block ">
              Para ter acesso a plataforma do paciente por favor nos informe seus
              dados.
            </IonLabel>

            <CustomFormik
              handleSubmit={submitLogin}
              formInputFields={formInputFields}
              initialValues={{ email: "", cpf: "" }}
              validationSchema={validationSchema}
              btnName="Continuar"
            />
            <IonText className="text-white flex justify-center mt-8">
              Já tem uma conta?
              <span className="mx-2 text-black cursor-pointer" onClick={navigateToLogin}>
                Entrar
              </span>
            </IonText>
          </IonCol>

          <Modal
            isOpen={isPhoneNumber}
            setIsOpen={setIsPhoneNumber}
            className=""
            text="Telefone não cadastrado. Por gentileza, clique no botão 'Precisa de Ajuda?' e solicite o cadastramento do seu telefone à equipe de Suporte Técnico."
            btnSimText="Sim"
            handleClick={() => setIsPhoneNumber(false)}
            btnSimColor="success"
            btnSimFill="outline"
          />
        </Grid>
      }
      {
        isAppointment &&
        <AppointmentConfirmation corporationData={{
          appointmentTextColor: "rgb(46 138 213)",
          corporationName: corporation,
        }}
        />
      }
    </Layout>
  );
};

export default OperatorSignup;
