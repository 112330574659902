import { Browser } from "@capacitor/browser";
import { getPlatforms } from "@ionic/react";
import { isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useLayoutEffect, useRef } from "react";
import { NavigateFunction } from "react-router";
import { presentType } from "../helper/helper";

export const corporation = "avus";

export const format = (text: string, values: Array<string>) => {
  return text.replace(/{(\d+)}/g, function (match, number) {
    return typeof values[number] != "undefined" ? values[number] : match;
  });
};

export const CNES_NUMBER = "0969052";

export const getExtension = (file: string | null) =>
  file?.substr(file.lastIndexOf(".") + 1);

export const userShortName = (name: string) => {
  const username = name
    ?.split(" ")
    .map((name) => name[0])
    .join("")
    .toUpperCase();
  return username?.length <= 2 ? username : username?.substring(0, 2);
};

export const isDesktop = getPlatforms().includes("desktop");

export const dateFormat = (date: Date | string) =>
  moment(date).format("DD/MM/YYYY");

export const formatWeekDays = (weekNum: number) => {
  const weekday = [
    "domingo",
    "segunda",
    "terça",
    "quarta",
    "quinta",
    "sexta",
    "sábado",
  ];
  return weekday[weekNum];
};

export const viewFile = async (url: string, fileName: string, present: presentType) => {
  if (url && !isEmpty(url)) {
    // const ext = getExtension(url);
    if (isDesktop) window.open(url, "_blank");
    else await Browser.open({ url, presentationStyle: "fullscreen" });
  } else {
    present({
      message: "problema na busca de URL entre em contato com o suporte",
      duration: 5000,
    });
  }
};

export const getDay = (date: string) => formatWeekDays(moment(date).day());
export const getSlot = (slot: string) => slot?.replace(":", "h");
export const getDateMonth = (date: string) => moment(date).format("DD/MM");

export const navigateToRoute = (navigate: NavigateFunction ,route: string, isReplace = false) => {
  navigate(route, { replace: isReplace });
};

export const useDidMountHook = (callback: () => void) => {
  const didMount = useRef(null || false)

  useEffect(() => {
    if (callback && !didMount.current) {
      didMount.current = true
      callback()
    }
  })
}

export const useWillMountHook = (callback: () => void) => {
  const didMount = useRef(null || false)

  useLayoutEffect(() => {
    if (callback && !didMount.current) {
      didMount.current = true
      callback()
    }
  }, []);
}