import { liveSupport } from "@irontrainers/palibrary/dist/clients/iron-fit-patient";
import Support from "@irontrainers/palibrary/dist/lib/Support";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RootState } from "../../Redux/reducer";
import homeScreenService from "../../services/home-serveice";
import { corporation, navigateToRoute, useDidMountHook } from "../../utils/helper";
import Layout from "../Layout";
import { useNavigate } from "react-router";
const { LivePatientSyncProvider } = liveSupport;

const PatientSupport = () => {
  const { user } = useSelector((state: RootState) => state.auth);

  const [showTerm, setShowTerm] = useState(false);
  const [design, setDesign] = useState({});

  const navigate = useNavigate();

  useDidMountHook(() => {
    // terms =  props?.history?.location?.state || props?.history?.location?.location?.state;
    setShowTerm(!showTerm);
    if(!showTerm) {
      homeScreenService.getPaCallDesign(corporation)
        .then(async (response) => {
          const { data: { data } } = await response;
          setDesign(data);
        }).catch((error) => {
          console.error("[Error]: pa call design", error);
        })
    } else {
      navigateToRoute(navigate, "/home", true)
    }
  })

  return (
    <Layout routeName="support">
      <LivePatientSyncProvider>
      <Support corporation={corporation} users={user} showChat={true} showTerm={showTerm} setShowTerm={setShowTerm} design={design} />
        <ToastContainer
          position="top-right"
          autoClose={10000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </LivePatientSyncProvider>
    </Layout>
  );
};

export default PatientSupport;

