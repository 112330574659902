import { formFieldType } from "../../../components/UI/Formik/types";
import { IMG } from "../../../styles/assets";

export const operatorLoginInputFields: formFieldType[] = [
    {
        id: "email",
        placeholder: "E-mail",
        name: "email",
        type: "text",
        label: "Digite o seu e-mail",
        inputType: "input",
        isLabelAfter: true,
        labelClassName: "text-ternary"
    },
    {
        id: "password",
        placeholder: "Senha",
        name: "password",
        type: "password",
        label: "Digite sua senha",
        disabled: true,
        inputType: "input",
        isLabelAfter: true,
        labelClassName: "text-ternary",
        visible: IMG.visible,
        hidden: IMG.hidden,
    }
]
