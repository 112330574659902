import React, { useState } from "react";
import Layout from "../../../components/Layout";
import { IonImg, IonLabel, IonProgressBar, IonRow, IonText, useIonToast } from "@ionic/react";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router";
import Grid from "../../../components/Grid";
import LeftGrid from "../../../components/Grid/LeftGrid";
import RightGrid from "../../../components/Grid/RightGrid";
import { Button } from "../../../components/UI/Button";
import Modal from "../../../components/UI/Modal";
import authService from "../../../services/auth-service";
import storeService from "../../../services/store.service";
import { IMG } from "../../../styles/assets";
import { navigateToRoute } from "../../../utils/helper";

const LoginFactorScreen: React.FC = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [twoFactorAuthType,] = useState<string>("phone");
    const [present] = useIonToast();

    const handleBackClick = () => {
        storeService.removeKey("operator_signup")
        navigateToRoute(navigate, "/", true)
    }

    const handleClick = async () => {
        const signupData = await storeService.get("operator_signup")
        const payload = { cpf: signupData?.cpf, type: twoFactorAuthType };
        setIsOpen(true);
        try {
            const resp: AxiosResponse = await authService.otpSend(payload);
            const { data: { success } } = resp
            if (success) {
                const signup = { ...signupData, type: twoFactorAuthType };
                storeService.set("operator_signup", signup);
                navigateToRoute(navigate, "/two-factor-auth", true)
            }
            setIsOpen(false);
        } catch (e: any) {
            console.error("[Error]: LoginFactorScreen", e)
            setIsOpen(false);
            present({ message: e?.response?.data?.message, duration: 5000 });
        }
    }

    return (
        <Layout routeName="loginfactor">
            <Grid className="[&>ion-row]:w-full !py-6 !px-0 items-start md:!items-center [&>ion-row>ion-img]:ml-3 [&>ion-row>ion-img]:mt-0 md:[&>ion-row>ion-img]:hidden" handleBackClick={() => navigateToRoute(navigate, "/", true)} backIcon={true}>
                <IonRow className="md:px-0 mx-auto w-full max-w-[1320px] lg:items-center md:[&>.left-grid-wrapper>ion-label]:absolute md:[&>.left-grid-wrapper>ion-label]:top-5 md:[&>.left-grid-wrapper]:static">
                    <LeftGrid
                        // title={`Como prefere ${" "} receber seu código`}
                        src={IMG.loginFactorImg}
                        backIcon={true}
                        onBackClick={handleBackClick}
                    />
                    <RightGrid className="p-4 lg:p-0 mx-auto !max-w-sm">
                        <IonLabel className="font-bold mb-10 text-2xl md:text-[32px] text-center md:text-left md:leading-10 md:text-secondary block max-w-[100%]">
                            <span>Como prefere</span>{" "}
                            <span>receber seu código?</span>
                        </IonLabel>
                        <IonImg src={IMG.loginFactorImg} className="md:hidden mb-10" />
                        <Button
                            expand="block"
                            shape="round"
                            fill="outline"
                            color="primary"
                            className="bg-white rounded-[50px] h-11"
                            text="Por celular"
                            id="login-factor-btn"
                            handleClick={handleClick}
                        />
                    </RightGrid>
                </IonRow>
                <Modal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    className=""
                >
                    <IonText className=" relative font-inter font-normal text-sm text-secondary items-center text-center modal-title mb-[10px] leading-6">
                        Aguarde um instante
                    </IonText>
                    <IonText className=" relative font-inter font-normal text-sm text-black justify-center items-center align-middle p-[10px]">
                        Enviando código para o<br></br>
                        {twoFactorAuthType === "email" ? "email" : "celular"} cadastrado
                    </IonText>

                    <IonProgressBar
                        type="indeterminate"
                        className=" w-[164px] my-0 mx-[10px] rounded-[20px] bg-lightGray"
                    ></IonProgressBar>
                </Modal>
            </Grid>
        </Layout>
    )
}

export default LoginFactorScreen;