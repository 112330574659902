import { SocialSharing } from "@awesome-cordova-plugins/social-sharing";
import { isEmpty } from "lodash";
import React from "react";
import { RWebShare } from "react-web-share";
import platform from "../../utils/platform";
import { presentType } from "../../helper/helper";

interface Props {
  present: presentType;
  label?: string;
  text?: string;
  sendIcon?: string | JSX.Element;
  fileurl: string;
  fileName: string;
  textClassName?: string;
}

const shareOption = async (
  fileurl: string,
  present: presentType,
  filename?: string
) => {
  if (fileurl === "" || isEmpty(fileurl)) {
    present({
      message: "problema na busca de URL entre em contato com o suporte",
      duration: 5000,
    });
  } else {
    SocialSharing.share(filename, fileurl).then(() => {
      // console.log("Share");
    });
  }
};

const Share: React.FC<Props> = ({
  present,
  label,
  sendIcon,
  fileurl,
  fileName,
  text,
  textClassName,
}) => {
  return platform.isDesktop || platform.isMobileweb ? (
    <>
      {fileurl === "" || isEmpty(fileurl) ? (
        <a
          className={`flex sm:items-center sm:justify-end font-medium text-base text-[#757575] bg-transparent cursor-pointer ${text && textClassName}`}
          onClick={() => shareOption(fileurl, present, fileName)}
        >
          {label || text}
          {sendIcon}
        </a>
      ) : (
        <RWebShare
          data={{
            title: fileName,
            url: fileurl,
          }}
          onClick={() => console.log("shared successfully!")}
        >
          <a className={`flex items-center sm:justify-end text-[#757575] font-medium xl:text-base underline cursor-pointer ${text && textClassName}`}>
            {label || text} {sendIcon}
          </a>
        </RWebShare>
      )}
    </>
  ) : (
    <a
      className=""
      onClick={() => shareOption(fileurl, present, fileName)}
    >
      {label || text}
      {sendIcon}
    </a>
  );
};

export default Share;
