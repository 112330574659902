import { IonCol, IonImg, IonItem, IonLabel, IonRow, IonText } from "@ionic/react";
import { AxiosError, AxiosResponse } from "axios";
import { isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Slider from "react-slick";
import { SCHEDULING } from "../../Redux/action";
import { RootState } from "../../Redux/reducer";
import Grid from "../../components/Grid";
import LeftGrid from "../../components/Grid/LeftGrid";
import Layout from "../../components/Layout";
import { Button } from "../../components/UI/Button";
import schedulingService from "../../services/scheduling-service";
import { navigateToRoute } from "../../utils/helper";
import { CalendarView, TimeSlot } from "./types";
import { handleSchedulingBack } from "./helper";

const CalendarScreen = () => {
  const { scheduling: { speciality, selectedStaff } } = useSelector((state: RootState) => state.scheduling);
  const { user } = useSelector((state: RootState) => state.auth);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentDate = new Date();
  const [selectedDate, setSelectedDate] = useState<string | null>();
  const [timeSlot, setTimeSlot] = useState<TimeSlot[]>([]);
  const [month, setMonth] = useState<number>(currentDate.getMonth() + 1);
  const [year, setYear] = useState<number>(currentDate.getFullYear());
  const [dateMarks, setdateMarks] = useState<string[]>([]);
  const [selectedTime, setSelectedTime] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedStaffFromSlots, setSelectedStaffFromSlots] = useState<TimeSlot>();

  const settings = {
    centerMode: false,
    infinite: false,
    centerPadding: "40px",
    speed: 500,
    rows: 2,
    slidesPerRow: 4,
    arrows: true,
  };

  useEffect(() => {
    setdateMarks([]);
    setSelectedDate(null);
    setTimeSlot([]);
    (month && year && !isEmpty(user) && !isEmpty(speciality)) &&  getAvailableDateWithProfessional();
  }, [month, year, user]);

  const onMonthYearChange = (params: Date) => {
    setMonth(params.getMonth() + 1);
    setYear(params.getFullYear());
  };

  const onArrowChange = (params: CalendarView) => {
    setMonth(params.activeStartDate.getMonth() + 1);
    setYear(params.activeStartDate.getFullYear());
  };

  const getSlotsWithProfessional = async (d: string) => {
    setLoading(true);
    setSelectedDate(d);
    const startDate = new Date(d);
    const endDate = new Date(d);
    const beginOfDay = startDate;
    beginOfDay.setHours(0, 0, 0, 0);

    const endOfDay = endDate;
    endOfDay.setHours(23, 59, 59, 999);
    const data = {
      timezone: moment?.tz?.guess(),
      agenda_date: startDate.toUTCString(),
      agenda_start_time: beginOfDay.toUTCString(),
      agenda_end_time: endOfDay.toUTCString(),
      speciality,
      staff_id: selectedStaff?.staff_id,
    };

    schedulingService
      .getSlotByStaff(user?.user_id, data)
      .then((response: AxiosResponse) => {
        const result = response.data.result;
        setTimeSlot(result);
        setLoading(false);
      })
      .catch((err: AxiosError) => {
        // setAvailableSlot([]);
        setLoading(false);
        console.error("[Error]: get slots by staff", err);
      });
  };

  const getAvailableDateWithProfessional = () => {
    const startDate = moment([year, month - 1]);
    const endDate = moment(startDate).endOf("month");
    const timezone: string = moment?.tz?.guess();
    setLoading(true);
    const next_month_year = moment().add(1, "M").startOf("month").format("YYYY");
    const next_month = (month % 12) + 1;
    const c_current_date = new Date();
    c_current_date.setHours(0, 0, 0, 0);

    let n_current_last_date = new Date();
    n_current_last_date.setHours(0, 0, 0, 0);
    n_current_last_date.setMonth((n_current_last_date.getMonth() % 12) + 1);
    n_current_last_date = new Date(n_current_last_date.getFullYear(), (n_current_last_date.getMonth() % 12) + 1, 0);

    const queryparams = `?speciality=${speciality}&month=${month}&year=${year}&staff=${selectedStaff?.scheduled_staff_id}&timezone=${timezone}&monthStart=${startDate}&monthEnd=${endDate}&c_current_date=${c_current_date}&n_current_last_date=${n_current_last_date}&next_month_year=${next_month_year}&next_month=${next_month}`;
    schedulingService
      .getAvailableDateWithProffessional(queryparams)
      .then((response: AxiosResponse) => {
        const { data: { status, next_two_months = false, data } } = response;
        if (status) {
          if (next_two_months === false) {
            const dateObj = data.reduce((prev: { date: string }[], next: { date: string }) =>
                moment(next.date).format("MM-DD-YYYY")
                  ? [...prev, moment(next.date).format("MM-DD-YYYY")]
                  : prev,
              []
            );
            setdateMarks(dateObj);
          }
        }
        setLoading(false);
      })
      .catch((error: AxiosError) => {
        console.error("[Error]: get available date with proffessional", error);
        setLoading(false);
      });
  };

  const onDateChange = async (d: string) => {
    getSlotsWithProfessional(d);
    setSelectedTime("");
  };

  const handleSlots = (item: TimeSlot) => {
    setSelectedTime(item?.slots);
    setSelectedStaffFromSlots(item);
  };

  const handleSubmit = () => {
    dispatch({
      type: SCHEDULING,
      payload: {
        speciality,
        selectedStaff: isEmpty(selectedStaff)
          ? { ...selectedStaffFromSlots }
          : selectedStaff,
        date: selectedDate,
        slot: selectedTime,
      },
    });
    navigateToRoute(navigate, "/scheduling/confirm-booking-screen", true);
  };

  const handleBackClick = () => {
    handleSchedulingBack(navigate, dispatch)
  }

  return (
    <Layout routeName="scheduling" loading={loading} handleBackClick={handleBackClick}>
      <Grid className="[&>ion-row]:w-full p-0 !items-start">
        <IonRow className="md:px-0 mx-auto w-full max-w-[1320px]">
          <IonCol size="12" className="flex">
            <LeftGrid
              name={user?.name}
              title="Agendamento de consulta"
              backIcon={true}
              onBackClick={handleBackClick}
            />
          </IonCol>
          <IonCol size="12" sizeLg="4" className="md:pl-10">
            <IonLabel className="text-ternary text-2xl max-w-md mx-auto md:mx-0 text-center md:text-left font-medium pb-4 mb-4 block mt-4 relative">{speciality}
              <span className="bg-ternary w-16 h-[1px] block mt-3 mx-auto md:mx-0"></span>
            </IonLabel>
            <div className="md:mt-3 px-4 md:px-0">
              <div className="w-full">
                <IonItem
                  lines="none"
                  key={selectedStaff?.staff_id}
                  className="flex items-center flex-row pl-0 nonestart-padding"
                >
                  <div className="h-16 w-16 mr-4 min-w-[64px] overflow-hidden rounded-full bg-gray-50">
                    <IonImg className="h-full w-full object-cover" src={selectedStaff?.avatar} />
                  </div>
                  <div className="flex flex-col">
                    <IonText className="font-bold text-lg leading-6 text-ternary">
                      {selectedStaff?.name}
                    </IonText>
                    <IonText className="text-sm text-gray-700">
                      {selectedStaff?.crm}
                    </IonText>
                  </div>
                </IonItem>
              </div>
            </div>
          </IonCol>
          <IonCol size="12" sizeLg="4" className="pt-5">
            <div className="text-center px-2">
              <IonLabel className="label-text font-semibold text-lg text-center block mb-2 text-ternary">
                Escolha uma data
              </IonLabel>
              <Calendar
                className={loading ? "pointer-events-none" : ""}
                locale="pt-BR"
                minDate={new Date()}
                value={selectedDate}
                onChange={(d: any) => onDateChange(d)}
                onActiveStartDateChange={(d: any) => onArrowChange(d)}
                onClickMonth={(d) => onMonthYearChange(d)}
                onClickYear={(d) => onMonthYearChange(d)}
                tileClassName={({ date }) => dateMarks.find((x: string) => x === moment(date).format("MM-DD-YYYY")) ? "highlight" : "nonHighlight"}
              />
            </div>
          </IonCol>
          <IonCol size="12" sizeLg="4" className="pt-5">
            <div className="max-w-[400px] px-3 md:px-0 m-0 md:ml-auto text-center items-center justify-center md:max-w-[350px] md:mt-0 mx-auto mb-5 xl:mb-0">
              <div>
                {timeSlot.length > 0 && (
                  <>
                    <IonLabel className="label-text font-semibold text-lg text-center block mb-5 text-ternary">
                      Escolha um horario
                    </IonLabel>
                    <Slider className="mb-4" {...settings}>
                      {timeSlot?.map((item: TimeSlot, index: number) => {
                        return (
                          <div
                            key={index}
                            onClick={() => handleSlots(item)}
                            className={`text-center flex-col items-center justify-center text-sm mr-3 mb-3 !w-14 !h-14 font-normal text-gray-600 py-4 px-0 cursor-pointer border border-gray-200 rounded-full ${selectedTime === item.slots ? " time-slot active " : ""}`}
                          >
                            <span>{item.slots}</span>
                          </div>
                        );
                      })}
                    </Slider>
                  </>
                )}
                {isEmpty(timeSlot) && selectedDate && (
                  <IonLabel className="mt-3 text-center block">
                    No momento, não há intervalos de tempo disponíveis para a data
                    selecionada. Selecione outra data.
                  </IonLabel>
                )}
              </div>
              {timeSlot.length > 0 && (
                <>
                  <Button
                    fill="solid"
                    text={"Confirmar agendamento"}
                    className="w-full max-w-md rounded border-0 text-white h-12 font-normal text-base mb-6"
                    // disabled={selectedTime == '' ? true : false}
                    id="calendar-save-btn"
                    handleClick={handleSubmit}
                    disabled={!selectedDate || !selectedTime}
                  />
                  <Button
                    fill="outline"
                    shape="round"
                    color="danger"
                    className="!text-red-500 w-full max-w-md !border-red-500"
                    text={"Cancelar"}
                    id="calendar-cancel-btn"
                    handleClick={() => navigateToRoute(navigate, "/home", true)}
                  />
                </>
              )}
            </div>
          </IonCol>
        </IonRow>
      </Grid>
    </Layout>
  );
};

export default CalendarScreen;
