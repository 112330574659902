import { IonAvatar, IonCol, IonLabel, IonRow, IonText, useIonToast } from "@ionic/react";
import { AxiosError } from "axios";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/reducer";
import { Button } from "../../components/UI/Button";
import { CustomCard } from "../../components/UI/Card";
import { EmptyMessage } from "../../components/UI/EmptyMessage";
import { CustomList } from "../../components/UI/List";
import Modal from "../../components/UI/Modal";
import complementoryService from "../../services/complement-service";
import { IMG } from "../../styles/assets";
import { handleCopyLink } from "./api";
import { HistoryItemType, HistoryListProps } from "./types";
import { SCHEDULING } from "../../Redux/action";
import { navigateToRoute } from "../../utils/helper";
import { useNavigate } from "react-router";

const HistoryList: React.FC<HistoryListProps> = ({ data, loading, tabname, historyAppointmentdata, getHistoryAppointments, setLoading }) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();

  const [present] = useIonToast();
  const dispatch = useDispatch();

  const [isCancelAppointment, setIsCancelAppointment] =
    useState<boolean>(false);
  const [isCancelAppointmentSuccess, setIsCancelAppointmentSuccess] =
    useState<boolean>(false);
  const [appointmentId, setAppointmentId] = useState<string>("");
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleCancelClick = (item: HistoryItemType) => {
    setIsCancelAppointment(true);
    setAppointmentId(item.id);
  };

  const cancelAppointment = (appointmentId: string) => {
    setLoading(true);
    const data = {
      id: appointmentId,
      user_id: user?.user_id,
    };
    complementoryService
      .deleteScheduleAppointment(data)
      .then(() => {
        setIsCancelAppointment(false);
        setIsCancelAppointmentSuccess(true);
        setTimeout(() => {
          setIsCancelAppointmentSuccess(false);
        }, 3000);
        getHistoryAppointments();
        setLoading(false);
      })
      .catch((err: AxiosError) => {
        console.error("[Error]: delete appointment", err);
        setLoading(false);
      });
  };

  const handleReset = () => {
    setIsCancelAppointment(false);
    setAppointmentId("");
  };

  const rescheduleAgenda = (speciality: string) => {
    dispatch({ type: SCHEDULING, payload: { speciality } });
    navigateToRoute(navigate, "/scheduling/professional-screen", true);
  };

  return (
    <>
      {!isEmpty(data) ? (
        <CustomList className="max-w-md mx-auto md:max-w-full px-4 lg:px-7">
          {data.map((item: HistoryItemType) => (
            <React.Fragment key={item.id}>
              <CustomCard
                key={item.id}
                className={`shadow-none !p-3 mx-2.5 bg-grayColor border border-solid border-[#dfdfdf] ${tabname === "future" ||
                item.id === historyAppointmentdata?.id ?  "" : "opacity-75"}`}
              >
                <div className="flex items-center mb-2">
                  <IonAvatar className="w-2/12 h-auto">
                    <img
                      src={item.staffProfile?.url || item.staffProfile}
                      alt="staff-profile"
                      className={`${(tabname === "future" || item.id === historyAppointmentdata?.id) ? "bg-secondary px-1 pt-0.5" : ""}  w-12 h-12 rounded-full `}
                    />
                  </IonAvatar>
                  <div className="grid ml-2 w-5/6">
                    <IonText className={`text-2xl font-semibold block truncate text-ternary ${(tabname === "future" || item.id === historyAppointmentdata?.id) ? "text-ternary" : ""}`}>
                      {item.staffName}
                    </IonText>
                    <IonText className={`text-sm font-medium whitespace-normal pt-1 ${(tabname === "future" || item.id === historyAppointmentdata?.id) ? "text-secondary" : "text-gray"}`}>
                      {item.staffSpeciality}
                    </IonText>
                  </div>
                </div>
                <div className="flex justify-between mx-2 mt-3">
                  <IonLabel className="flex">
                    {tabname === "future" ||
                      item.id === historyAppointmentdata?.id
                      ? IMG.calendarIcon
                      : IMG.calendarGrayIcon}
                    <span className={`ms-2 text-sm font-normal text-ternary ${(tabname === "future" || item.id === historyAppointmentdata?.id) ? "text-secondary" : ""}`}>
                      {moment
                        .utc(item.UTCdatetime)
                        .local()
                        .format("DD/MM/YYYY")}
                    </span>
                  </IonLabel>
                  <IonLabel className="flex">
                    {tabname === "future" ||
                      item.id === historyAppointmentdata?.id
                      ? IMG.clockIcon
                      : IMG.clockDisabledIcon}
                    <span className={`ms-2 text-base font-normal text-ternary ${(tabname === "future" ||
                      item.id === historyAppointmentdata?.id) ? "text-secondary" : ""}`}>
                      {/* {item.appointment_time} - {item.endtime || item.endTime} */}
                      {moment
                            .utc(item.UTCdatetime)
                            .local()
                            .format("HH:mm")
                            .replace(":", ":")}{" "} - {item.endtime || item.endTime}
                    </span>
                  </IonLabel>
                </div>
                {tabname === "future" ? (
                  <>
                    <Button
                      className="mt-3 max-w-[278px] mx-auto block"
                      text="Copiar link da consulta"
                      fill="solid"
                      imgSrc={IMG.copyIcon}
                      imgSlot="start"
                      imgClass="mr-2"
                      id="copy-link-btn"
                      handleClick={() => handleCopyLink(item, setModalOpen, present)}
                    />
                    <Button
                      color="danger"
                      className="max-w-[278px] mx-auto mt-3 block"
                      fill="clear"
                      text="Cancelar consulta"
                      id="cancel-appointment-btn"
                      handleClick={() => handleCancelClick(item)}
                    />
                  </>
                ) : null}
                {item.id === historyAppointmentdata?.id && (
                  <IonRow>
                    <IonCol size="12">
                      <Button
                        text="Agendar retorno"
                        handleClick={() => rescheduleAgenda(item.staffSpeciality)}
                        className="mt-2"
                        id="reagenda-appointment-btn"
                      />
                    </IonCol>
                  </IonRow>
                )}
              </CustomCard>
              {tabname === "future" ? (
                <IonLabel className="mb-4 block text-[#5f5f5f] text-center px-4">
                  Você poderá iniciar sua consulta com 30 minutos de
                  antecedência.
                </IonLabel>
              ) : null}
            </React.Fragment>
          ))}
        </CustomList>
      ) : !loading ? (
        <EmptyMessage tab={tabname === "agendadas" ? "futureHistory" : "pastHistory"} />
      ) : null}
      <Modal
        isOpen={isCancelAppointment}
        setIsOpen={handleReset}
        subText="Tem certeza que deseja cancelar a consulta?"
        btnSimText="Manter teleconsulta"
        handleClick={() => setIsCancelAppointment(false)}
        btnCancelText="Cancelar consulta"
        btnCancelFill="outline"
        btnCancelColor="danger"
        btnCancelClick={() => cancelAppointment(appointmentId)}
        className="className"
        btnClass={"flex flex-wrap-reverse"}
      />
      <Modal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        text={"Link copiado com sucesso"}
        btnCancelClick={() => setModalOpen(false)}
        btnSimColor="success"
        btnSimFill="outline"
        icon={IMG.successCheckedIcon}
      />
      <Modal
        isOpen={isCancelAppointmentSuccess}
        setIsOpen={setIsCancelAppointmentSuccess}
        text="Teleconsulta cancelada com sucesso!"
        icon={IMG.successCheckedIcon}
      />
    </>
  );
};

export default HistoryList;
