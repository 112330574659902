import { IonButton, IonIcon, IonSpinner } from "@ionic/react";
import React from "react";

type ButtonProps = {
  handleClick?: () => void;
  text: string;
  imgSrc?: string | JSX.Element;
  className?: string;
  shape?: "round";
  fill?: "clear" | "outline" | "solid" | "default";
  expand?: string;
  color?: string;
  disabled?: boolean;
  imgSlot?: string;
  id: string;
  backgroundColor?: string;
  type?: "button" | "submit" | "reset" | undefined;
  imgClass?: string;
  imgEndSrc?: string;
  imgEndClass?: string;
  isLoading?: boolean;
};

export const Button: React.FC<ButtonProps> = ({
  handleClick,
  text,
  imgSrc,
  className,
  shape = "round",
  fill,
  color,
  disabled = false,
  imgSlot = "end",
  id,
  backgroundColor = "#1059b2",
  type = "button",
  imgClass,
  imgEndSrc,
  imgEndClass,
  isLoading = false
}) => {
  return (
    <IonButton
      id={id}
      shape={shape}
      color={color}
      fill={fill}
      className={`${className} w-full text-white ${backgroundColor} normal-case h-12 tracking-normal text-base shadow-none filter-none drop-shadow-none m-0 commonbtn`}
      disabled={disabled}
      onClick={() => (handleClick ? handleClick() : null)}
      mode="md"
      type={type}
    >
      {imgSrc && imgSlot === "start" ? (
        typeof imgSrc === "string" ? (
          <IonIcon src={imgSrc} className={`ml-2 ${imgClass}`} />
        ) : (
          imgSrc
        )
      ) : null}
      {text}
      {imgSrc && imgSlot === "end" ? (
        typeof imgSrc === "string" ? (
          <IonIcon src={imgSrc} className={`ml-2 ${imgClass}`} />
        ) : (
          imgSrc
        )
      ) : null}
      {isLoading 
        ? <IonSpinner name="dots" className="!ml-2" />
        : imgEndSrc && <IonIcon src={imgEndSrc} className={`!ml-2 w-4 ${imgEndClass}`} slot="end"/>}
    </IonButton>
  );
};
