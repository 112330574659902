import { FooterIcons, HeaderMenuIcons, MobileFooterMenuIcons, MobileHeaderMenuIcons, SideMenuIcons } from "../styles/assets";

export const FACEBOOK_LINK = "https://www.facebook.com/avusbr";
export const INSTAGRAM_LINK = "https://www.instagram.com/avusbr/";
export const LINKEDIN_LINK = "https://www.linkedin.com/company/23347821/";
export const YOUTUBE_LINK = "https://www.youtube.com/channel/UCp_bNR3fVQQdkIreiV2rnrw";

export const socialIcons = [
  { id: "facebook", icon: FooterIcons.faceBook(), link: FACEBOOK_LINK },
  { id: "instagram", icon: FooterIcons.instagram(), link: INSTAGRAM_LINK },
  { id: "linkedin", icon: FooterIcons.linkedin(), link: LINKEDIN_LINK },
  { id: "youtube", icon: FooterIcons.youtube(), link: YOUTUBE_LINK }
];

export type headerItemType = {
  route: string;
  name: string;
  icon?: string | JSX.Element;
}

export const headerMenuItem: headerItemType[] = [
  {
    route: "/home", name: "Home",
    icon: HeaderMenuIcons.home
  },
  {
    route: "/history",
    name: "Minhas Consultas",
    icon: HeaderMenuIcons.history,
  },
  {
    route: "/medicine",
    name: "Meus Medicamentos",
    icon: HeaderMenuIcons.medicine,
  },
  {
    route: "/exams", name: "Meus Exames",
    icon: HeaderMenuIcons.exams
  },
  {
    route: "/profile", name: "",
    icon: ""
  },
];

export interface mobileHeaderMenuType { 
  id: string, 
  name: string, 
  icon: string | JSX.Element 
}
export const mobileHeaderMenuItem: mobileHeaderMenuType[] = [
  { id: "medicine", name: "Meus medicamentos", icon: MobileHeaderMenuIcons.medicine },
  { id: "exams", name: "Meus exames", icon: MobileHeaderMenuIcons.exams },
  { id: "history", name: "Minhas consultas", icon: MobileHeaderMenuIcons.history },
  { id: "profile", name: "Meu perfil", icon: MobileHeaderMenuIcons.profile },
  { id: "scheduling", name: "Agendamento de Consulta", icon: MobileHeaderMenuIcons.scheduling },
]
export const menuItemArray = [
  { route: "/home", imgName: SideMenuIcons.home, display: "Home" },
  {
    route: "/history",
    imgName: SideMenuIcons.history,
    display: "Minhas consultas",
  },
  {
    route: "/medicine",
    imgName: SideMenuIcons.medicine,
    display: "Meus medicamentos",
  },
  { route: "/exams", imgName: SideMenuIcons.exams, display: "Meus exames" },
  { route: "/profile", imgName: SideMenuIcons.profile, display: "Meu perfil" },
  { route: "/logout", imgName: SideMenuIcons.logout, display: "Sair" },
];

export const sideMenuSocialIcons = [
  { id: "facebook", icon: FooterIcons.faceBook("#fff"), link: FACEBOOK_LINK },
  { id: "instagram", icon: FooterIcons.instagram("#fff"), link: INSTAGRAM_LINK },
  { id: "linkedin", icon: FooterIcons.linkedin("#fff"), link: LINKEDIN_LINK },
  { id: "youtube", icon: FooterIcons.youtube("white"), link: YOUTUBE_LINK }
];


export const profile = {
  route: "/profile",
  name: "Profile"
}

export const MobileFooterMenu = [
  {
    route: "/history",
    imgName: MobileFooterMenuIcons.history,
    display: "Minhas consultas",
  },
  {
    route: "/medicine",
    imgName: MobileFooterMenuIcons.medicine,
    display: "Meus medicamentos",
  },
  { route: "/exams", imgName: MobileFooterMenuIcons.exams, display: "Meus exames" },
  { route: "/profile", imgName: MobileFooterMenuIcons.profile, display: "Meu perfil" },
];

export const hideMobileFooter = [
  "home",
  "history",
  "medicine",
  "exams",
  "profile",
  "dependentuser",
  "selectdependents",
  "meusdependentes",
  "registerdependent",
  "scheduling",
]