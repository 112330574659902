export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const MS_USER_BASE_URL = process.env.REACT_APP_MS_USER_BASE_URL;
export const IRON_FIT_PATIENT_API_KEY = process.env.REACT_APP_IRON_FIT_PATIENT_API_KEY;
export const PARSE_URL = process.env.REACT_APP_PARSE_URL;
export const APS_URL = process.env.REACT_APP_APS_URL;
export const PA_LIBRARY_API = process.env.REACT_APP_PA_LIBRARY_API;
export const CHAT_SOCKET_URL = process.env.REACT_APP_CHAT_SOCKET_URL;

const API_CONFIG = {
    signUp: `${BASE_URL}/auth/staff/signup`,
    login: `${BASE_URL}/auth/plansaude/login`,
    operatorLogin: `${BASE_URL}/auth`,
    operatorByEmail: `${BASE_URL}/doctor/getUserByEmail`,
    patientToken: `${MS_USER_BASE_URL}/user/verify/token?isPaid={0}&isPA={1}&isSpeciality={2}`,
    insertPatient: `${BASE_URL}/doctor/patient/insert`,
    userOTPSend: `${BASE_URL}/auth/authenticate_user`,
    userOTPVerify: `${BASE_URL}/auth/verify_user`,
    resendOtp: `${BASE_URL}/auth/resend_otp`,
    passwordCreation: `${BASE_URL}/auth/patient/update_password`,
    historyAppointment: `${BASE_URL}/scheduling/patient/history_appointments/{0}`,
    getFutureAppointmentList: `${BASE_URL}/scheduling/patient/home_screen_appointments/{0}?date={1}&timezone={2}&page=1&limit=1000`,
    getMedicine: `${BASE_URL}/doctor/patient/health_plan/{0}/prescriptions`,
    deleteAppointment: `${BASE_URL}/scheduling/schedule/delete/{0}/{1}`,
    getVideoAccessToken: `${BASE_URL}/live_support/get_video_access_token`,
    prescriptions: `${BASE_URL}/doctor/patient/health_plan/{0}/prescriptions`,
    exams: `${BASE_URL}/clinical_exam/health_plan/{0}/exams`,
    deleteExamResult: `${BASE_URL}/clinical_exam/health_plan/{0}/delete_exams`,
    uploadExamResult: `${BASE_URL}/clinical_exam/health_plan/{0}/upload_exam_result`,

    // profile
    getProfileDetails: `${BASE_URL}/scheduling/patient/get_profile_details/{0}`,
    updateProfileDetails: `${BASE_URL}/doctor/update_profile_details/{0}`,
    updateUserProfilePic: `${BASE_URL}/scheduling/update_user_profie_pic/{0}`,
    createDependentUser: `${BASE_URL}/scheduling/patient/create_dependent_user`,
    getDependentUser: `${BASE_URL}/scheduling/patient/get_dependant_users`,
    getDetailsByCPF: `${BASE_URL}/doctor/patients/get_detail_by_cpf`,


    // scheduling
    getSpecialities: "corporation/new_agenda/{0}/get_specialities/patient",
    getStaffBySpeciality: "/scheduling/new_agenda/get_staff_by_speciality",
    getAvailableDateWithProffessional: `${BASE_URL}/scheduling/new_agenda/patient/get/available_dates`,
    getSlotByStaff:`${BASE_URL}/scheduling/new_agenda/patient/health_nurse_agenda/{0}`,
    bookAppointment: `${BASE_URL}/scheduling/new_agenda/patient/book_adma_appointment/{0}`,
    
    // support
    paCallDesign: `${PA_LIBRARY_API}/covid/get_pa_design_by_coporation/{0}`,

    supportChat: `${PA_LIBRARY_API}/chat_bot/get_supportChat/{0}`,
}


export default API_CONFIG;