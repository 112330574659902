import { CHAT_DESIGN } from "../action";
import { updateObject } from "../updateObject";

const initialState: any = {
    chatDesign: null
};

const chatReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case CHAT_DESIGN:
            return updateObject(state, { chatDesign: action.payload });
        default:
            return state;
    }
};

export default chatReducer;