import API_CONFIG from "../config/api.config";
import HttpClient from "../utils/HttpClient";
import { format } from "../utils/helper";

class HomeScreen extends HttpClient {
    public getPaCallDesign(corporationID: string) {
      return this.http.get(format(API_CONFIG.paCallDesign, [corporationID]))
    }
}

const homeScreenService = new HomeScreen();
export default homeScreenService;
