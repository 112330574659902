import API_CONFIG from "../config/api.config";
import { patientInsertInterface } from "../pages/patient/signup/formFields";
import HttpClient from "../utils/HttpClient";
import { format } from "../utils/helper";

class AuthService extends HttpClient {
    public async patientLogin(data: {
        cpf?: string,
        corporation?: string,
        birthdate?: string,
        isPatientlink?: boolean,
        password?: string,
    }) {
        return this.http.post(API_CONFIG.login, data);
    }

    public async operatorLogin(data: { email?: string; password?: string }) {
        return this.http.post(API_CONFIG.operatorLogin, data, {
            // headers: {
            //   token: operatorUser?.token,
            // },
        });
    }

    public async operatorSignUp(data: { email?: string, cpf?: string }) {
        return this.http.post(API_CONFIG.signUp, data, {
          // headers: {
          //   token: operatorUser?.token,
          // },
        });
      }

    public async otpSend(data: { cpf: string; type: string }) {
        return this.http.post(API_CONFIG.userOTPSend, data);
    }

    public async otpVerify(data: { cpf: string; otp: string }) {
        return this.http.post(API_CONFIG.userOTPVerify, data);
    }

    public passwordCreation(patient_id: string, data: { password: string, emailverified: boolean }) {
        return this.http.post(`${API_CONFIG.passwordCreation}/${patient_id}`, data)
    }

    public otpResend(data: { cpf: string }) {
        return this.http.get(`${API_CONFIG.resendOtp}/${data}`);
    }

    public signupPatient(data: patientInsertInterface) {
        return this.http.post(`${API_CONFIG.insertPatient}`, data);
    }

    public operatorByEmail(email: string | unknown) {
        return this.http.post(API_CONFIG.operatorByEmail, { email });
    }

    public patientToken(token: string, { isPaid, isPA, isSpeciality }: { isPaid: string, isPA: string, isSpeciality: string }) {
        return this.http.get(`${format(API_CONFIG.patientToken, [isPaid, isPA, isSpeciality])}`, { headers: { "x-access-token": token }});

        // return this.http.get(`${API_CONFIG.patientToken}`, );
    }

    public getSupportChat(corporationID: string) {
        return this.http.get(format(API_CONFIG.supportChat, [corporationID]))
      }
}

export default new AuthService();
