import { IonImg, IonLabel } from "@ionic/react";
import React from "react";
import { viewFile } from "../../utils/helper";
import { presentType } from "../../helper/helper";

interface Props {
  fileUrl: string;
  fileName: string;
  label?: string;
  present: presentType;
  icon?: string;
  img?: string | JSX.Element;
  className?: string
}

const Viewfile: React.FC<Props> = ({ fileUrl, fileName, label, present, icon, img, className = "" }) => {
  return (
    <IonLabel
      className="flex items-center cursor-pointer"
      onClick={() => viewFile(fileUrl, fileName, present)}
    >
      {img}
      <a className={`font-medium xl:text-base text-[#757575] cursor-pointer  bg-transparent decoration-1 whitespace-normal break-all ml-2 ${className}`}>
        {label}
        {icon ? (
          <IonImg src={icon} style={{ width: "30px", height: "30px" }} />
        ) : null}
      </a>
    </IonLabel>
  );
};
export default Viewfile;
