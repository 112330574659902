import { AxiosError, AxiosResponse } from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/reducer";
import Grid from "../../components/Grid";
import LeftGrid from "../../components/Grid/LeftGrid";
import RightGrid from "../../components/Grid/RightGrid";
import Layout from "../../components/Layout";
import Tabs from "../../components/UI/Tabs";
import complementoryService from "../../services/complement-service";
import { IMG } from "../../styles/assets";
import MedicineList from "./MedicineList";
import { medicineLists, medicineTabType } from "./types";
import { navigateToRoute, useDidMountHook } from "../../utils/helper";
import { useNavigate } from "react-router";

const Medicine = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();

  const tabs = [
    { value: "novas", label: "Válidas" },
    // { value: "guidelines", label: "Orientações" },
    { value: "antigas", label: "Antigas" },
  ];

  const [activeTab, setActiveTab] = useState<medicineTabType>("novas");
  const [medicines, setMedicines] = useState<medicineLists>();
  const [loading, setLoading] = useState<boolean>(false);

  useDidMountHook(() => {
    setLoading(true);
    complementoryService
      .getMedicine(user.id)
      .then((res: AxiosResponse) => {
        const { data: { data } } = res;
        setMedicines(data);
        setLoading(false);
      })
      .catch((err: AxiosError) => {
        setLoading(false);
        console.error("[Error]: get medicine - ", err);
      });
  });

  return (
    <Layout routeName="medicine" loading={loading} handleBackClick={() => navigateToRoute(navigate, "/home", true)}>
      <Grid className="[&>ion-row]:w-full pt-0 pt-xl-4 px-0 mx-auto max-w-[1320px]">
        <LeftGrid
          title="Meus Medicamentos"
          src={IMG.medicineImg}
          backIcon={true}
          name={user.name}
        />
        <RightGrid colSize="5" className="p-0 mx-auto lg:mx-0">
          <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
          {medicines ? (
            <MedicineList loading={loading} data={medicines[activeTab]} activeTab={activeTab} />
          ) : null}
        </RightGrid>
      </Grid>
    </Layout>
  );
};

export default Medicine;
