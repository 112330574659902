import { IonApp,setupIonicReact } from "@ionic/react";
import React, { useEffect } from "react";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "react-phone-input-2/lib/style.css";

/* Theme variables */
import "./theme/variables.css";
import "./App.scss";

import { Router } from "./Router";
import { BrowserRouter } from "react-router-dom";
import { isDesktop } from "./utils/helper";
import SideMenu from "./components/Layout/SideMenu";
import { MQUERY,CHAT_DESIGN } from "./Redux/action";
import { useDispatch } from "react-redux";
import authService from "./services/auth-service";
import { corporation } from "./utils/helper";

setupIonicReact();

const App: React.FC = () => {
  const [mQuery, setMQuery] = React.useState<{ matches: boolean }>({
    matches: window.innerWidth > 768 ? true : false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 768px)");
    mediaQuery.addListener(setMQuery);

    authService
    .getSupportChat(corporation)
    .then(async (response:any) => {
      const { data } = await response;
      dispatch({ type: CHAT_DESIGN, payload: data.data.supportChatDesign })
    })
    .catch((error: any) => {
      console.error("error", error);
    });

    return () => mediaQuery.removeListener(setMQuery);
  }, []);

  useEffect(() => {
    dispatch({ type: MQUERY, payload: mQuery.matches })
  },[mQuery])

  return (
    <IonApp>
      <BrowserRouter>
        {!isDesktop && <SideMenu />}
        <Router />
      </BrowserRouter>
    </IonApp>
  );
};

export default App;
