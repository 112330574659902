import { IonCol, IonImg, IonItem, IonLabel, IonRow, IonText } from "@ionic/react";
import { AxiosError, AxiosResponse } from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { SCHEDULING } from "../../Redux/action";
import { RootState } from "../../Redux/reducer";
import Grid from "../../components/Grid";
import LeftGrid from "../../components/Grid/LeftGrid";
import Layout from "../../components/Layout";
import { Button } from "../../components/UI/Button";
import schedulingService from "../../services/scheduling-service";
import { corporation, navigateToRoute, useDidMountHook } from "../../utils/helper";
import { staffType, staffTypes } from "./types";
import { handleSchedulingBack } from "./helper";
import { isEmpty } from "lodash";

const ProffessionalScreen = () => {
  const { scheduling: { speciality } } = useSelector((state: RootState) => state.scheduling);
  const { user } = useSelector((state: RootState) => state.auth);
  const [loading, setLoading] = useState<boolean>(false);
  const [staffs, setStaffs] = useState<staffTypes>([]);
  const [selectedStaff, setSelectedStaff] = useState<staffType>();
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useDidMountHook(() => {
    getStaffBySpeciality();
  });

  const handleSelectedStaff = (selectedStaff: staffType | undefined) => {
    dispatch({
      type: SCHEDULING,
      payload: { speciality, staff: staffs, selectedStaff: selectedStaff },
    });
    navigateToRoute(navigate, "/scheduling/calendar-screen", true);
  };

  const getStaffBySpeciality = () => {
    setLoading(true);
    schedulingService
      .getStaffBySpeciality({ speciality, corporation })
      .then(async (resp: AxiosResponse) => {
        const { data: { result } } = resp;
        setStaffs(result);
        if (result?.length === 1) setSelectedStaff(result[0]);
        setLoading(false);
      })
      .catch((error: AxiosError) => {
        console.error("[Error]: get staff by speciality", error);
        setLoading(false);
      });
  };

  const handleBackClick = () => {
    handleSchedulingBack(navigate, dispatch)
  }

  return (
    <Layout routeName="scheduling" loading={loading} handleBackClick={handleBackClick}>
      <Grid className="[&>ion-row]:w-full p-0 !items-start">
        <IonRow className="md:px-0 mx-auto w-full max-w-[1320px]">
          <IonCol size="12" className="flex">
            <LeftGrid
              name={user?.name}
              title="Conheça nossas Especialidadesa"
              backIcon={true}
              onBackClick={handleBackClick}
            />
          </IonCol>
          <IonCol size="12" sizeMd="5" sizeLg="4" className="px-4 lg:px-10">
            <IonLabel className="text-ternary text-2xl max-w-md mx-auto md:mx-0 text-center md:text-left font-medium pb-4 mb-4 block mt-4 relative">
              {speciality}
              <span className="bg-ternary w-16 h-[1px] block mt-3 mx-auto md:mx-0"></span>
            </IonLabel>
            <div className="mt-3 w-full">
              {staffs?.map((staffData: staffType, index: number) => (
                <div
                  key={index}
                  className="w-full"
                  onClick={() => setSelectedStaff(staffData)}
                >
                  <IonItem
                    lines="none"
                    button={false}
                    key={staffData.staff_id}
                    className={`flex items-center mb-5 flex-row border border-solid cursor-pointer rounded-lg lg:mr-10 w-full mx-auto max-w-md md:max-w-full py-4 pro-card ${staffData.staff_id === selectedStaff?.staff_id ? "active" : ""}`}
                  >
                    <div className="h-16 w-16 mr-4 min-w-[64px] overflow-hidden rounded-full bg-gray-500">
                      <IonImg src={staffData.avatar} />
                    </div>
                    <div className="flex flex-col">
                      <IonText className="font-bold text-lg leading-6 text-ternary">
                        {staffData.name}
                      </IonText>
                      <IonText className="text-sm text-gray-800 font-semibold">
                        {staffData.crm}
                      </IonText>
                    </div>
                  </IonItem>
                </div>
              ))}
            </div>
            {!loading && isEmpty(staffs) ? <IonLabel>Veja os profissionais disponíveis para agendamento.</IonLabel> : null}
          </IonCol>
          
          {!isEmpty(staffs) && 
            <IonCol size="12" sizeMd="5" sizeLg="3" className="md:mt-24 text-center md:text-start mb-6 md:mb-0 flex-col px-4">
              <Button
                fill="solid"
                text={"Confirmar"}
                id="proffessional-save-btn"
                className="w-full max-w-md rounded-[50px] border text-white h-12 font-normal text-base mb-5"
                disabled={!selectedStaff}
                handleClick={() => handleSelectedStaff(selectedStaff)}
              />
              <Button
                id="proffessional-cancel-btn"
                fill="outline"
                shape="round"
                color="danger"
                className="!text-red-500 max-w-md !border-red-500"
                text={"Cancelar"}
                handleClick={handleBackClick}
              />
            </IonCol>}
        </IonRow>
      </Grid>
    </Layout>
  );
};

export default ProffessionalScreen;
