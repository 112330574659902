import { IonCol, IonFooter, IonImg, IonLabel, IonRow, IonSegmentButton, IonText } from "@ionic/react";
import { useNavigate } from "react-router";
import { FooterIcons } from "../../styles/assets";
import { CNES_NUMBER, corporation, navigateToRoute } from "../../utils/helper";
import { MobileFooterMenu, hideMobileFooter, socialIcons } from "../../utils/layoutHelper";
import { useState } from "react";
import FooterChatModal from "@irontrainers/chat-bot/dist/Components/FooterChatModal";
import { RootState } from "../../Redux/reducer";
import { useSelector } from "react-redux";
import storeService from "../../services/store.service";

interface MobileFooterProps {
  routeName?: string | undefined;
}

interface PopoverState {
  showPopover: boolean;
  event: React.MouseEvent<HTMLIonImgElement, MouseEvent> | undefined;
}


const Footer: React.FC<MobileFooterProps> = ({ routeName }) => {
  const navigate = useNavigate();
  const [popoverState, setShowPopover] = useState<PopoverState>({
    showPopover: false,
    event: undefined,
  });

    const { user } = useSelector((state: RootState) => state.auth);
  const { chatDesign } = useSelector((state: RootState) => state.chatReducer);

  return (
    <IonFooter
      translucent={true}
      className="relative pb-4 md:py-1.5 z-1 bg-[#f6f6f6] shadow-none"
    >
      <IonRow className="items-center">
        {hideMobileFooter.map((route: string, index: number) => (route === routeName &&
          <IonCol size="12" sizeMd="12" className="md:hidden flex items-start bg-[#ecf6fe] py-6" key={index}>
            {MobileFooterMenu?.map((data: { route: string; imgName: string | JSX.Element; display: string; }, index) => (
              <IonSegmentButton
                key={index}
                className="mainicon w-1/4 flex flex-col justify-center whitespace-normal !min-w-[inherit]"
                value="history"
                onClick={() => navigateToRoute(navigate, data?.route, true)}
              >
                <div className="w-[50px] h-[50px] rounded-full bg-white border border-[#bdbdbd] flex items-center justify-center icon-box">
                  {data.imgName}
                </div>
                <IonLabel className=" text-secondary text-[15px] font-normal block capitalize w-full text-center !mb-0">{data?.display.toLowerCase()}</IonLabel>
              </IonSegmentButton>
            ))}
          </IonCol>
        ))}

        <IonCol size="4" sizeMd="4">
          <IonImg
            src={FooterIcons.chatIcon}
            alt="footer-chat"
            className="max-w-[84px] mr-5 cursor-pointer"
            onClick={(e) => {
              e.persist();
              setShowPopover({ showPopover: true, event: e });
            }}
          />
        </IonCol>
        <IonCol size="4" className="hidden md:block">
          <div className="flex justify-center align-middle max-w-max mx-auto">
            {socialIcons.map((item) => (
              <span
                onClick={() => window.open(item.link)}
                key={item.id}
                className="socialicons mx-auto"
              >
                {item.icon}
              </span>
            ))}
          </div>
        </IonCol>
        <IonCol size="8" sizeMd="4" className="text-right pr-5">
          <IonText className="text-secondary text-xs font-bold block mb-2 platform-iron tracking-wider">
            Plataforma Iron Telemedicina
          </IonText>
          <div className="flex items-end justify-end flex-col-reverse lg:flex-row">
            <IonImg
              src={FooterIcons.ANSLogo}
              alt="footer-ans-logo"
              className="w-[150px] mr-md-2"
            />
            <IonText className="text-[#828282] md:text-ternary ml-3 text-xs font-medium font-serif">
              CNES: {CNES_NUMBER}
            </IonText>
          </div>
        </IonCol>
      </IonRow>
      <FooterChatModal
        popoverState={popoverState}
        setShowPopover={setShowPopover}
        user={user}
        corporation={corporation}
        design={chatDesign}
        storeService={storeService}
      />
    </IonFooter>
  );
};

export default Footer;
