import { formFieldType } from "../../../components/UI/Formik/types";

export const patientLoginInputFields: formFieldType[] = [
    {
        id: "cpf",
        placeholder: "CPF",
        name: "cpf",
        type: "tel",
        inputType: "input",
        mask:[
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ],
    },
    {
        id: "birthdate",
        placeholder: "dd/mm/yyyy",
        name: "birthdate",
        type: "tel",
        disabled: true,
        inputType: "input",
        isLabelAfter: true,
        labelClassName: "text-ternary",
        mask: [
            /\d/,
            /\d/,
            "/",
            /\d/,
            /\d/,
            "/",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ],
    }
]
