const appColor = "#A9E0FF";

export const homeMenu = (color = appColor) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.984375 8.97314L10.2685 0.988281"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5346 8.97314L10.2656 0.988281"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.39844 10.1582V19.2461H8.37008V14.2576H10.315"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.1388 10.1582V19.2461H12.152V14.2576H10.207"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const historyMenu = (color = appColor) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4648 2.64258H16.1305C16.6161 2.64382 17.0814 2.84626 17.4243 3.20551C17.7672 3.56475 17.9598 4.05146 17.9598 4.55889V10.1897"
      stroke={color}
      strokeWidth="1.16084"
      strokeMiterlimit="10"
    />
    <path
      d="M6.87109 2.64258H12.6927"
      stroke={color}
      strokeWidth="1.16084"
      strokeMiterlimit="10"
    />
    <path
      d="M11.0308 16.1196H3.31416C2.78743 16.1196 2.28227 15.9193 1.90982 15.5629C1.53737 15.2065 1.32813 14.7231 1.32813 14.219V4.54407C1.32812 4.03993 1.53733 3.55641 1.90976 3.19984C2.28219 2.84327 2.78734 2.64282 3.31416 2.64258H4.20461"
      stroke={color}
      strokeWidth="1.16084"
      strokeMiterlimit="10"
    />
    <path
      d="M15.3278 18.8147C18.0071 18.8147 20.1791 16.7029 20.1791 14.0978C20.1791 11.4927 18.0071 9.38086 15.3278 9.38086C12.6486 9.38086 10.4766 11.4927 10.4766 14.0978C10.4766 16.7029 12.6486 18.8147 15.3278 18.8147Z"
      stroke={color}
      strokeWidth="1.16084"
      strokeMiterlimit="10"
    />
    <path
      d="M6.02555 0.755859H5.22717C4.75869 0.755859 4.37891 1.15378 4.37891 1.64463V3.64064C4.37891 4.1315 4.75869 4.52941 5.22717 4.52941H6.02555C6.49403 4.52941 6.87382 4.1315 6.87382 3.64064V1.64463C6.87382 1.15378 6.49403 0.755859 6.02555 0.755859Z"
      stroke={color}
      strokeWidth="1.16084"
      strokeMiterlimit="10"
    />
    <path
      d="M14.521 0.755859H13.6339C13.1134 0.755859 12.6914 1.15378 12.6914 1.64463V3.64064C12.6914 4.1315 13.1134 4.52941 13.6339 4.52941H14.521C15.0416 4.52941 15.4636 4.1315 15.4636 3.64064V1.64463C15.4636 1.15378 15.0416 0.755859 14.521 0.755859Z"
      stroke={color}
      strokeWidth="1.16084"
      strokeMiterlimit="10"
    />
    <path
      d="M15.4648 10.7285V14.2325H18.237"
      stroke={color}
      strokeWidth="1.16084"
      strokeMiterlimit="10"
    />
    <path
      d="M1.32812 7.22461H17.961"
      stroke={color}
      strokeWidth="1.16084"
      strokeMiterlimit="10"
    />
  </svg>
);
export const examMenu = (color = appColor) => (
  <svg
    width="17"
    height="20"
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.39186 1.98535H2.79648C2.29347 1.98558 1.81109 2.17898 1.45524 2.5231C1.09939 2.86723 0.899137 3.33396 0.898438 3.82085V16.9744C0.898437 17.4618 1.09838 17.9292 1.45431 18.2738C1.81024 18.6185 2.29301 18.8123 2.79648 18.8125H14.3679C14.6172 18.8125 14.8642 18.765 15.0946 18.6726C15.325 18.5802 15.5343 18.4448 15.7106 18.2741C15.887 18.1035 16.0268 17.9008 16.1223 17.6778C16.2177 17.4548 16.2668 17.2158 16.2668 16.9744V3.82085C16.2661 3.33381 16.0657 2.86695 15.7097 2.5228C15.3536 2.17865 14.871 1.98535 14.3679 1.98535H10.7699"
      stroke={color}
      strokeWidth="1.16084"
      strokeMiterlimit="10"
    />
    <path
      d="M2.13672 12.4182H3.84242L5.42179 10.4565L7.14554 13.5898L9.32179 9.17578L11.094 14.805L12.602 12.4182H14.8287"
      stroke={color}
      strokeWidth="1.16084"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.9306 0.75293H7.22874C6.76586 0.75293 6.39062 1.11613 6.39062 1.56417V3.61003C6.39062 4.05807 6.76586 4.42127 7.22874 4.42127H9.9306C10.3935 4.42127 10.7687 4.05807 10.7687 3.61003V1.56417C10.7687 1.11613 10.3935 0.75293 9.9306 0.75293Z"
      stroke={color}
      strokeWidth="1.16084"
      strokeMiterlimit="10"
    />
  </svg>
);
export const medicineMenu = (color = appColor) => (
  <svg
    width="18"
    height="21"
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2592 1.43359H3.38454C3.13808 1.43359 2.89404 1.48079 2.66635 1.5725C2.43865 1.6642 2.23176 1.79861 2.05749 1.96806C1.88322 2.13751 1.74498 2.33867 1.65067 2.56007C1.55635 2.78146 1.50781 3.01875 1.50781 3.25838V17.668C1.50781 17.9077 1.55635 18.145 1.65067 18.3664C1.74498 18.5878 1.88322 18.7889 2.05749 18.9584C2.23176 19.1278 2.43865 19.2622 2.66635 19.3539C2.89404 19.4456 3.13808 19.4928 3.38454 19.4928H14.7945C15.2923 19.4928 15.7696 19.3006 16.1216 18.9584C16.4735 18.6161 16.6713 18.152 16.6713 17.668V5.8564"
      stroke={color}
      strokeWidth="1.16084"
      strokeMiterlimit="10"
    />
    <path
      d="M11.7125 9.36008H10.2256V7.91429C10.2256 7.73682 10.153 7.56661 10.024 7.44112C9.89491 7.31562 9.71985 7.24512 9.53733 7.24512H8.64638C8.55593 7.24501 8.46634 7.26223 8.38274 7.29581C8.29914 7.32939 8.22317 7.37867 8.15917 7.44082C8.09517 7.50297 8.0444 7.57677 8.00976 7.65802C7.97512 7.73926 7.95729 7.82634 7.95729 7.91429V9.36008H6.47034C6.28765 9.3603 6.11251 9.43097 5.98333 9.55657C5.85415 9.68218 5.78148 9.85247 5.78125 10.0301V10.8998C5.78148 11.0773 5.85418 11.2475 5.98339 11.373C6.11259 11.4985 6.28773 11.5689 6.47034 11.5689H7.95729V13.0147C7.95729 13.1027 7.97512 13.1898 8.00976 13.271C8.0444 13.3523 8.09517 13.4261 8.15917 13.4882C8.22317 13.5504 8.29914 13.5996 8.38274 13.6332C8.46634 13.6668 8.55593 13.684 8.64638 13.6839H9.53733C9.71985 13.6839 9.89491 13.6134 10.024 13.4879C10.153 13.3624 10.2256 13.1922 10.2256 13.0147V11.5681H11.7125C11.8029 11.5682 11.8925 11.551 11.9761 11.5174C12.0597 11.4838 12.1357 11.4346 12.1997 11.3724C12.2637 11.3103 12.3145 11.2364 12.3491 11.1552C12.3838 11.074 12.4016 10.9869 12.4016 10.8989V10.0301C12.4016 9.8524 12.329 9.68198 12.1998 9.55632C12.0705 9.43067 11.8953 9.36008 11.7125 9.36008Z"
      stroke={color}
      strokeWidth="1.16084"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.69141 16.0508H13.4885"
      stroke={color}
      strokeWidth="1.16084"
      strokeMiterlimit="10"
    />
    <path
      d="M12.2578 5.85641H16.6698L12.2613 1.43359L12.2578 5.85641Z"
      stroke={color}
      strokeWidth="1.16084"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const profileMenu = (color = appColor) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.41211 14.5468C5.52034 13.681 6.64597 11.9492 10.2826 11.9492C13.9193 11.9492 15.0449 13.681 15.1531 14.5468"
      stroke={color}
      strokeWidth="1.16084"
      strokeLinecap="round"
    />
    <path
      d="M0.740576 10.6643C0.740576 5.21786 5.16122 0.797216 10.6077 0.797216C16.0542 0.797216 20.4748 5.21786 20.4748 10.6643C20.4748 16.1108 16.0542 20.5315 10.6077 20.5315C5.16122 20.5315 0.740576 16.1108 0.740576 10.6643Z"
      stroke={color}
      strokeWidth="1.16084"
    />
    <path
      d="M13.1599 8.16409C13.1599 9.43029 12.1334 10.4568 10.8672 10.4568C9.60102 10.4568 8.57456 9.43029 8.57456 8.16409C8.57456 6.89789 9.60102 5.87144 10.8672 5.87144C12.1334 5.87144 13.1599 6.89789 13.1599 8.16409Z"
      stroke={color}
      strokeWidth="1.16084"
    />
  </svg>
);

export const logoutMenu = (color = appColor) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 22.7188C17.5228 22.7188 22 18.2416 22 12.7188C22 7.1959 17.5228 2.71875 12 2.71875C6.47715 2.71875 2 7.1959 2 12.7188C2 18.2416 6.47715 22.7188 12 22.7188Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 9.71875L9 15.7188"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 9.71875L15 15.7188"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const schedulingMenu = (color = appColor) => (
  <svg
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.6406 2.69434H18.4136C18.9775 2.69578 19.5178 2.93086 19.916 3.34802C20.3142 3.76518 20.5379 4.33036 20.5378 4.9196V11.4582"
      stroke={color}
      strokeMiterlimit="10"
    />
    <path d="M7.66211 2.69434H14.4223" stroke={color} strokeMiterlimit="10" />
    <path
      d="M12.4896 18.3441H3.52888C2.91723 18.3441 2.33063 18.1116 1.89813 17.6977C1.46563 17.2838 1.22266 16.7224 1.22266 16.1371V4.90239C1.22266 4.31697 1.46559 3.7555 1.89806 3.34144C2.33053 2.92738 2.91712 2.69462 3.52888 2.69434H4.56289"
      stroke={color}
      strokeMiterlimit="10"
    />
    <path
      d="M17.4771 21.4744C20.5884 21.4744 23.1105 19.022 23.1105 15.9969C23.1105 12.9719 20.5884 10.5195 17.4771 10.5195C14.3659 10.5195 11.8438 12.9719 11.8438 15.9969C11.8438 19.022 14.3659 21.4744 17.4771 21.4744Z"
      stroke={color}
      strokeMiterlimit="10"
    />
    <path
      d="M6.67774 0.503906H5.75065C5.20663 0.503906 4.76562 0.965976 4.76562 1.53597V3.85377C4.76562 4.42376 5.20663 4.88584 5.75065 4.88584H6.67774C7.22175 4.88584 7.66277 4.42376 7.66277 3.85377V1.53597C7.66277 0.965976 7.22175 0.503906 6.67774 0.503906Z"
      stroke={color}
      strokeMiterlimit="10"
    />
    <path
      d="M16.5426 0.503906H15.5125C14.908 0.503906 14.418 0.965976 14.418 1.53597V3.85377C14.418 4.42376 14.908 4.88584 15.5125 4.88584H16.5426C17.147 4.88584 17.6371 4.42376 17.6371 3.85377V1.53597C17.6371 0.965976 17.147 0.503906 16.5426 0.503906Z"
      stroke={color}
      strokeMiterlimit="10"
    />
    <path
      d="M17.6406 12.085V16.1539H20.8597"
      stroke={color}
      strokeMiterlimit="10"
    />
    <path d="M1.22266 8.01562H20.5371" stroke={color} strokeMiterlimit="10" />
  </svg>
);
