const appColor = "#46B7F8";

export const cameraIcon = (color = appColor) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3339_98080)">
      <path
        d="M20.0166 16.1771C20.0166 16.6191 19.8339 17.043 19.5086 17.3556C19.1833 17.6682 18.7421 17.8437 18.2821 17.8437H2.67196C2.21195 17.8437 1.77079 17.6682 1.44551 17.3556C1.12024 17.043 0.9375 16.6191 0.9375 16.1771V7.01042C0.9375 6.56839 1.12024 6.14447 1.44551 5.83191C1.77079 5.51934 2.21195 5.34375 2.67196 5.34375H6.14089L7.87535 2.84375H13.0787L14.8132 5.34375H18.2821C18.7421 5.34375 19.1833 5.51934 19.5086 5.83191C19.8339 6.14447 20.0166 6.56839 20.0166 7.01042V16.1771Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4767 14.5104C12.3926 14.5104 13.9457 13.018 13.9457 11.1771C13.9457 9.33613 12.3926 7.84375 10.4767 7.84375C8.5609 7.84375 7.00781 9.33613 7.00781 11.1771C7.00781 13.018 8.5609 14.5104 10.4767 14.5104Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3339_98080">
        <rect
          width="20.8136"
          height="20"
          fill="white"
          transform="translate(0.0703125 0.34375)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const photoIcon = (color = appColor) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5477 3.0625H4.40646C3.44854 3.0625 2.672 3.80869 2.672 4.72917V16.3958C2.672 17.3163 3.44854 18.0625 4.40646 18.0625H16.5477C17.5056 18.0625 18.2822 17.3163 18.2822 16.3958V4.72917C18.2822 3.80869 17.5056 3.0625 16.5477 3.0625Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.44147 8.896C8.15991 8.896 8.74232 8.33635 8.74232 7.646C8.74232 6.95564 8.15991 6.396 7.44147 6.396C6.72303 6.396 6.14062 6.95564 6.14062 7.646C6.14062 8.33635 6.72303 8.896 7.44147 8.896Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.2822 13.0624L13.946 8.89575L4.40649 18.0624"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const documentIcon = (color = appColor) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M14 2.04541H6C5.46957 2.04541 4.96086 2.2609 4.58579 2.64448C4.21071 3.02805 4 3.54829 4 4.09075V20.4535C4 20.9959 4.21071 21.5162 4.58579 21.8997C4.96086 22.2833 5.46957 22.4988 6 22.4988H18C18.5304 22.4988 19.0391 22.2833 19.4142 21.8997C19.7893 21.5162 20 20.9959 20 20.4535V8.18143L14 2.04541Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 2.04541V8.18143H20"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 13.2947H8"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 17.3855H8"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 9.2041H9H8"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
