import { IonSegment, IonSegmentButton } from "@ionic/react";
import React, { Dispatch, SetStateAction } from "react";
import { examsTabType } from "../../../pages/exams/types";
import { historyTabType } from "../../../pages/history/types";
import { medicineTabType } from "../../../pages/medicine/types";

export type tabType = {
  value: string;
  label: string;
};

export interface tabsProps {
  tabs: tabType[];
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<historyTabType>> | Dispatch<SetStateAction<medicineTabType>> | Dispatch<SetStateAction<examsTabType>>;
}

const Tabs: React.FC<tabsProps> = ({ tabs, activeTab, setActiveTab }) => {
  return (
    <div className="md:my-2">
      <IonSegment
        className="bg-[#eff8ff] md:bg-transparent rounded-none custom-tab"
        mode="md"
        value={activeTab}
        onIonChange={(e: any) => {
          setActiveTab(e.detail.value)
        }}
      >
        {tabs.map((tab) => (
          <IonSegmentButton
            mode="md"
            key={tab.value}
            value={tab.value}
            defaultChecked={tab.value === activeTab}
            className={`${tab.value === activeTab ? "text-primary border-b-0 font-medium" : "text-gray-500 font-normal"} text-base m-0 border-b border-borderColor capitalize tracking-normal hover:!text-gray-500`}
          >
            {tab.label}
          </IonSegmentButton>
        ))}
      </IonSegment>
    </div>
  );
};

export default Tabs;
