export const appColor = "#1059B2";
export const disabledColor = "#828282"

export const examFileIcon = (color = appColor) => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.6569 3.17676H9.32357C8.57212 3.17676 7.85145 3.47527 7.3201 4.00662C6.78875 4.53797 6.49023 5.25864 6.49023 6.01009V28.6768C6.49023 29.4282 6.78875 30.1489 7.3201 30.6802C7.85145 31.2116 8.57212 31.5101 9.32357 31.5101H26.3236C27.075 31.5101 27.7957 31.2116 28.327 30.6802C28.8584 30.1489 29.1569 29.4282 29.1569 28.6768V11.6768L20.6569 3.17676Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.6582 3.17676V11.6768H29.1582"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.8242 25.8437V17.3438"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5742 21.5938H22.0742"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const deleteIcon = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.44727 5H5.11393H18.4473"
      stroke="#B84949"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.61328 4.99984V3.33317C7.61328 2.89114 7.78888 2.46722 8.10144 2.15466C8.414 1.8421 8.83792 1.6665 9.27995 1.6665H12.6133C13.0553 1.6665 13.4792 1.8421 13.7918 2.15466C14.1044 2.46722 14.2799 2.89114 14.2799 3.33317V4.99984M16.7799 4.99984V16.6665C16.7799 17.1085 16.6044 17.5325 16.2918 17.845C15.9792 18.1576 15.5553 18.3332 15.1133 18.3332H6.77995C6.33792 18.3332 5.914 18.1576 5.60144 17.845C5.28888 17.5325 5.11328 17.1085 5.11328 16.6665V4.99984H16.7799Z"
      stroke="#B84949"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.28125 9.1665V14.1665"
      stroke="#B84949"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6133 9.1665V14.1665"
      stroke="#B84949"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const examResultIcon = (color = appColor) => (
  <svg
    width="19"
    height="21"
    viewBox="0 0 19 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.62891"
      y="1.75977"
      width="9.51074"
      height="13.4044"
      rx="1"
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M9.91543 15.9637C12.0304 15.9637 13.7449 14.2491 13.7449 12.1342C13.7449 10.0192 12.0304 8.30469 9.91543 8.30469C7.80046 8.30469 6.08594 10.0192 6.08594 12.1342C6.08594 14.2491 7.80046 15.9637 9.91543 15.9637Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.6931 17.9129L12.7441 14.9639"
      stroke={color}
      strokeWidth="1.80781"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="9.93075" cy="12.0655" r="3.16708" fill="white" />
  </svg>
);

export const examShareIcon = (color = appColor) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3024_54895)">
      <path
        d="M23.3351 12.7282H11.6678"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.3346 12.7277L9.01572 19.622L11.6674 12.7277L9.01572 5.8334L23.3346 12.7277Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3024_54895">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(12.728) rotate(45)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const uploadIcon = (color = appColor) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6797 7L12.6797 3L8.67969 7"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6797 12L12.6797 3"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.13086 12C7.13086 11.4477 6.68314 11 6.13086 11C5.57857 11 5.13086 11.4477 5.13086 12H7.13086ZM6.13086 16.3002H5.13086V17.3002H6.13086V16.3002ZM19.2275 16.3002V17.3002H20.2275V16.3002H19.2275ZM20.2275 12C20.2275 11.4477 19.7797 11 19.2275 11C18.6752 11 18.2275 11.4477 18.2275 12H20.2275ZM5.13086 12V16.3002H7.13086V12H5.13086ZM6.13086 17.3002H19.2275V15.3002H6.13086V17.3002ZM20.2275 16.3002V12H18.2275V16.3002H20.2275Z"
      fill={color}
    />
  </svg>
);
