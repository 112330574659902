import { combineReducers } from "redux";
import auth from "./auth/reducer";
import scheduling from "./scheduling/reducer";
import dependent from "./dependent/reducer";
import chatReducer from "./chat-bot/reducer";

const rootReducer = combineReducers({
    auth,
    scheduling,
    dependent,
    chatReducer
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
