import { IonContent, IonPage } from "@ionic/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RESET, RESET_DEPENDENT, RESET_PATIENT } from "../../Redux/action";
import { RootState } from "../../Redux/reducer";
import storeService from "../../services/store.service";
import { navigateToRoute } from "../../utils/helper";
import Modal from "../UI/Modal";
import CustomLoader from "../UI/customLoader";
import Footer from "./Footer";
import Header from "./Header";

interface LayoutProps {
  children?: string | JSX.Element | JSX.Element[] | React.ReactNode;
  loading?: boolean;
  routeName?: string;
  handleBackClick?: () => void
}

const Layout: React.FC<LayoutProps> = ({ children, routeName, loading, handleBackClick }) => {
  const { currentUserType, isPatientLink } = useSelector((state: RootState) => state.auth);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  
  const dispatch = useDispatch();
  
  const logout = async () => {
    if(currentUserType === "patient" || currentUserType === "dependent") {
      if(isPatientLink) {
        window.location.href = "https://www.avus.com.br/";
        dispatch({ type: RESET_PATIENT , payload: { currentUserType: "", user: {}, updateUser: {} }});
        dispatch({ type: RESET_DEPENDENT });
        await storeService.remove();
      } else {
        const operatorUser = await storeService.get("operator_login");
        await storeService.set("currentUserType", "operator");
        await storeService.logoutOperator();
        dispatch({ type: RESET_PATIENT , payload: { currentUserType: "operator", user: operatorUser, updateUser: {} }});
        navigateToRoute(navigate, "/patient/login", true);
      }
    } else {
      navigateToRoute(navigate, "/login", true);
      await storeService.logout();
      dispatch({ type: RESET , payload: { currentUserType: "", user: {}, updateUser: {} }});
    }
    setIsOpen(false);
  };

  return (
    <IonPage id="main">
      <Header setIsOpen={setIsOpen} routeName={routeName} handleBackClick={handleBackClick} />
      <IonContent>{children}</IonContent>
      <Footer routeName={routeName} />
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        text={(currentUserType === "operator" || location.pathname === "/patient/login") ? " Tem certeza que deseja sair da plataforma do operador?" : "Tem certeza de que deseja sair?"}
        btnSimText={(currentUserType === "operator" || location.pathname === "/patient/login") ? "Sim, Sair" : "Sim"}
        handleClick={logout}
        btnCancelText={(currentUserType === "operator" || location.pathname === "/patient/login") ? "Não, continuar logado" : "Cancelar"}
        btnCancelClick={() => setIsOpen(false)}
        btnSimColor={(currentUserType === "operator" || location.pathname === "/patient/login") ? "danger" : "secondary"}
        // className="[&>div>div>ion-button]:mx-1 [&>div>div>ion-button]:my-0"
        btnClass={`flex flex-col m-auto ${(currentUserType === "operator" || location.pathname === "/patient/login") ? "w-[80%]" : "w-[60%]"}`}
        btnSimFill="outline"
        customTitleColor="text-secondary sm:text-sm md:text-xl"
      />
      {loading ? <CustomLoader showLoading={loading} msgText="Obtendo detalhes..." /> : null}
    </IonPage>
  );
};

export default Layout;
