import { Browser } from "@capacitor/browser";
import { isEmpty } from "lodash";
import { isDesktop } from "../utils/helper";

export const bgGradient = "bg-gradient-to-r from-[#1059b2] from-27.4% via-[#2e8ad5] via-51.66% to-[#46b7f8] to-95.83%"

export type presentType = ({ message, duration } : { message: string, duration: number }) => void

export const downloadFile = async (url: string, present: presentType) => {
  if (isEmpty(url)) {
    present({
      message: "problema na busca de URL entre em contato com o suporte",
      duration: 5000,
    });
  } else {
    if (isDesktop) {
      window.open(url, "_blank");
    } else {
      await Browser.open({ url, presentationStyle: "fullscreen" });
    }
  }
};

export const validationRules = [
  { label: "8 caracteres", rule: "CHAR_LENGTH" },
  { label: "Letras e números", rule: "LETTER_NUMBER" },
  { label: "Letras maiúsculas", rule: "UPP_CAS" },
  { label: "Letras minúsculas", rule: "LOW_CAS" },
  { label: "Caracter especial", rule: "CHAR_SPEC" },
];
