import { IonCol, IonLabel, IonRow, useIonToast } from "@ionic/react";
import { AxiosResponse } from "axios";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import { RootState } from "../../Redux/reducer";
import CustomFormik from "../../components/UI/Formik";
import Modal from "../../components/UI/Modal";
import profileService from "../../services/profile.service";
import { IMG } from "../../styles/assets";
import { corporation, navigateToRoute } from "../../utils/helper";
import { initialValues, payloadInterface, registerMajorDependentField } from "./formFields";
import CustomLoader from "../../components/UI/customLoader";

const RegisterMajorDependents = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const [present] = useIonToast();
  const navigate = useNavigate();
  const [updatedata, setUpdateData] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nome completo obrigatório"),
    cpf: Yup.string().required("Nome completo obrigatório"),
    birthdate: Yup.string().required("A data de nascimento é obrigatória")
      .test("birthdate", "A data de nascimento não pode ser uma data futura.",(value) => {
        const todayDate = moment(new Date(), "DD/MM/YYYY").format("YYYY-MM-DD")
        const valueDate = moment(value, "DD/MM/YYYY").format("YYYY-MM-DD")
        return moment(todayDate).diff(moment(valueDate), "days") >= 0;
      })
      .test("birthdate", "A data de nascimento deve ser pelo menos 120 anos atrás",(value) => {
        const todayDate =  moment().subtract(120, "years").toDate()
        const valueDate = moment(value, "DD/MM/YYYY").format("YYYY-MM-DD")
        return moment(todayDate).diff(moment(valueDate), "years") <= 0;
      })
      .test("birthdate", "Adicione apenas dependentes principais", (value) => {
        const todayDate = moment(new Date(), "DD/MM/YYYY").format("YYYY-MM-DD")
        const valueDate = moment(value, "DD/MM/YYYY").format("YYYY-MM-DD")
        return moment(todayDate).diff(moment(valueDate), "years") >= 18;
      })
});

  const submitformMajor = async (values: any, setSubmitting: (submitting: boolean) => void) => {
    setLoading(true);
    setSubmitting(true);
    const data: payloadInterface = {
      parent_id: user.user_id,
      corporation,
      name: values?.name,
      cpf: values?.cpf.replace(/\D+/g, ""),
      birthdate: moment(values?.birthdate, "DD/MM/YYYY").format("YYYY-MM-DD"),
    };
    profileService
      .createDependentUser(data)
      .then((response: AxiosResponse) => {
        setLoading(false);
        setSubmitting(false);
        response?.data?.status === 200
          ? (setUpdateData(true),
            setTimeout(() => {
              navigateToRoute(navigate, "/profile", true);
            }, 3000))
          : present({ message: response?.data?.message, duration: 3000 });
      })
      .catch((error: { response: { data: { message: string } } }) => {
        setLoading(false);
        setSubmitting(false);
        error.response.data.message
          ? present({ message: error.response.data.message, duration: 3000 })
          : present({ message: "Algo deu errado!", duration: 3000 });
      });
  };

  return (
    <IonRow className="contentrow">
      <IonCol size="12" className="p-0">
        <div className="max-w-[240px]">
          <div className="mb-3 normal-case text-secondary text-base font-bold flex items-center">
            <span className="w-11 h-11 rounded-[50px] border-[1] border-secondary font-normal inline-block relative bg-secondary text-white text-[21px] mr-[1.7rem] p-0 flex justify-center items-center">
              18
              <span className="h-5 w-5 absolute -right-2 top-0 bg-white rounded-full">
                {IMG.PlusIcons}
              </span>
            </span>
            Maior de idade
          </div>
        </div>
        <div className="">
          <IonLabel className="font-bold text-2xl text-secondary">
            Insira os dados <br></br>do dependente{" "}
            <span className="text-ternary">maior de idade</span>
          </IonLabel>
        </div>
      </IonCol>
      <IonCol sizeMd="6" size="12" className="m-0 mt-5">
        <div className="max-w-full">
          <CustomFormik
            formInputFields={registerMajorDependentField}
            initialValues={initialValues}
            handleSubmit={(values, setSubmitting) => {
              submitformMajor(values, setSubmitting);
            }}
            validationSchema={validationSchema}
            btnName="Adicionar dependente" 
            btnIcon={IMG.adicionarButton}
          />
        </div>
      </IonCol>
      <IonCol sizeMd="6" size="12">
        <IonLabel class=" font-normal text-lg text-gray-500 text-center md:text-left block">
          Mais informações do dependente{" "}
          <span className="font-bold">maior de idade</span> serão registradas no
          primeiro acesso do próprio dependente na plataforma.
        </IonLabel>
      </IonCol>

      <Modal
        isOpen={updatedata}
        subText={"Dependente maior de idade adicionado com sucesso."}
        setIsOpen={setUpdateData}
        icon={IMG.successCheckedIcon}
      />
     {loading ? <CustomLoader showLoading={loading} msgText="Obtendo detalhes..." /> : null}
    </IonRow>
  );
};
export default RegisterMajorDependents;
