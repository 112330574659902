import { IonText } from "@ionic/react";
import { emptyMessage } from "./helper";
import { emptyMsgKey } from "./types";

interface EmptyMessageProps {
  tab: emptyMsgKey;
}

export const EmptyMessage: React.FC<EmptyMessageProps> = ({ tab }) => {
  return (
    <IonText className="block mt-5 text-center text-secondary">
      <span>{emptyMessage[tab]}</span>
    </IonText>
  );
};
