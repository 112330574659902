import moment from "moment";
import { formFieldType } from "../../../components/UI/Formik/types";
import * as Yup from "yup";

export const patientSignupInputFields: formFieldType[] = [
    {
        id: "name",
        name: "name",
        type: "text",
        placeholder: "Nome",
        inputType: "readonly",
        label: "Nome",
        disabled: true
    },
    {
        id: "birthdate",
        placeholder: "dd/mm/yyyy",
        name: "birthdate",
        type: "tel",
        inputType: "readonly",
        label: "Digite a data de nascimento",
        isLabelAfter: true,
        labelClassName: "text-ternary",
        disabled: true,
        mask: [
            /\d/,
            /\d/,
            "/",
            /\d/,
            /\d/,
            "/",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ],
    },
    {
        id: "celular",
        name: "celular",
        type: "text",
        label: "Digite o número do seu celular com DDD",
        inputType: "phone-input",
        isLabelAfter: true,
        placeholder: "",
        labelClassName: "text-ternary"
    },
    {
        id: "email",
        placeholder: "E-mail",
        name: "email",
        type: "text",
        label: "Digite o e-mail que você mais usa",
        inputType: "readonly",
        isLabelAfter: true,
        labelClassName: "text-ternary",
        disabled: true
    },
]

export const patientSignupInputSelfRegisterFields: formFieldType[] = [
    {
        id: "name",
        name: "name",
        type: "text",
        placeholder: "Nome",
        inputType: "text",
        label: "Nome",
        disabled: true
    },
    {
        id: "birthdate",
        placeholder: "dd/mm/yyyy",
        name: "birthdate",
        type: "tel",
        inputType: "text",
        label: "Digite a data de nascimento",
        isLabelAfter: true,
        labelClassName: "text-ternary",
        disabled: true,
        mask: [
            /\d/,
            /\d/,
            "/",
            /\d/,
            /\d/,
            "/",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
        ],
    },
    {
        id: "celular",
        name: "celular",
        type: "text",
        label: "Digite o número do seu celular com DDD",
        inputType: "phone-input",
        isLabelAfter: true,
        placeholder: "",
        labelClassName: "text-ternary"
    },
    {
        id: "email",
        placeholder: "E-mail",
        name: "email",
        type: "text",
        label: "Digite o e-mail que você mais usa",
        inputType: "text",
        isLabelAfter: true,
        labelClassName: "text-ternary",
        disabled: true
    },
]
export const validationSchema = Yup.object().shape({
    birthdate: Yup.string().required("Digite sua data de nascimento")
        .test("birthdate", "A data de nascimento não pode ser uma data futura.", (value) => {
            const todayDate = moment(new Date(), "DD/MM/YYYY").format("YYYY-MM-DD")
            const valueDate = moment(value, "DD/MM/YYYY").format("YYYY-MM-DD")
            return moment(todayDate).diff(moment(valueDate), "days") >= 0;
        })
        .test("birthdate", "A data de nascimento deve ser pelo menos 120 anos atrás", (value) => {
            const todayDate = moment().subtract(120, "years").toDate()
            const valueDate = moment(value, "DD/MM/YYYY").format("YYYY-MM-DD")
            return moment(todayDate).diff(moment(valueDate), "years") <= 0;
        }),
    email: Yup.string()
        .email("Email inválido")
        .required("E-mail é obrigatório"),
    celular: Yup.string().length(13, "12 number is required").required("Número de telefone é obrigatório")
});

export const validationSchemas = Yup.object().shape({
    name: Yup.string().required("Nome completo obrigatório"),
    biologicalSex: Yup.string().required("Sexo biológico é obrigatório"),
    gender: Yup.string().required("Gênero é obrigatório")
})

export const biologicalSexOptions = [
    { value: "male", label: "Masculino" },
    { value: "female", label: "Feminino" },
];

export const genderOptions = [
    { value: "male", label: "Masculino" },
    { value: "female", label: "Feminino" },
    { value: "other", label: "Agênero" },
    { value: "fluid genre", label: "Gênero Fluido" },
    { value: "non-binary gender", label: "Gênero não Binário" },
    { value: "queer", label: "Queer" },
    { value: "female trans", label: "Trans Feminino" },
    { value: "male trans", label: "Trans Masculino" },
];

export const initialValues = {
    username: "",
    biologicalSex: "",
    gender: "",
};

export const patientSignupInputFields2: formFieldType[] = [
    {
        id: "name",
        name: "name",
        type: "text",
        placeholder: "Nome",
        inputType: "input",
        label: "Como gosta de ser chamado?",
        isLabelAfter: true,
    },
    {
        id: "biologicalSex",
        name: "biologicalSex",
        type: "select",
        placeholder: "Selecione o sexo biológico",
        inputType: "select",
        label: "Sexo biológico",
        isLabelAfter: true,
        options: biologicalSexOptions,
    },
    {
        id: "gender",
        name: "gender",
        type: "select",
        placeholder: "Selecione o gênero",
        inputType: "select",
        label: "Gênero",
        isLabelAfter: true,
        options: genderOptions
    }
]

export interface patientInsertInterface {
    name: string,
    email: string,
    cpf: string,
    phone: string,
    countryCode: string,
    isActiveUser: boolean,
    nickname: string,
    gender: string,
    bioLogicalGender: string,
    isDependent: boolean,
    birthdate: string,
}