import React from "react";
import { Route, Routes } from "react-router-dom";
import Exam from "../pages/exams";
import History from "../pages/history";
import Home from "../pages/home";
import Medicine from "../pages/medicine";
import OperatorSignup from "../pages/operator/signup";
import PatientLogin from "../pages/patient/login";
import Profile from "../pages/profile";
import RegisterDependents from "../pages/registerDependents/registerDepenedent";
import SelectDependents from "../pages/registerDependents/selectRegisterDependents";
import CalendarScreen from "../pages/scheduling/CalendarScreen";
import ConfirmAppointment from "../pages/scheduling/ConfirmAppointment";
import ProffessionalScreen from "../pages/scheduling/ProffessionalScreen";
import ProtectedRoute from "./ProtectedRoute";
import AuthRoute from "./AuthRoute";
import MeusDependentes from "../pages/meusDependentes";
import PatientSupport from "../components/patient-support";
import SupportSuccess from "../components/patient-support/supportSucces";
import LoginFactorScreen from "../pages/operator/loginfactor/LoginFactorScreen";
import { PasswordScreen } from "../pages/operator/loginfactor/PasswordScreen";
import { SuccessScreen } from "../pages/operator/loginfactor/SuccessScreen";
import DependentUser from "../pages/profile/dependent";
import OperatorLogin from "../pages/operator/login";
import { PatientSignup } from "../pages/patient/signup";
import { PatientRegister } from "../pages/patient/signup/patientRegister";
import TwoFactorAuthScreen from "../pages/operator/loginfactor/TwoFactorAuthScreen";

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<OperatorSignup />} />
      <Route path="/login" element={<AuthRoute><OperatorLogin /></AuthRoute>} />
      <Route path="/patient/login" element={<AuthRoute><PatientLogin /></AuthRoute>} />
      <Route path="/patient/signup" element={<AuthRoute><PatientSignup /></AuthRoute>} />
      <Route path="/patient/register" element={<AuthRoute><PatientRegister /></AuthRoute>} />
      <Route path="/login-factor" element={<AuthRoute><LoginFactorScreen /></AuthRoute>} />
      <Route path="/two-factor-auth" element={<AuthRoute><TwoFactorAuthScreen /></AuthRoute>} />
      <Route path="/password" element={<AuthRoute><PasswordScreen /></AuthRoute>} />
      <Route path="/success" element={<AuthRoute><SuccessScreen /></AuthRoute>} />   
      <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
      <Route path="/history" element={<ProtectedRoute><History /></ProtectedRoute>} />
      <Route path="/medicine" element={<ProtectedRoute><Medicine /></ProtectedRoute>} />
      <Route path="/exams" element={<ProtectedRoute><Exam /></ProtectedRoute>} />
      <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
      <Route path="/dependent-user" element={<ProtectedRoute><DependentUser /></ProtectedRoute>} />
      <Route path="/selectdependents" element={<ProtectedRoute><SelectDependents /></ProtectedRoute>} />
      <Route path="/meusdependentes" element={<ProtectedRoute><MeusDependentes /></ProtectedRoute>} />
      <Route path="/registerdependent" element={<RegisterDependents />} />
      <Route path="/scheduling/professional-screen" element={<ProtectedRoute><ProffessionalScreen /></ProtectedRoute>} />
      <Route path="/scheduling/calendar-screen" element={<ProtectedRoute><CalendarScreen /></ProtectedRoute>} />
      <Route path="/scheduling/confirm-booking-screen" element={<ProtectedRoute><ConfirmAppointment /></ProtectedRoute>} />
      <Route path="/support" element={<ProtectedRoute><PatientSupport /></ProtectedRoute>} />
      <Route path="/support-success" element={<ProtectedRoute><SupportSuccess /></ProtectedRoute>} />
    </Routes>
  );
};
