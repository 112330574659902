import { IonCol, IonImg, IonLabel, IonProgressBar, IonRow } from "@ionic/react";
import Layout from "../../../components/Layout";
import { IMG } from "../../../styles/assets";
import { useNavigate } from "react-router";
import { navigateToRoute, useDidMountHook } from "../../../utils/helper";
import Grid from "../../../components/Grid";

export const SuccessScreen = () => {
  const navigate = useNavigate();

  useDidMountHook(() => {
    setTimeout(() => {
      navigateToRoute(navigate, "/login", true);
    }, 5000);
  });

  return (
    <Layout routeName="success">
      <Grid className="[&>ion-row]:w-full !px-0 !py-6 md:items-center">
        <IonRow className="md:px-0 mx-auto w-full max-w-[1320px] lg:items-center">
          <IonCol size="12" sizeMd="4" className="mx-auto text-center">
            <IonLabel className="mb-10 block font-bold text-2xl md:text-3xl text-ternary">
              Conta criada com <span className="text-success text-[#00D37A] block">sucesso!</span>
            </IonLabel>
            <IonImg src={IMG.successImg} className="w-56 mx-auto" />
            <IonProgressBar
              type="indeterminate"
              className=" w-[164px] mt-14 mx-auto rounded-[20px] bg-lightGray flex items-center justify-center"
            >
            </IonProgressBar>
            <IonLabel className="mt-4 block w-72 mx-auto">
              Em instantes você será direcionado para a tela inicial
            </IonLabel>
          </IonCol>
        </IonRow>
      </Grid>
    </Layout>
  );
};
