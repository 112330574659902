import { getPlatforms } from "@ionic/react";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import _ from "lodash";

class Platform {
    private _mobilePlatform = ["mobile", "mobileweb", "android"];
    private _desktopPlatform = ["desktop"];
    private _androidPlatform = ["android"];
    private _iosPlatform = ["iphone", "ios"];
    private _mobileWebPlatform = ["mobileweb"];

    public get isMobile() {
        return _.intersection(this._mobilePlatform, this.platform).length > 0;
    }

    public get isDesktop() {
        return _.intersection(this._desktopPlatform, this.platform).length > 0;
    }

    public get isAndroid() {
        return _.intersection(this._androidPlatform, this.platform).length > 0;
    }

    public get isIos() {
        return _.intersection(this._iosPlatform, this.platform).length > 0;
    }

    public get isMobileweb() {
        return _.intersection(this._mobileWebPlatform, this.platform).length > 0;
    }

    public get platform() {
        return getPlatforms();
    }
}

export default new Platform();


const takePicture = async () => {
    try {
        const image = await Camera.getPhoto({
            quality: 90,
            allowEditing: false,
            resultType: CameraResultType.Base64,
            source: CameraSource.Camera
        });
        return image.base64String;
    } catch (e) {
        console.error("[Error]: take picture", e)
    }
};

export { takePicture };
