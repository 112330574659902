const appColor = "#2E8AD5";

export const calendarIcon = (color = appColor) => (
  <svg
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.6406 2.69434H18.4136C18.9775 2.69578 19.5178 2.93086 19.916 3.34802C20.3142 3.76518 20.5379 4.33036 20.5378 4.9196V11.4582"
      stroke={color}
      strokeMiterlimit="10"
    />
    <path d="M7.66211 2.69434H14.4223" stroke={color} strokeMiterlimit="10" />
    <path
      d="M12.4896 18.3441H3.52888C2.91723 18.3441 2.33063 18.1116 1.89813 17.6977C1.46563 17.2838 1.22266 16.7224 1.22266 16.1371V4.90239C1.22266 4.31697 1.46559 3.7555 1.89806 3.34144C2.33053 2.92738 2.91712 2.69462 3.52888 2.69434H4.56289"
      stroke={color}
      strokeMiterlimit="10"
    />
    <path
      d="M17.4771 21.4744C20.5884 21.4744 23.1105 19.022 23.1105 15.9969C23.1105 12.9719 20.5884 10.5195 17.4771 10.5195C14.3659 10.5195 11.8438 12.9719 11.8438 15.9969C11.8438 19.022 14.3659 21.4744 17.4771 21.4744Z"
      stroke={color}
      strokeMiterlimit="10"
    />
    <path
      d="M6.67774 0.503906H5.75065C5.20663 0.503906 4.76562 0.965976 4.76562 1.53597V3.85377C4.76562 4.42376 5.20663 4.88584 5.75065 4.88584H6.67774C7.22175 4.88584 7.66277 4.42376 7.66277 3.85377V1.53597C7.66277 0.965976 7.22175 0.503906 6.67774 0.503906Z"
      stroke={color}
      strokeMiterlimit="10"
    />
    <path
      d="M16.5426 0.503906H15.5125C14.908 0.503906 14.418 0.965976 14.418 1.53597V3.85377C14.418 4.42376 14.908 4.88584 15.5125 4.88584H16.5426C17.147 4.88584 17.6371 4.42376 17.6371 3.85377V1.53597C17.6371 0.965976 17.147 0.503906 16.5426 0.503906Z"
      stroke={color}
      strokeMiterlimit="10"
    />
    <path
      d="M17.6406 12.085V16.1539H20.8597"
      stroke={color}
      strokeMiterlimit="10"
    />
    <path d="M1.22266 8.01562H20.5371" stroke={color} strokeMiterlimit="10" />
  </svg>
);

export const calendarGrayIcon = () => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5059 3.52393H17.2227C17.7455 3.5253 18.2465 3.7495 18.6158 4.14736C18.9851 4.54521 19.1924 5.08424 19.1924 5.6462V11.8822"
      stroke="#828282"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M7.25195 3.52393H13.5207"
      stroke="#828282"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M11.7291 18.4494H3.41981C2.85263 18.4494 2.30867 18.2277 1.90762 17.8329C1.50656 17.4382 1.28125 16.9028 1.28125 16.3446V5.62979C1.28125 5.07146 1.50653 4.53598 1.90755 4.14108C2.30858 3.74619 2.85253 3.5242 3.41981 3.52393H4.37865"
      stroke="#828282"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M6.3376 1.43457H5.47789C4.97341 1.43457 4.56445 1.87526 4.56445 2.41887V4.62941C4.56445 5.17302 4.97341 5.61371 5.47789 5.61371H6.3376C6.84208 5.61371 7.25104 5.17302 7.25104 4.62941V2.41887C7.25104 1.87526 6.84208 1.43457 6.3376 1.43457Z"
      stroke="#828282"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M15.4897 1.43457H14.5345C13.9739 1.43457 13.5195 1.87526 13.5195 2.41887V4.62941C13.5195 5.17302 13.9739 5.61371 14.5345 5.61371H15.4897C16.0502 5.61371 16.5046 5.17302 16.5046 4.62941V2.41887C16.5046 1.87526 16.0502 1.43457 15.4897 1.43457Z"
      stroke="#828282"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M1.28125 8.59863H19.1918"
      stroke="#828282"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <rect
      x="1.28125"
      y="8.76782"
      width="18.0001"
      height="9.33341"
      rx="2"
      fill="#828282"
    />
    <path
      d="M16.3567 21.4344C19.2418 21.4344 21.5807 19.0956 21.5807 16.2105C21.5807 13.3254 19.2418 10.9866 16.3567 10.9866C13.4716 10.9866 11.1328 13.3254 11.1328 16.2105C11.1328 19.0956 13.4716 21.4344 16.3567 21.4344Z"
      fill="#828282"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M15.8379 13.146V17.0266H18.823"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <rect
      x="4.61523"
      y="1.43457"
      width="2.66669"
      height="4.00003"
      fill="#828282"
    />
    <path
      d="M13.9473 1.43457L16.614 2.10124V5.10127L13.9473 5.4346V1.43457Z"
      fill="#828282"
    />
  </svg>
);
