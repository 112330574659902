import { corporation } from "../utils/helper";

const APP_CONFIG = {
    OTP_SIZE: 6,
    storeName: `${corporation}_db`,
    SECRET: "566B5970337336763979244226452948",
    algorithm : "aes-256-ctr",
    secretKey : "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
    iv : "iron-secret-keys"
};

export default APP_CONFIG;

