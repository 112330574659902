import { IonCol, IonIcon, IonImg, IonLabel, IonRow, IonText, useIonToast, useIonViewDidLeave } from "@ionic/react";
import { Field, Formik } from "formik";
import moment from "moment";
import { useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import { SCHEDULING } from "../../Redux/action";
import { RootState } from "../../Redux/reducer";
import Grid from "../../components/Grid";
import LeftGrid from "../../components/Grid/LeftGrid";
import Layout from "../../components/Layout";
import { Button } from "../../components/UI/Button";
import Modal from "../../components/UI/Modal";
import schedulingService from "../../services/scheduling-service";
import { IMG } from "../../styles/assets";
import { corporation, getDateMonth, getDay, getSlot, navigateToRoute, useWillMountHook } from "../../utils/helper";
import { AppointmentData, Country } from "./types";
import { handleSchedulingBack } from "./helper";

const ConfirmAppointment = () => {
  const { scheduling: { speciality, selectedStaff, date, slot } } = useSelector((state: RootState) => state.scheduling);
  const { user } = useSelector((state: RootState) => state.auth);
  
  const formikRef = useRef(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [celular, setCelular] = useState<string>("");
  const [celularErr, setCelularErr] = useState<string>("");
  const [formData, setFormData] = useState<{ email: string; celular: string; }>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isCancelAppointment, setIsCancelAppointment] = useState<boolean>(false);
  const [isCancelAppointmentSuccess, setIsCancelAppointmentSuccess] = useState<boolean>(false);

  const [present] = useIonToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const phoneNumberValid = (text = celular) => {
    if (!text || text.length <= 2) {
      setCelularErr("celular é obrigatório");
    } else if (text.length > 2 && text.length < 13) {
      setCelularErr("celular inválido");
    } else {
      setCelularErr("");
    }
  };

  const handleEditSlots = () => {
    dispatch({
      type: SCHEDULING,
      payload: { speciality, selectedStaff },
    });
    navigateToRoute(navigate, "/scheduling/calendar-screen", true);
  };

  useWillMountHook(() => {
    if (user?.telePhone[0]?.number) setCelular(user?.telePhone[0]?.number);
    else setCelular(user?.telePhone?.number);
    const data = { email: user?.email, celular: "55" + (user?.telePhone[0]?.number ? user?.telePhone[0]?.number : user?.telePhone?.number) };
    setFormData(data);
    setCelularErr("");
  });

  useIonViewDidLeave(() => {
    setCelular("");
    setFormData({ email: "", celular: "" });
    setCelularErr("");
  });

  const handleBookAppointment = async (values: { celular: string; email: string; }) => {
    setLoading(true);
    const currentDate = new Date();
    const startDate = new Date(currentDate);
    const endDate = new Date(currentDate);
    endDate.setDate(endDate.getDate() + 7);

    const beginOfWeek = startDate;
    beginOfWeek.setHours(0, 0, 0, 0);

    const endOfWeek = endDate;
    endOfWeek.setHours(23, 59, 59, 999);
    const _Date = moment(date).format("DD/MM/YYYY");
    const UTCdatetime = moment(_Date + " " + slot, "DD/MM/YYYY HH:mm").utc().format("DD/MM/YYYY HH:mm");

    const data: AppointmentData = {
      UTCdatetime: UTCdatetime,
      agenda_end_time: endOfWeek,
      agenda_speciality: speciality,
      agenda_start_time: beginOfWeek,
      date: moment(date).format("DD/MM/YYYY"),
      patient_email: values?.email,
      patient_mobile: values?.celular,
      scheduled_staff_id: selectedStaff?.scheduled_staff_id,
      staff: selectedStaff?.staff_id,
      start_time: slot,
      corporation: corporation,
      timezone: moment.tz.guess(),
    };
    schedulingService
      .bookAppointment(user?.user_id, data)
      .then((resp: { data: AppointmentData }) => {
        if (resp.data) {
          setModalOpen(true);
          setTimeout(() => {
            navigateToRoute(navigate, "/home", true);
            handleBackClick();
          }, 5000);
        }
        setLoading(false);
      })
      .catch((err: { response: { data: { message: string } } }) => {
        setLoading(false);
        present({ message: err?.response?.data?.message, duration: 5000 });
      });
  };

  const handleReset = () => {
    setIsCancelAppointment(false);
  };

  const handleBackClick = () => handleSchedulingBack(navigate, dispatch);

  return (
    <Layout loading={loading} routeName="scheduling" handleBackClick={handleBackClick}>
      <Grid className="[&>ion-row]:w-full p-0 !items-start">
        <IonRow className="md:px-0 mx-auto w-full max-w-[1320px]">
          <IonCol size="12" className="flex">
            <LeftGrid
              name={user?.name}
              title="Agendamento de consulta"
              backIcon={true}
              onBackClick={handleBackClick}
            />
          </IonCol>
          <IonCol size="12" sizeMd="6" sizeLg="4" className="px-3 md:px-0">

            <div className="flex-col md:ms-9 mx-auto mt-2 bg-gray-50 max-w-[363px] border border-gray-200 box-border shadow-xl shadow-gray-100 rounded-xl p-5 mb-0 text-center">
              <div className="text-center items-center">
                <div className="text-2xl border-b border-ternary pt-0 text-center mb-7 mt-0 font-medium pb-4 -ml-5 -mr-5 text-ternary">
                  {speciality}
                </div>
                <div className="flex items-center flex-col justify-start md:justify-center">
                  <IonImg
                    className="h-16 w-16 mx-5 rounded-full overflow-hidden object-cover"
                    src={selectedStaff?.avatar}
                  />
                  <IonText className="text-gray-500 font-bold text-lg">
                    {selectedStaff?.name}
                  </IonText>
                  <IonText className="">{selectedStaff?.crm}</IonText>
                </div>
              </div>
              <div className="text-2xl justify-center text-black my-2 p-0 box-border">
                {getDateMonth(date)}, {getDay(date)}, às {getSlot(slot)}
              </div>
              <div className="text-sm pt-2 font-bold text-secondary">
                <IonLabel className="cursor-pointer" onClick={handleEditSlots}>
                  Editar
                </IonLabel>
              </div>
            </div>

          </IonCol>
          <IonCol size="12" sizeMd="6" sizeLg="8">
            <div className="w-full">
              <>
                <Formik
                  innerRef={formikRef}
                  initialValues={{
                    celular: formData?.celular || "",
                    email: formData?.email || "",
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string().trim().email("Email inválido").required("E-mail é obrigatório"),
                    celular: Yup.string().required("celular é necessário").test("celular", "comprimento de célula inválido", (value: string | string[]) => !value?.includes("_")),
                  })}
                  enableReinitialize={true}
                  onSubmit={(values: { email: string; celular: string }) => {
                    handleBookAppointment(values);
                  }}
                >
                  {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
                    <form>
                      <IonRow className="items-end">
                        <IonCol size="12" sizeLg="6" className="px-4 xl:px-0">
                          <div className="max-w-[354px] mx-auto">
                            <IonCol size="12">
                              <IonLabel className="ml-0 font-semibold text-md mb-8 flex items-center justify-center text-ternary">
                                Verifique seu e-mail e telefone{" "}
                              </IonLabel>
                              <IonLabel className="label mt-3">
                                <strong className="justify-start ml-0 font-medium text-sm mb-4 items-center lg:justify-center text-secondary">
                                  Email
                                </strong>{" "}
                                -{" "}
                                <i className={errors.email && "text-red-700 text-sm"}>
                                  Preenchimento obrigatórioo
                                </i>
                              </IonLabel>
                              <div className="block w-full relative">
                                <Field
                                  className="m-0 mb-1 flex flex-row justify-between items-center border border-gray-400 box-border rounded-xl w-full pl-5 pr-1 pt-3 pb-3 bg-white"
                                  name="email"
                                  type={"text"}
                                  placeholder="Digite seu email"
                                  value={values.email}
                                  onChange={handleChange}
                                  onFocus={handleBlur}
                                />
                                <IonIcon
                                  color="dark"
                                  className="absolute right-5 top-4 z-10 max-w-[18px] min-w-[20px] cursor-pointer"
                                  src={IMG.editIcon}
                                />
                              </div>
                            </IonCol>
                            <IonCol size="12">
                              <IonLabel className="label">
                                <strong className="justify-start ml-0 font-medium text-sm mb-4 items-center lg:justify-center text-secondary">
                                  {" "}
                                  Telefone
                                </strong>{" "}
                                -{" "}
                                <i className={celularErr && "text-red-700 text-sm"}>
                                  Preenchimento obrigatório
                                </i>
                              </IonLabel>
                              <div className="block w-full relative">
                                <PhoneInput
                                  country={"br"}
                                  countryCodeEditable={true}
                                  buttonStyle={{
                                    background: "#FFF",
                                    padding: "0px 14px !important",
                                    height: "30px",
                                    borderRadius: "10px",
                                    border: "none",
                                    margin: "auto",
                                    marginLeft: "7px",
                                  }}
                                  containerStyle={{
                                    background: "#FFF",
                                    padding: "0px 14px !important",
                                    height: "50px",
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    fontWeight: "400",
                                  }}
                                  inputStyle={{
                                    background: "#FFF",
                                    padding: "0px 14px !important",
                                    height: "50px",
                                    width: "100%",
                                    border: "1px solid rgb(156, 163, 175)",
                                    borderRadius: "10px",
                                    color: "#3D3C3C",
                                    fontWeight: "600",
                                    fontSize: "16px",
                                  }}
                                  value={values?.celular}
                                  onChange={(phone: string, country: Country) => {
                                    setCelular(phone.slice(country?.dialCode?.length));
                                    phoneNumberValid(phone);
                                  }}
                                />
                                <IonIcon
                                  color="dark"
                                  className="absolute right-5 top-4 z-10 max-w-[18px] min-w-[20px] cursor-pointer"
                                  src={IMG.editIcon}
                                />
                              </div>
                            </IonCol>
                          </div>
                        </IonCol>
                        <IonCol size="12" className="text-center px-4 lg:px-0 mb-4 md:mb-0" sizeLg="6">
                          <Button
                            fill="solid"
                            text={"Confirmar agendamento"}
                            className="w-full max-w-sm rounded border-0 text-white h-12 font-normal text-base mb-6"
                            disabled={!values.email || celularErr !== ""}
                            id="confirm-save-btn"
                            handleClick={handleSubmit}
                          />
                          <Button
                            fill="outline"
                            shape="round"
                            color="danger"
                            className="!text-red-500 max-w-sm !border-red-500"
                            text={"Cancelar"}
                            id="confirm-cancel-btn"
                            handleClick={() => setIsCancelAppointment(true)}
                          />
                        </IonCol>
                      </IonRow>
                    </form>
                  )}
                </Formik>
              </>
            </div>
          </IonCol>
        </IonRow>
        <Modal
          isOpen={modalOpen}
          setIsOpen={setModalOpen}
          text={"Consulta agendada com sucesso!"}
          btnCancelClick={() => setModalOpen(false)}
          btnSimColor="success"
          btnSimFill="outline"
          icon={IMG.successCheckedIcon}
        />
        <Modal
          isOpen={isCancelAppointment}
          setIsOpen={handleReset}
          subText="Tem certeza que deseja cancelar a consulta?"
          btnSimText="Voltar"
          handleClick={() => setIsCancelAppointment(false)}
          btnCancelText="Sim"
          btnCancelFill="outline"
          btnCancelColor="danger"
          btnCancelClick={handleBackClick}
          className="className"
          btnClass={"flex flex-wrap-reverse"}
        />
        <Modal
          isOpen={isCancelAppointmentSuccess}
          setIsOpen={setIsCancelAppointmentSuccess}
          text="Teleconsulta cancelada com sucesso!"
          icon={IMG.successCheckedIcon}
        />
      </Grid>
    </Layout>
  );
};

export default ConfirmAppointment;
