
import store from "../utils/storage";
import { CryptoService } from "./crypto.service";

class StoreService extends CryptoService {
    private storeProvider: typeof store;

    constructor() {
        super();
        this.storeProvider = store;
    }

    public async get(key: string) {
        return this.storeProvider.get(key);
    }

    public async set(key: string, value: unknown) {
        return this.storeProvider.set(key, value);
    }

    public async remove() {
        return this.storeProvider.clear();
    }

    public async removeKey(key: string) {
        return this.storeProvider.remove(key);
    }

    public async logout() {
        await this.remove();
        await localStorage.removeItem("persist:root");
        window.location.href = "/";
    }

    public async logoutOperator() {
        await this.removeKey("patient_token");
        await this.removeKey("user");
        await this.removeKey("patient_signup");
        await this.removeKey("authorId");
        await this.removeKey("chatSupportStarted");
        await this.removeKey("chatSupportId");
    }
}

const storeService = new StoreService();
export default storeService;
