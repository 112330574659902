import moment from "moment";
import { User } from "../../Redux/auth/types";
import { APS_URL } from "../../config/api.config";
import complementoryService from "../../services/complement-service";
import { corporation } from "../../utils/helper";
import platform from "../../utils/platform";
import { HistoryItemType } from "./types";
import { Clipboard } from "@capacitor/clipboard";
import { presentType } from "../../helper/helper";

export const getAccessToken = async (users: User, appointment: HistoryItemType) => {
    const response: { roomName: string, videoAccessToken: string, videotoken: boolean, appointmentStartData: HistoryItemType | null, message: string }
        = { roomName: "", videoAccessToken: "", videotoken: false, appointmentStartData: null, message: "" };

    if (moment(new Date(appointment.UTCdatetime), "DD/MM/YYYY HH:mm").diff(moment(new Date(), "DD/MM/YYYY HH:mm"), "minutes") <= appointment.ETA) {
        response["roomName"] = appointment.id;
        const username: string = "participant_" + moment().unix();
        const createdById: string = appointment.staff;
        if (platform.isDesktop || platform.isMobileweb) {
            const userToken = btoa(
                `|${users?.cpf}||${users?.name}|${users?.email}||||||||APS`
            );
            window.location.href = `${APS_URL}/patient-appointments?name=${userToken}&&appointment_id=${appointment?.id}&corporation=${corporation}&state=pt&redirect_url=${window.location.origin}/home`;
        } else {
            const data = { username, roomName: response.roomName, createdById }
            complementoryService.getVideoAccessToken(data)
            .then((res) => {
                if (res) {
                    response["appointmentStartData"] = appointment
                    response["videoAccessToken"] = res.data.token;
                    response["videotoken"] = true;
                }
            })
            .catch(() => {
                response["appointmentStartData"] = null;
                response["videotoken"] = false;
            });
        }
    } else {
        response["appointmentStartData"] = null;
        response["videotoken"] = false;
        response["message"] = `As consultas só podem ser iniciada ${appointment.ETA || 30} minutos antes da hora marcada. Por gentileza aguarde o momento certo para iniciar o atendimento.`
    }
    return response
};

export const handleCopyLink = async (appointment: HistoryItemType, setModalOpen: (isOpen: boolean) => void, present: presentType) => {
    const { appointmentLink, ETA, UTCdatetime } = appointment;
    // if(moment(new Date(UTCdatetime), "DD/MM/YYYY HH:mm").diff(moment(new Date(), "DD/MM/YYYY HH:mm"), "minutes") <= ETA) {
        await Clipboard.write({
            string: appointmentLink?.includes("redirect_url") ? appointmentLink: `${appointmentLink}&redirect_url=https://www.avus.com.br/`
          });
          setModalOpen(true);
          setTimeout(() => {
            setModalOpen(false);
          }, 2000);
    // } else {
    //     const message = `As consultas só podem ser iniciada ${ETA || 30} minutos antes da hora marcada. Por gentileza aguarde o momento certo para iniciar o atendimento.`
    //     present({ message, duration: 2000 });
    // }
}