import moment from "moment";
import { CustomCard } from "../../components/UI/Card";
import { SchedulingItemType } from "../scheduling/types";
import { IonText, useIonToast } from "@ionic/react";
import { formatWeekDays } from "../../utils/helper";
import { Button } from "../../components/UI/Button";
import { IMG } from "../../styles/assets";
import Modal from "../../components/UI/Modal";
import { useState } from "react";
import { handleCopyLink } from "../history/api";
import React from "react";

export const FutureAppointmetmentsList = ({ futureData }: { futureData: SchedulingItemType; }) => {
  const { staffSpeciality, appointment_date, appointment_time } = futureData;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [present] = useIonToast();

  return (
    <>
      <CustomCard className="text-center w-full md:max-w-sm !max-w-[95%]">
        <IonText className="text-base font-semibold text-center flex justify-center w-100 text-[#5f5f5f]">
          Você tem uma consulta agendada com
        </IonText>
        <IonText className="font-semibold text-secondary text-2xl flex justify-center py-2 px-0">
          {staffSpeciality}
        </IonText>
        <IonText className="font-normal text-xl text-secondary mb-2">
          <strong className="mr-1">
            {moment(appointment_date, "DD/MM/YYYY").format("DD/MM")}
          </strong>
          , {formatWeekDays(moment(appointment_date, "DD/MM/YYYY").day())}, às
          <strong> {appointment_time?.replace(":", "h")}</strong>
        </IonText>
        <Button
          className="mt-3"
          text="Copiar link da consulta"
          color="primary"
          fill="outline"
          id="copy-appointment-btn"
          imgSrc={IMG.copyIcon}
          handleClick={() => handleCopyLink(futureData, setModalOpen, present)}
        />
      </CustomCard>
      <Modal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        text={"Link copiado com sucesso"}
        btnCancelClick={() => setModalOpen(false)}
        btnSimColor="success"
        btnSimFill="outline"
        icon={IMG.successCheckedIcon}
      />
    </>
  );
};
