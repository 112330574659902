import { IonImg, IonLabel, IonText } from "@ionic/react";
import React from "react";
import { IMG } from "../../styles/assets";
import { navigateToRoute } from "../../utils/helper";
import { useNavigate } from "react-router";
interface Props {
  name: string | undefined;
  title: string | undefined;
  backIcon?: boolean;
  backClick?: () => void;
}

const Content: React.FC<Props> = ({
  name,
  title,
  backIcon,
  backClick,
}) => {
  const navigate = useNavigate();
  return (
    <>
      {name ? <IonText className="text-black font-medium block mb-1">Olá, {name}</IonText> : null}
      <IonLabel className="flex items-start pt-2">
        {backIcon ? (
          <IonImg
            src={IMG.backIcon}
            className="w-6 h-6 mt-1 mr-3 cursor-pointer"
            onClick={() => (backClick ? backClick() : navigateToRoute(navigate, "/home", true))}
          />
        ) : null}
        <IonText className="text-primary text-xl  xl:text-3xl font-semibold flex align-center">
          {title}
        </IonText>
      </IonLabel>
    </>
  );
};

export default Content;
