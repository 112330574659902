import { IonCol, IonLabel, IonProgressBar, IonRow, IonText } from "@ionic/react"
import LeftGrid from "../../../components/Grid/LeftGrid"
import RightGrid from "../../../components/Grid/RightGrid"
import Layout from "../../../components/Layout"
import { IMG } from "../../../styles/assets"
import { validationRules } from "../../../helper/helper"
import { useEffect, useState } from "react"
import React from "react"
import { Button } from "../../../components/UI/Button"
import Input from "../../../components/UI/Input"
import { AxiosResponse } from "axios"
import authService from "../../../services/auth-service"
import storeService from "../../../services/store.service"
import { useNavigate } from "react-router"
import { navigateToRoute } from "../../../utils/helper"
import Grid from "../../../components/Grid"

export const PasswordScreen = () => {
  const [password, setPassword] = useState<string>("");
  const [progressBar, setProgressBar] = useState<number>(0);
  const [toggle, setToggle] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const rulesValidation: { [key: string]: string | boolean } = {
    "LETTER_NUMBER": /[0-9]+/g.test(password),
    "CHAR_LENGTH": String(password).length >= 8,
    "UPP_CAS": /[A-Z]+/g.test(password),
    "LOW_CAS": /[a-z]+/g.test(password),
    // eslint-disable-next-line
    'CHAR_SPEC': /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password),
  };

  const ruleCheck = (rule: string) => rulesValidation[rule] || false;
  const isPasswordAllow = validationRules.every((r) => ruleCheck(r.rule));

  const isRuleCheck = (rule: string) => ruleCheck(rule) ? IMG.checkedIcon : IMG.checkedDisabledIcon;

  useEffect(() => {
    let temp = 0;
    validationRules.forEach((rule) => {
      if (isRuleCheck(rule.rule) === IMG.checkedIcon) temp++;
    });
    setProgressBar(temp / validationRules.length);
  }, [password]);

  const handleToggle = () => setToggle(!toggle);

  const navigateToPasswordSuccess = async () => {
    setLoading(true);
    try {
      const signUpData = await storeService.get("operator_signup");
      const payload = { password, emailverified: signUpData?.emailverified }
      const resp: AxiosResponse = await authService.passwordCreation(signUpData?.id, payload)
      const { data: { result } } = resp
      if (result) {
        await storeService.removeKey("operator_signup");
        navigateToRoute(navigate, "/success", true)
      }
      setLoading(false);
      setPassword("");
    } catch (err) {
      console.error("[Error]: Password creation", err);
      setLoading(false);
    }
  }
  return (
    <Layout routeName="passwordscreen" loading={loading}>
      <Grid className="[&>ion-row]:w-full items-start !px-0 !py-5 [&>ion-row>ion-img]:ml-3 [&>ion-row>ion-img]:mt-0 md:[&>ion-row>ion-img]:hidden" handleBackClick={() => navigateToRoute(navigate, "/", true)} backIcon={true}>
        <IonRow className="md:px-0 mx-auto w-full max-w-[1320px] lg:items-center">
          <LeftGrid
            src={IMG.passwordImg}
            backIcon={true}
          />
          <RightGrid className="!max-w-sm lg:ml-12 lg:mr-0 p-5 lg:p-0 mx-auto">
            <IonLabel className="font-bold text-2xl text-ternary block mb-5">Defina sua senha</IonLabel>
            <IonRow className="passfields [&>.input-wrapper>span]:top-10 [&>.input-wrapper>ion-input]:!pr-12 [&>.input-wrapper>span]:cursor-pointer [&>.input-wrapper>ion-label]:ml-3 [&>.input-wrapper>ion-label]:text-xs [&>.input-wrapper>span]:w-5">
              <Input
                name="password"
                type={toggle ? "text" : "password"}
                placeholder="Senha"
                className="mb-0 passwordinput mt-1"
                label="Senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                icon={toggle ? IMG.visible : IMG.hidden}
                iconClick={handleToggle}
              />
              <IonProgressBar value={progressBar} className={"progress-bar-password"} />
              <IonRow className="list-password my-5 w-full leading-7">
                <IonCol className="p-0">
                  {validationRules.map((rule) => (
                    <React.Fragment key={`rule_${rule.rule}`}>
                      <IonLabel className="flex items-center">
                        {isRuleCheck(rule.rule)}
                        <IonText className={ruleCheck(rule.rule) ? "font-semibold ml-2" : "ml-2"}>
                          {rule.label}
                        </IonText>
                      </IonLabel>
                    </React.Fragment>
                  ))}
                </IonCol>
              </IonRow>
              <Button
                className="orange-btn w-100"
                color="primary"
                fill="solid"
                text="Entrar"
                id="password-btn"
                handleClick={navigateToPasswordSuccess}
                disabled={!isPasswordAllow || loading}
              />
            </IonRow>
          </RightGrid>
        </IonRow>
      </Grid>
    </Layout>
  )
}
