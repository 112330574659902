import { IonLabel, IonText } from "@ionic/react";
import PhoneInput from "react-phone-input-2";
import { formFieldType } from "../Formik/types";

export const CustomPhoneInput = ({ field, value, setValue, errors, errorText, errorclass }: {
  field: formFieldType, value: any, errors?: object | string | unknown,
  errorText?: string, errorclass?: string, setValue: (filedName: string, val: string) => void
}) => {
  return (
    <>
      <div className="input-wrapper mb-5">
        <PhoneInput
          country={"br"}
          countryCodeEditable={false}
          buttonStyle={{
            background: "rgb(244, 245, 247)",
            padding: "0px 14px !important",
            height: "30px",
            borderRadius: "10px",
            border: "none",
            margin: "auto",
            marginLeft: "7px",
          }}
          containerStyle={{
            background: "rgb(244, 245, 247)",
            padding: "0px 14px !important",
            height: "45px",
            width: "100%",
            borderRadius: "10px",
            border: "none",
            fontWeight: "400",
          }}
          inputStyle={{
            background: "rgb(244, 245, 247)",
            padding: "0px 14px !important",
            height: "45px",
            width: "100%",

            border: "1px solid rgb(244, 245, 247)",
            borderRadius: "10px",
            color: "#3D3C3C",
            fontWeight: "600",
            fontSize: "16px",
          }}
          value={value || ""}
          onChange={(phone: string) => {
            setValue("celular", phone)
          }}
        />
        <IonLabel className={field.labelClassName}>{field?.label}</IonLabel>
        {errors ? (
          <IonText className={`text-red-500 text-xs font-normal block ${errorclass ? errorclass : ""}`}>{errorText}</IonText>
        ) : null}
      </div>
      {/* <IonIcon
        color="dark"
        className="absolute right-5 top-4 z-10 max-w-[18px] min-w-[20px] cursor-pointer"
        src={IMG.editIcon}
      /> */}
    </>
  );
};
