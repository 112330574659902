import { IonButton, IonCol, IonGrid, IonImg, IonLabel, IonRow } from "@ionic/react";
import { useNavigate } from "react-router";
import LeftGrid from "../../components/Grid/LeftGrid";
import RightGrid from "../../components/Grid/RightGrid";
import Layout from "../../components/Layout";
import { IMG } from "../../styles/assets";
import { navigateToRoute, useDidMountHook } from "../../utils/helper";
import { useDispatch } from "react-redux";
import { CREATE_DEPENDENT, DEPENDENTTYPE } from "../../Redux/action";

const SelectDependents = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useDidMountHook(() => {
    dispatch({ type: CREATE_DEPENDENT, payload:{ createDependent: {} , isMinorSecondDetails : false} });
  });

  return (
    <Layout routeName="selectdependents">
      <IonImg src={IMG.backIcon} className="h-6 w-6 mx-3 my-6 md:hidden" onClick={() => navigateToRoute(navigate, "/profile", true)} />  
      <IonGrid className="[&>ion-row]:w-full p-0 !items-start py-2">
      <IonRow className="md:px-0 mx-auto w-full max-w-[1320px]">
          <LeftGrid
            title="Cadastro de dependentes"
            src={IMG.userInfoImg}
            backIcon={true}
            onBackClick={() => navigateToRoute(navigate, "/profile", true)}
            ImgClassname="!max-w-2xl md:mt-12"
          />
          <RightGrid className="md:mt-20">
            <IonCol sizeMd="6" className="text-left">
              <IonLabel className="text-secondary font-bold text-2xl max-w-[279px] md:max-w-[335px] mx-auto md:mx-0 block mb-4 mb-md-4 text-center md:text-left">
                Por favor, qual o perfil do dependente a ser cadastrado?
              </IonLabel>
              <div className="max-w-[240px] selectdependents-btns mx-auto md:mx-0">
                <IonButton
                  className="my-3 capitalize text-base h-12 btn-primary"
                  size="large"
                  shape="round"
                  type="button"
                  onClick={() => {
                    navigateToRoute(navigate, "/registerdependent", true);
                    dispatch({ type: DEPENDENTTYPE, payload: "major" });
                  }}
                >
                  <span className="w-11 h-11 rounded-[50px] border-1 border-secondary inline-block relative bg-white text-secondary text-[21px] font-normal mr-7 flex items-center justify-center">
                    18
                    <span className="h-5 w-5 absolute -right-3 top-0 bg-white flex items-center justify-center rounded-full">
                      {IMG.PlusIcons}
                    </span>
                  </span>
                  <strong>Maior</strong> de idade
                </IonButton>  
                <IonButton
                  className="my-3 capitalize text-base h-12 btn-light"
                  size="large"
                  shape="round"
                  type="button"
                  onClick={() => {
                    navigateToRoute(navigate, "/registerdependent", true);
                    dispatch({ type: DEPENDENTTYPE, payload: "minor" });
                  }}
                >
                  <span className="w-11 h-11 rounded-[50px] border border-secondary inline-block relative bg-white text-secondary text-[21px] font-normal mr-7 flex items-center justify-center">
                    18
                    <span className="h-5 w-5 absolute -right-3 top-0 bg-white flex items-center justify-center rounded-full">
                      {IMG.IconMinus}
                    </span>
                  </span>
                  <strong>Menor</strong> de idade
                </IonButton>
              </div>
            </IonCol>
          </RightGrid>
        </IonRow>
      </IonGrid>
    </Layout>
  );
};

export default SelectDependents;
