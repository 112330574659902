import { IonCol, IonLabel, IonRow, IonText, useIonToast } from "@ionic/react";
import { isEmpty } from "lodash";
import React from "react";
import { CustomCard } from "../../components/UI/Card";
import { EmptyMessage } from "../../components/UI/EmptyMessage";
import { CustomList } from "../../components/UI/List";
import { dateFormat } from "../../utils/helper";
import { medicineList, medicineTabType } from "./types";
import {
  appColor,
  disabledColor,
  medicinePillIcon,
  medicineViewIcon,
  downloadMedicineIcon,
} from "../../styles/svg/medicine-svg";
import { downloadFile } from "../../helper/helper";
import Share from "../../components/WebShare";
import { examShareIcon } from "../../styles/svg/exam-svg";

interface MedicineListProps {
  data: medicineList[];
  loading: boolean;
  activeTab: medicineTabType;
}

const MedicineList: React.FC<MedicineListProps> = ({
  data,
  loading,
  activeTab,
}) => {
  const [present] = useIonToast();
  const dosage = (entity: medicineList) =>
    entity.frequency &&
    (entity.frequencyTime === "hours"
      ? `${entity.frequency}x ao dia durante ${entity.frequency} horas`
      : `1x ao dia durante ${entity.frequency} dias`);

  return (
    <>
      {!isEmpty(data) ? (
        <CustomList className="">
          {data.map((item: medicineList) => (
            <CustomCard
              className={
                "shadow-none p-4 mx-2.5 bg-grayColor border border-solid !max-w-full border-[#dfdfdf] rounded-xl"
              }
              key={item.id}
            >
              <IonLabel className="flex justify-between">
                <IonLabel className="w-10">
                  {medicinePillIcon(
                    activeTab === "novas" ? appColor : disabledColor
                  )}
                </IonLabel>
                <div className="w-full ms-5">
                  <IonLabel className="flex justify-between">
                    <IonText className="text-base text-[#757575] font-medium">
                      {dateFormat(item.start)}
                    </IonText>
                    <IonLabel
                      className={`${
                        activeTab === "novas" ? "cursor-pointer" : ""
                      } flex`}
                    >
                      <span
                        className="h-5 w-5"
                        onClick={() => window.open(item.file, "_blank")}
                      >
                        {medicineViewIcon(
                          activeTab === "novas" ? appColor : disabledColor
                        )}
                      </span>
                      <IonText
                        className={`${
                          activeTab === "novas"
                            ? "text-secondary"
                            : "text-[#757575]"
                        } ml-1 text-sm font-semibold`}
                        onClick={() => window.open(item.file, "_blank")}
                      >
                        Visualizar
                      </IonText>
                    </IonLabel>
                  </IonLabel>
                  <IonLabel className="font-normal text-xl text-black block">
                    {item.name}
                  </IonLabel>
                  <IonLabel
                    className={`font-semibold text-sm ${
                      activeTab === "novas"
                        ? "text-secondary"
                        : "text-[#4f4f4f]"
                    } mt-8`}
                  >
                    {dosage(item)}
                  </IonLabel>
                  {activeTab === "novas" ? (
                    <IonRow className="mt-2 pt-2 border-t-2 border-gray-200">
                      <IonCol
                        size="6"
                        className="p-0 flex items-center justify-start"
                      >
                        <IonLabel
                          className="text-secondary xl:text-base text-sm font-semibold mr-1 cursor-pointer"
                          onClick={() => downloadFile(item.file, present)}
                        >
                          Download
                        </IonLabel>
                        <div
                          className="cursor-pointer"
                          onClick={() => downloadFile(item.file, present)}
                        >
                          {downloadMedicineIcon(appColor)}
                        </div>
                      </IonCol>
                      <IonCol
                        size="6"
                        className="p-0 flex items-center justify-end cursor-pointer"
                      >
                        <Share
                          label=""
                          text="Enviar receita"
                          textClassName="text-secondary font-semibold !no-underline"
                          sendIcon={examShareIcon()}
                          present={present}
                          fileurl={item.file}
                          fileName={item.name}
                        />
                      </IonCol>
                    </IonRow>
                  ) : null}
                </div>
              </IonLabel>
            </CustomCard>
          ))}
        </CustomList>
      ) : !loading ? (
        <EmptyMessage tab={"medicine"} />
      ) : null}
    </>
  );
};

export default MedicineList;
