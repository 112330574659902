import { RESET_SCHEDULING, SCHEDULING } from "../action";
import { updateObject } from "../updateObject";
import { SchedulingActionTypes, SchedulingState } from "./types";

const initialState: SchedulingState = {
  scheduling: {
    speciality: "",
    staff: {
      staff_id: "",
      name: "",
      scheduled_staff_id: "",
      title: "",
      avatar: "",
      job: "",
      crm: "",
      siglaConselho: "",
    },
    selectedStaff: {
      staff_id: "",
      name: "",
      scheduled_staff_id: "",
      title: "",
      avatar: "",
      job: "",
      crm: "",
      siglaConselho: "",
    },
    date: "",
    slot: "",
  },
};

const schedulingReducer = (
  state = initialState,
  action: SchedulingActionTypes
): SchedulingState => {
  switch (action.type) {
    case SCHEDULING:
      return updateObject(state, { scheduling: action.payload });
    case RESET_SCHEDULING:
      return updateObject(state, { scheduling: {} });
    default:
      return state;
  }
};

export default schedulingReducer;
