import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./Redux";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import platform from "./helper/platform";
import "./tailwind.css";
import { liveSupport } from "@irontrainers/palibrary/dist/clients/iron-fit-patient";


const { LivePatientSyncProvider } = liveSupport;

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <LivePatientSyncProvider>
        <App />
      </LivePatientSyncProvider>
    </Provider>
  </React.StrictMode>
);

platform?.isDesktop && defineCustomElements(window);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
