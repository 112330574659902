import { IonRow, useIonToast, useIonViewDidLeave } from "@ionic/react";
import { AxiosError, AxiosResponse } from "axios";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/reducer";
import Grid from "../../components/Grid";
import LeftGrid from "../../components/Grid/LeftGrid";
import RightGrid from "../../components/Grid/RightGrid";
import Layout from "../../components/Layout";
import { Button } from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Tabs from "../../components/UI/Tabs";
import complementaryService from "../../services/complement-service";
import { IMG } from "../../styles/assets";
import ExamsList from "./ExamList";
import { ExamsItemType, ExamsItemsType, examsTabType } from "./types";
import complementoryService from "../../services/complement-service";
import { navigateToRoute, useDidMountHook } from "../../utils/helper";
import PicUpload from "../../components/PicUpload";
import { uploadIcon } from "../../styles/svg/exam-svg";
import { useNavigate } from "react-router";

const Exam = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();

  const tabs = [
    { value: "future", label: "Pedidos recentes" },
    { value: "past", label: "Pedidos Antigos" },
  ];
  const [activeTab, setActiveTab] = useState<examsTabType>("future");
  const [loading, setLoading] = useState<boolean>(false);
  const [pastExams, setPastExams] = useState<ExamsItemsType>([]);
  const [futureExams, setFutureExams] = useState<ExamsItemsType>([]);
  const [examTitle, setExamTitle] = useState<string>("");
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [examRef, setExamRef] = useState<HTMLInputElement | ExamsItemType | null>(null);
  const [present] = useIonToast();

  useDidMountHook(() => {
    getExams();
  });

  useIonViewDidLeave(() => {
    setExamTitle("");
    setExamRef(null);
  });

  const getExams = () => {
    setLoading(true);
    complementaryService
      .exam(user.id)
      .then((res: AxiosResponse) => {
        setLoading(false);
        const { data: { data, exam_results }} = res;
        const examFuture = data.filter((d: ExamsItemType) => d.status === "pending");
        const examPast = data.filter((d: ExamsItemType) => d.status !== "pending");
        const outerExam: [] = exam_results.map((e: ExamsItemType) => ({ ...e, type: "outer" }));
        setFutureExams(examFuture.concat(outerExam));
        setPastExams(examPast);
      })
      .catch((e) => {
        console.error("[Error]: get exam", e)
        setLoading(false)
      });
  };

  const deleteExam = (examId: string, attachmentId: string | null, type: string) => {
    setLoading(true);
    const data = { userId: user.id, examId, attachmentId, type };
    complementaryService
      .deleteExamdata(data)
      .then((res: AxiosResponse) => {
        setLoading(false);
        if (res) getExams();
        const { data: { message } } = res
        present({ message, duration: 3000 });
      })
      .catch((error: AxiosError) => {
        console.error("[Error]: exam delete", error);
        setLoading(false);
      });
  };

  const handleOuterExamUpload = () => {
    if (isEmpty(examTitle.trim()))
      present({ message: "Por favor, insira o nome do exame", duration: 3000 });
    else {
      setIsShowModal(true);
      setExamRef(null);
    }
  };

  const uploadPicture = async (attachment: string) => {
    setLoading(true);
    const data = { attachment, exam_id: examRef?.id, title: examTitle, userId: user?.id };
    complementoryService
      .UploadExamResult(data)
      .then((res: AxiosResponse) => {
        const { data: { message } } = res;
        if (message === "Success!") {
          setLoading(false);
          setExamTitle("");
          setExamRef(null);
          getExams();
          present({ message: "Sucesso!", duration: 3000 });
        }
      })
      .catch((error: AxiosError) => {
        console.error("[Error]: upload exam result", error);
        setLoading(false);
      });
  };

  return (
    <Layout routeName="exams" loading={loading} handleBackClick={() => navigateToRoute(navigate, "/home", true)}>
      <Grid className="[&>ion-row]:w-full exam-wrapper pt-0 pt-xl-4 px-0 mx-auto max-w-[1320px]">
        <LeftGrid
          title="Meus Exames"
          src={IMG.examImg}
          backIcon={true}
          name={user.name}
        />
        <RightGrid colSize="5" className="p-0 mx-auto lg:mx-0 w-full max-w-full">
          <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
          {activeTab === "future" ? (
            <ExamsList
              data={futureExams}
              deleteExam={deleteExam}
              setIsShowModal={setIsShowModal}
              setExamRef={setExamRef}
              loading={loading}
              activeTab={activeTab}
            />
          ) : (
            <ExamsList
              data={pastExams}
              deleteExam={deleteExam}
              setIsShowModal={setIsShowModal}
              setExamRef={setExamRef}
              loading={loading}
              activeTab={activeTab}
            />
          )}
          {activeTab === "future" ? (
            <IonRow className="text-[#262626] p-0 mt-4 relative max-w-xs m-auto text-center">
              <Input
                label={"Enviar outros exames"}
                value={examTitle}
                type="text"
                name="exam-title"
                onChange={(event: { target: { value: string } }) => setExamTitle(event.target.value)}
                placeholder="Escreva o nome do exame"
                className="max-w-[296px] mx-auto rounded bg-white border border-gray-500"
                labelClassName="text-secondary !mb-3 block"
              />
              <Button
                imgSrc={uploadIcon("white")}
                text="Upload de outros exames"
                handleClick={handleOuterExamUpload}
                imgSlot="start"
                id="exam-upload-btn"
                className="max-w-[296px] mx-auto text-sm"
              />
            </IonRow>
          ) : null}

          <PicUpload setIsShowModal={setIsShowModal} isShowModal={isShowModal} uploadPicture={uploadPicture}/>
        </RightGrid>
      </Grid>
    </Layout>
  );
};

export default Exam;
