import { IonImg, IonModal, IonText } from "@ionic/react";
import React from "react";
import { Button } from "../Button";
interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  text?: string;
  subText?: string;
  subTexts?: string;
  btnSimText?: string;
  btnCancel?: string;
  btnSimColor?: string;
  buttonSimShape?: "round";
  btnSimFill?: "clear" | "outline" | "solid" | "default";
  imgSrc?: string;
  handleClick?: () => void;
  btnCancelText?: string;
  buttonCancelShape?: "round";
  btnCancelFill?: "clear" | "outline" | "solid" | "default";
  btnCancelColor?: string;
  btnCancelClick?: () => void;
  backDropDismiss?: boolean;
  children?: string | JSX.Element | JSX.Element[] | React.ReactNode;
  className?: string;
  btnClass?: string;
  icon?: string;
  iconClass ?: string;
  customTitleColor ?: string;
}

const Modal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  text = "",
  subText = "",
  subTexts = "",
  btnSimText,
  btnSimColor,
  btnSimFill,
  buttonSimShape,
  imgSrc,
  handleClick,
  btnCancelText,
  buttonCancelShape,
  btnCancelFill,
  btnCancelColor,
  btnCancelClick,
  backDropDismiss,
  children,
  className,
  btnClass,
  icon,
  iconClass,
  customTitleColor
}) => {
  return (
    <IonModal
      className={`custom-modal-wrapper ${className ? className : ""}`}
      isOpen={isOpen}
      onDidDismiss={() => setIsOpen(false)}
      backdropDismiss={backDropDismiss}
    >
      {children ? (
        children
      ) : (
        <>
          <IonText className={`text-center block font-bold ${customTitleColor}`}>{text}</IonText>
          <IonText className="text-center block modal-subtext text-xl font-semibold text-secondary m-">
            {subText}
          </IonText>
          <IonText className="text-sm text-center">{subTexts}</IonText>
          {icon ? <IonImg src={icon} className={`h-24  ${iconClass}`} /> : null}
          <div
            className={`${btnClass ? `${btnClass}` : "flex items-center justify-center"}`}
          >
            {btnSimText ? (
              <Button
                color={btnSimColor}
                fill={btnSimFill}
                shape={buttonSimShape}
                text={btnSimText}
                handleClick={handleClick}
                imgSrc={imgSrc}
                className="mt-3"
                id="sim-btn"
              />
            ) : null}
            {btnCancelText ? (
              <Button
                shape={buttonCancelShape}
                fill={btnCancelFill}
                color={btnCancelColor}
                text={btnCancelText}
                handleClick={btnCancelClick}
                id="cancel-btn"
                className="mt-4"
              />
            ) : null}
          </div>
        </>
      )}
    </IonModal>
  );
};

export default Modal;
