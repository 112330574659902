import { IonGrid, IonImg, IonLabel, IonProgressBar, IonRow } from "@ionic/react";
import { IMG } from "../../styles/assets";
import { useDidMountHook } from "../../utils/helper";
import Layout from "../Layout";
import "./SupportSuccess.scss";

const SupportSuccess = () => {
    useDidMountHook(() => {
        setTimeout(() => {
            window.location.href = "https://www.avus.com.br/"
        }, 5000);
    });

    return (
        <Layout routeName="support-success">
            <IonGrid className="succuess-review pass-box py-4">
                <IonRow className="container text-center">
                    <div className="inner-center mx-auto">
                        <IonLabel className="review-success-title">
                            Consulta online <br /> realizada com<br /> <span>sucesso!</span>
                        </IonLabel>
                        <IonImg src={IMG.successImg} className="review-success-img" />
                        <IonProgressBar type="indeterminate" className="success-progress" />
                    </div>
                </IonRow>
            </IonGrid>
        </Layout>
    );
}

export default SupportSuccess;