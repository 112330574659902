import { IonImg, IonItem, IonLabel, IonList } from "@ionic/react";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Button } from "../Button";
import { IMG } from "../../../styles/assets";
import { useDispatch } from "react-redux";
import { SCHEDULING } from "../../../Redux/action";
import { navigateToRoute } from "../../../utils/helper";
import { useNavigate } from "react-router";

interface SelectSearchItemProps {
  label: string;
  specialities: string[];
  loading: boolean;
}

const SelectSearch = ({ label, specialities, loading }: SelectSearchItemProps) => {
  const [specialityFilterAry, setSpecialityFilterAry] = useState<string[]>([]);

 

  const [value, setValue] = useState<string>("");
  const [openList, setOpenList] = useState<boolean>(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSelectedItem = (speciality: string) => {
    dispatch({ type: SCHEDULING, payload: { speciality } });
    setOpenList(false);
    navigateToRoute(navigate, "/scheduling/professional-screen", true);
  };

  const searchItem = (query: string) => {
    setValue(query);
    if (!query) setSpecialityFilterAry(specialities);
    else {
      const specialitiesRes = specialities.reduce((p: string[], n: string) => n.toLowerCase().includes(query) ? [...p, n] : p, []);
      setSpecialityFilterAry(specialitiesRes);
    }
  };

  const handleList = () => {
    if(!openList) {window.addEventListener("click", handleDocumentClick); document.getElementById("speciality-card")?.classList.add("mb-20");}
    else document.getElementById("speciality-card")?.classList?.remove("mb-20");
    if(!loading) setOpenList(!openList); 
  };

  useEffect(() => {
    setSpecialityFilterAry(specialities);
    return () => {
      window.removeEventListener("click", handleDocumentClick);
    }
  }, [specialities]);

  const handleDocumentClick = (e: any) => {
    if (e.target.id !== "search-btn" && !openList) {
      setOpenList(false)
      window.removeEventListener("click", handleDocumentClick);
    }
  }

  return (
    <>
      <Button
        text={label}
        handleClick={handleList}
        imgSrc={IMG.calendarWhiteIcon}
        id="search-btn"
        imgSlot="start"
        imgEndSrc={IMG.downArrowIcon}
        isLoading={loading}
        imgEndClass={openList ? "rotate-180" : ""}
      />
      {openList ? (
        <div className="max-w-sm absolute left-0 right-0 bg-white z-10 border mx-6 rounded-xl overflow-hidden pt-8 -mt-6">
          <IonLabel className="flex border-b pb-2">
            <IonImg src={IMG.searchIcon} alt="search-icon" className="h-7 w-7" />
            <input
              id="search-input"
              type="search"
              value={value}
              onChange={(e) => searchItem(e.target.value)}
              placeholder="Escolha um Especialista"
              className="bg-white border-none outline-none w-100"
            />
          </IonLabel>

          {isEmpty(specialityFilterAry) ? (
            <IonLabel>Nenhuma especialidade encontrada</IonLabel>
          ) : (
            <IonList className="max-h-48 overflow-y-auto">
              {specialityFilterAry.map((item: string) => (
                <IonItem key={item} onClick={() => handleSelectedItem(item)} lines="none" className="text-black border-b py-0 cursor-pointer">
                  {item}
                </IonItem>
              ))}
            </IonList>
          )}
        </div>
      ) : null}
    </>
  );
};

export default SelectSearch;
