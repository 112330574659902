import { CREATE_DEPENDENT, DEPENDENT, DEPENDENTTYPE, RESET_DEPENDENT } from "../action";
import { updateObject } from "../updateObject";
import { DependentActionTypes, DependentState, Details } from "./types";

const dependentData: Details = {
  nickname: "",
  birthdate: "",
  telePhone: [],
  isDependentUser: false,
  id: "",
  cpf: "",
  cardnumber: "",
  user_id: "",
  name: "",
  isBlocked: false,
  mainRole: "",
  roles: [],
  serviceAccess: false,
  privacy_policy: false,
  subscription: false,
  terms_of_use: false,
  corporationId: "",
  corporation: "",
  avatar: "",
  userType: "",
  ideConsulta: false,
  parentData: {
      name: "",
      email: "",
      phone: []
  }
}

const initialState: DependentState = {
  dependentuser: {
    major: [{...dependentData}],
    minor: [{...dependentData}],
  },
  dependentHasMajor: false,
  dependentHasMinor: false,
  dependentType: "",
  createDependent: {
    name: "",
    birthdate: "",
    celular: "",
    email: "",
  },
  isMinorSecondDetails: false,
};

const dependentReducer = (
  state = initialState,
  action: DependentActionTypes
): DependentState => {
  switch (action.type) {
    case DEPENDENT:
      return updateObject(state, { dependentuser: action.payload.dependentuser, dependentHasMajor: action.payload.dependentHasMajor, dependentHasMinor: action.payload.dependentHasMinor });
    case DEPENDENTTYPE:
      return updateObject(state, { dependentType : action.payload });
    case CREATE_DEPENDENT:
      return updateObject(state, { createDependent : action.payload.createDependent , isMinorSecondDetails : action.payload.isMinorSecondDetails });
    case RESET_DEPENDENT:
      return initialState;
    default:
      return state;
  }
};

export default dependentReducer;
