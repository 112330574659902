import { IonCol, IonGrid, IonImg, IonLabel, IonRow } from "@ionic/react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "../../Redux/reducer";
import LeftGrid from "../../components/Grid/LeftGrid";
import Layout from "../../components/Layout";
import { Button } from "../../components/UI/Button";
import { IMG } from "../../styles/assets";
import { navigateToRoute } from "../../utils/helper";
import React from "react";

type dependentType = { id: "major" | "minor", title: string, icon: string | JSX.Element, btnClass: string, divClass: string }

const MeusDependentes = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.auth);
  const { dependentuser } = useSelector((state: RootState) => state.dependent);

  const dependentArray: dependentType[] = [
    { id: "major", title: "Maior", icon: IMG.PlusIcons, divClass:"text-secondary", btnClass: "border-secondary bg-secondary"},
    { id: "minor", title: "Menor", icon: IMG.IconMinus, divClass:"text-[#0073bb]", btnClass: "border-[#008ce4] bg-[#008ce4]"}
  ]
  return (
    <Layout routeName="meusdependentes">
      <IonGrid className="[&>ion-row]:w-full p-0 !items-start px-3">
        <IonRow className="md:px-0 mx-auto w-full max-w-[1320px]">
        <LeftGrid
          title="Meus dependentes"
          backIcon={true}
          name={user.name}
          onBackClick={() =>
            navigateToRoute(navigate, "/profile", true)
          }
        />
        </IonRow>
        <IonImg src={IMG.backIcon} className="h-6 w-6 my-6 md:hidden" onClick={() => navigateToRoute(navigate, "/profile", true)} />
        <IonRow className="md:mt-5 md:px-0 mx-auto w-full max-w-[1320px]">
          {dependentArray.map((d: dependentType) => (
            <React.Fragment key={d.id}>
              <IonCol sizeMd="6" size="12" className={`${d.id === "major" ? "containercol lg:pl-[200px]" : ""}`}>
              <div className="ml-0">
                <div className={`mb-3 normal-case  text-base flex justify-center md:justify-start items-center ${d.divClass}`}>
                  <span className={`w-11 h-11 rounded-[50px] border-[1] relative text-white text-[21px] mr-[1.7rem] p-0 flex items-center justify-center ${d.btnClass}`}>
                    18
                    <span className="h-5 w-5 absolute -right-2 top-0 bg-white rounded-full">
                      {d.icon}
                    </span>
                  </span>
                  <strong>{d.title} &nbsp;</strong>  de idade
                </div>
              </div>
              {dependentuser && dependentuser[d.id]?.map((item: { name: string; id: string }) => (
                <div
                  className="w-full md:w-4/5 pt-5 pb-[10px] md:ml-14 border-b border-ternary md:max-w-[360px]"
                  key={item?.id}
                >
                  <IonLabel className="text-base flex items-center text-[#292929]">
                    {item?.name}
                  </IonLabel>
                </div>
              ))}
              </IonCol>
            </React.Fragment>
          ))}
        </IonRow>
        <IonCol className="flex items-center justify-center">
          <Button
            shape="round"
            type="reset"
            className="w-80 flex items-center justify-center max-w-[388px] mt-10"
            imgSrc={IMG.adicionarButton}
            imgSlot="start"
            imgClass="mr-2"
            text=" Cadastrar dependentes"
            id="create-dependent-btn"
            handleClick={() => navigateToRoute(navigate, "/selectdependents", true)}
          />
        </IonCol>
      </IonGrid>
    </Layout>
  );
};

export default MeusDependentes;
