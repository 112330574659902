import { IonCol, IonLabel, IonRow, useIonToast } from "@ionic/react";
import * as Yup from "yup";
import CustomFormik from "../../components/UI/Formik";
import { IMG } from "../../styles/assets";
import { payloadInterface, registerMinorDependentField2 } from "./formFields";
import profileService from "../../services/profile.service";
import { AxiosResponse } from "axios";
import { corporation, navigateToRoute } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useState } from "react";
import { RootState } from "../../Redux/reducer";
import { CREATE_DEPENDENT } from "../../Redux/action";
import Modal from "../../components/UI/Modal";
import CustomLoader from "../../components/UI/customLoader";
import moment from "moment";

const RegisterMinorDependentsFormTwo = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { createDependent } = useSelector(
    (state: RootState) => state.dependent
  );
  const dispatch = useDispatch();
  const [present] = useIonToast();
  const navigate = useNavigate();
  const [updatedata, setUpdateData] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const validationSchemas = Yup.object().shape({
    name: Yup.string().required("Nome completo obrigatório"),
    biologicalSex: Yup.string().required("Sexo biológico é obrigatório"),
    gender: Yup.string().required("Gênero é obrigatório"),
  });

  const submitformMinor = async (values: any, setSubmitting: (submitting: boolean) => void) => {
    setLoading(true);
    setSubmitting(true);
    const country = "55";
    const data: payloadInterface = {
      parent_id: user.user_id,
      corporation: corporation,
      birthdate: moment(createDependent.birthdate, "DD/MM/YYYY").format("YYYY-MM-DD"),
      name: createDependent.name,
      email: createDependent.email,
      phone: createDependent.celular,
      countryCode: country.toString(),
      userName: values?.name,
      gender: values?.gender,
      sex: values?.biologicalSex,
    };
    profileService
      .createDependentUser(data)
      .then((response: AxiosResponse) => {
        setLoading(false);
        setSubmitting(false);
        response?.data?.status === 200
          ? (setUpdateData(true),
            setTimeout(() => {
              navigateToRoute(navigate, "/profile", true);
            }, 3000))
          : present({
              message: response?.data?.message,
              duration: 3000,
            });
      })
      .catch((error: { response: { data: { message: string } } }) => {
        setLoading(false);
        setSubmitting(false);
        error.response?.data?.message
          ? present({
              message: error.response.data.message,
              duration: 3000,
            })
          : present({
              message: "Algo deu errado!",
              duration: 3000,
            });
      });
  };

  return (
    <>
      <IonRow className="p-0 registerdependent-two">
        <IonCol size="12">
          <div className="max-w-[240px]">
            <div className="mb-3 normal-case text-secondary text-base font-bold">
              <span className="w-11 h-11 rounded-[50px] border-[1] border-[#b2e1fe] inline-block relative bg-[#b2e1fe] text-secondary text-[21px] mr-[1.7rem] p-0 pl-2">
                18
                <span className="h-5 w-5 absolute -right-3 top-0">
                  {IMG.IconMinus}
                </span>
              </span>
              Menor de idade
            </div>
          </div>
          <div className="mt-[10px] mb-[30px] mx-0">
            <IonLabel className="font-bold text-2xl text-secondary">
              Insira os dados do <br></br> dependente{" "}
              <span className="text-ternary">menor de idade</span>
            </IonLabel>
          </div>

          <div className="text-xl text-[#5f5f5f] mt-1">
            Agora, preencha os dados pessoais
          </div>
        </IonCol>
        <CustomFormik
            formInputFields={registerMinorDependentField2}
            initialValues={{
              name: "",
              biologicalSex: "",
              gender: "",
            }}
            handleSubmit={(values, setSubmitting) => {
              submitformMinor(values, setSubmitting)
              dispatch({ type: CREATE_DEPENDENT, payload:{ createDependent: {} , isMinorSecondDetails : false} });
              navigateToRoute(navigate, "/profile", true);
            }}
            validationSchema={validationSchemas}
            btnName="Adicionar dependente"
            btnIcon={IMG.adicionarButton}
          />
        <Modal
          isOpen={updatedata}
          subText={"Dependente menor de idade adicionado com sucesso."}
          setIsOpen={setUpdateData}
          icon={IMG.successCheckedIcon}
        />
      </IonRow>
      {loading ? <CustomLoader showLoading={loading} msgText="Obtendo detalhes..." /> : null}
    </>
  );
};

export default RegisterMinorDependentsFormTwo;
