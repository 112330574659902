import { IonCardContent, IonCardHeader } from "@ionic/react";
import React from "react";
import { CustomCard, childrenPropType } from "../Card";

interface CardBoxProps {
  className: string;
  title: string;
  btnView?: childrenPropType;
  body?: childrenPropType;
  cardFooter?: string;
  cardHeaderClass?: string;
  cardFooterSubText?: string;
  id?: string;
}

const CardBox: React.FC<CardBoxProps> = ({ className, title, btnView, body, cardFooter, cardHeaderClass, cardFooterSubText ="", id }) => {
  return (
    <CustomCard className={className} id={id}>
      <IonCardHeader className={cardHeaderClass}>{title}</IonCardHeader>
      {btnView}
      {body}
      {cardFooter ? <IonCardContent className="text-normal text-base text-[#4f4f4f] pb-0">{cardFooter} {cardFooterSubText && <span className="text-primary font-bold">{cardFooterSubText}</span>}</IonCardContent> : null}
    </CustomCard>
  );
};

export default CardBox;
