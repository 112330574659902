import { menuController } from "@ionic/core";
import { IonAvatar, IonCol, IonHeader, IonIcon, IonImg, IonLabel, IonMenuButton, IonMenuToggle, IonRow, IonSegment, IonSegmentButton, IonText } from "@ionic/react";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Location, NavigateFunction, useLocation, useNavigate } from "react-router";
import { RootState } from "../../Redux/reducer";
import { IMG } from "../../styles/assets";
import { navigateToRoute, userShortName } from "../../utils/helper";
import { headerItemType, headerMenuItem, mobileHeaderMenuItem, mobileHeaderMenuType } from "../../utils/layoutHelper";

interface HeaderProps {
  setIsOpen?: (isOpen: boolean) => void;
  routeName?: string;
  handleBackClick?: () => void
}

const Header: React.FC<HeaderProps> = ({ setIsOpen, routeName, handleBackClick }) => {
  const { user, mQuery, currentUserType } = useSelector((state: RootState) => state.auth);
  const [subHeader, setSubHeader] = useState<mobileHeaderMenuType | undefined>(undefined);

  const navigate: NavigateFunction = useNavigate();
  const location: Location = useLocation();

  const handleMenuClick = (item: headerItemType) => navigateToRoute(navigate, item.route, true);

  useEffect(() => {
    const data = mobileHeaderMenuItem.find(i => i.id === routeName)
    setSubHeader(data)
  }, [routeName])


  const handleMenu = async () => {
    try {
      const menuId = "menu";
      const isOpen = await menuController.isOpen(menuId);
      if (isOpen) await menuController.close(menuId);
      else await menuController.open(menuId);
    } catch (error) {
      console.error("[Error]: Menu", error);
    }
  }

  return (
    <>
      {!mQuery ? (
        <>
          <IonHeader className={"flex sticky top-0 shadow-none p-3 pb-0 pt-7 bg-grad"}>
            <IonCol>
              <div className="flex items-center">
                <IonImg
                  src={IMG.appLogo}
                  alt="header-app-logo"
                  className="max-w-[200px] pointer"
                  onClick={() => navigateToRoute(navigate, "/home", true)}
                />
                <IonText className="text-white text-sm ml-4 max-w-[200px] font-normal">
                  Seu médico sem sair de casa
                </IonText>
              </div>
            </IonCol>
            {location.pathname !== "/support" && currentUserType && currentUserType !== "operator" && 
              (<IonMenuToggle autoHide={true} onClick={handleMenu}>
                <IonMenuButton className="flex">
                  <IonIcon src={IMG.menuIcon} size="medium" className="content-start w-7 h-7"></IonIcon>
                </IonMenuButton>
            </IonMenuToggle>)}
            {currentUserType === "operator" && (
              <IonLabel className="text-sm">
                <IonImg src={IMG.closeIcon} className="h-4" onClick={setIsOpen && (() => setIsOpen(true))} />
                Sair
              </IonLabel>
            )}
          </IonHeader>

          {routeName && subHeader && <IonLabel className="flex bg-[#eff8ff] justify-center py-2">
            <IonImg src={IMG.backIcon} className="h-5 w-5 absolute left-3" onClick={handleBackClick} />
            <IonText className="text-primary flex">{subHeader.icon}<span className="ps-2">{subHeader.name}</span></IonText>
          </IonLabel>}
        </>
      ) : (
        <IonHeader className={"sticky top-0 shadow-none py-4 lg:py-3 px-4 lg:px-0 font-sans bg-grad"}>
          <IonRow className="mx-auto max-w-[1320px]">
            <IonCol size="3">
              <div className="flex items-center">
                <IonImg
                  src={IMG.appLogo}
                  alt="header-app-logo"
                  className={`max-w-[200px] cursor-pointer ${location?.pathname === "/support" ? "pointer-events-none" : ""}`}
                  onClick={() => navigateToRoute(navigate, "/home", true)}
                />
                <IonText className="text-white text-sm ml-4 max-w-[134px] font-normal">
                  Seu médico sem sair de casa
                </IonText>
              </div>
            </IonCol>
            <IonCol size="9">
              <IonSegment className="justify-end">
                {!isEmpty(user) && (currentUserType === "patient" || currentUserType === "dependent") && location?.pathname !== "/support" && headerMenuItem.map((item: headerItemType) => (
                    <IonSegmentButton
                      type="button"
                      layout="icon-start"
                      key={item.route}
                      className={`text-white  text-xs xl:text-lg font-medium text-left normal-case tracking-normal max-w-max min-w-max hover:text-white ${item.route === `/${routeName}` ? `segment-button-checked ${item.route.slice(1)}` : ""}`}
                      onClick={() => handleMenuClick(item)}
                    >
                      {item.route === "/profile" ?
                        <IonAvatar className="w-12 h-12">
                          {user?.profilePic ? (
                            <IonImg src={user?.profilePic} alt="" />
                          ) : user?.name ? (
                            <div className="w-full h-full flex items-center justify-center border-2 border-white rounded-full">
                              {userShortName(user?.name)}
                            </div>
                          ) : (
                            <IonImg src={IMG.profileAvatarIcon} alt="" />
                          )}
                        </IonAvatar>
                      :
                        <>
                          {item.icon}
                          <span className="ms-2 text-white">{item.name}</span>
                        </>
                      }
                    </IonSegmentButton>
                  ))}
                {!isEmpty(user) && currentUserType !== "" && location?.pathname !== "/support" && 
                  <IonSegmentButton
                    layout="icon-start"
                    className="mt-0 max-w-max min-w-max text-base font-normal text-white hover:text-white capitalize ms-auto sair"
                    onClick={() => setIsOpen && setIsOpen(true)}
                  >
                    {currentUserType === "operator" ? 
                      <IonLabel className="text-sm">
                        <IonImg src={IMG.closeIcon} className="h-4" />
                        Sair
                      </IonLabel>
                    : <>Sair</>}
                  </IonSegmentButton>}
              </IonSegment>
            </IonCol>
          </IonRow>
        </IonHeader>
      )}
    </>
  );
};

export default Header;
