import { IonCol, IonImg } from "@ionic/react";
import Content from "./Content";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/reducer";

interface LeftGridProps {
  src?: string | undefined;
  title?: string;
  name?: string;
  backIcon?: true;
  ImgClassname?: string;
  leftclassName?: string;
  onBackClick?: () => void;
}

const LeftGrid: React.FC<LeftGridProps> = ({
  src,
  title,
  name,
  backIcon,
  leftclassName,
  ImgClassname,
  onBackClick,
}) => {
  const { mQuery } = useSelector((state: RootState) => state.auth);

  return (
    <>
      {mQuery && (
        <IonCol size="12" sizeMd="6" className={`left-grid-wrapper ${leftclassName}`}>
          <Content
            name={name}
            title={title}
            backIcon={backIcon}
            backClick={onBackClick}
          />
          {src ? (
            <div className="flex justify-center xl:mt-8">
              <IonImg src={src} alt="" className={`max-w-xs xl:max-w-sm mt-7 md:mt-5 ${ImgClassname}`} />
            </div>
          ) : null}
        </IonCol>
      )
      }
    </>
  );
};

export default LeftGrid;
