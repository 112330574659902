import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import { RootState } from "../Redux/reducer";

export const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user, currentUserType } = useSelector((state: RootState) => state.auth);
  if(currentUserType === "operator") return <Navigate to="/patient/login" replace />;
  else if(window.location.search === "" && user?.name === "") return <Navigate to="/" replace />;
  return <>{children}</>;
};
export default ProtectedRoute;
