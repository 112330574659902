import * as FooterMenu from "../svg/footer-svg";
import * as HeaderMenu from "../svg/header-svg";
import * as Menu from "../svg/helper-svg";
import ANSLogo from "./app/ans-logo.svg";
import appLogo from "./app/app-logo.svg";
import backIcon from "./app/back-icon.svg";
import chatIcon from "./app/chat-icon.svg";
import examImg from "./images/exam-img.svg";
import historyImg from "./images/history-img.svg";
import medicineImg from "./images/medicine-img.svg";
import profileAvatarIcon from "./images/profile-icon.svg";
import searchIcon from "./images/search-icon.svg";
import homeScreenImg from "./images/home-screen-img.png";
import successCheckedIcon from "./images/sucess-check-icon.svg";
import editIcon from "./app/edit-icon.svg";
import menuIcon from "./app/menu-icon.svg";
import adicionarButton from "./images/adicionar-btn.svg";
import defaultProfile from "./images/defaultProfile.svg";
import loginFactorImg from "./images/login-factor.svg";
import rightArrow from "./images/right-arrow.svg";
import userInfoImg from "./images/user-info.svg";
import passwordImg from "./images/password-img.svg";
import successImg from "./images/success-img.svg";
import * as Dependent from "../svg/dependent-svg";
import * as Scheduling from "../svg/scheduling-svg";
import downArrowIcon from "./images/down-arrow.svg";
import closeIcon from "./app/close-icon.svg";
import minusIcon from "../assets/images/remove-outline.svg";
import messageSendIcon from "../assets/images/send-outline.svg";
import galleryIcon from "../assets/images/gallery-outline.svg";
export const IMG = {
  backIcon,
  appLogo,
  historyImg,
  passwordImg,
  successImg,
  examImg,
  medicineImg,
  profileAvatarIcon,
  clockIcon: Menu.clockIcon(),
  clockDisabledIcon: Menu.clockIcon("#828282"),
  calendarGrayIcon: Scheduling.calendarGrayIcon(),
  calendarIcon: Scheduling.calendarIcon(),
  // calendarDisabledIcon: Scheduling.calendarIcon("#828282"),
  calendarWhiteIcon: Scheduling.calendarIcon("white"),
  searchIcon,
  paWhiteIcon: Menu.paIcon(),
  homeScreenImg,
  successCheckedIcon,
  editIcon,
  menuIcon,
  adicionarButton,
  defaultProfile,
  userInfoImg,
  PlusIcons: Dependent.PlusIcon("#2E8AD5"),
  IconMinus: Dependent.MinuseIcon("#2E8AD5"),
  videoIcon: Menu.videoIcon(),
  hidden: Menu.hiddenField(),
  visible: Menu.visibleField(),
  checkedIcon: Menu.checkIcon("#00D37A"),
  checkedDisabledIcon: Menu.checkIcon("#C7C8CC"),
  loginFactorImg,
  rightArrow,
  copyIcon: Menu.copySvgIcon(),
  copyWhiteIcon: Menu.copySvgIcon("#2E8AD5", "white"),
  downArrowIcon,
  closeIcon,
  minusIcon,
  messageSendIcon,
  galleryIcon
};

export const FooterIcons = {
  faceBook: FooterMenu.faceBook,
  instagram: FooterMenu.instagram,
  linkedin: FooterMenu.linkedin,
  youtube: FooterMenu.youtube,
  chatIcon,
  ANSLogo,
};

export const HeaderMenuIcons = {
  home: HeaderMenu.homeMenu(),
  history: HeaderMenu.historyMenu(),
  exams: HeaderMenu.examMenu(),
  medicine: HeaderMenu.medicineMenu(),
  profile: HeaderMenu.profileMenu(),
};

export const SideMenuIcons = {
  home: HeaderMenu.homeMenu(),
  history: HeaderMenu.historyMenu(),
  exams: HeaderMenu.examMenu(),
  medicine: HeaderMenu.medicineMenu(),
  profile: HeaderMenu.profileMenu(),
  logout: HeaderMenu.logoutMenu(),
};

export const MobileHeaderMenuIcons = {
  history: HeaderMenu.historyMenu(),
  exams: HeaderMenu.examMenu(),
  medicine: HeaderMenu.medicineMenu(),
  profile: HeaderMenu.profileMenu(),
  scheduling: HeaderMenu.schedulingMenu(),
};

export const MobileFooterMenuIcons = {
  history: FooterMenu.historyFooter(),
  exams: FooterMenu.examFooter(),
  medicine: FooterMenu.medicineFooter(),
  profile: FooterMenu.profileFooter(),
};