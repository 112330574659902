import { LOGIN_PATIENT, MQUERY, OPERATOR_LOGIN, PATIENTLINK, RESET, RESET_PATIENT, UPDATE_USER_PROFILE, USER } from "../action";
import { updateObject } from "../updateObject";
import { AuthActionTypes, AuthState } from "./types";

const initialState: AuthState = {
  user: {
    id: "",
    name: "",
    user_id: "",
    cpf: "",
    email: "",
    corporationId: "",
    gender: "",
    telePhone: {
      id: "",
      country_code: "",
      state_code: "",
      number: "",
      primary: true,
      areaCode: "",
    },
    isDependentUser: false,
    profilePic: ""
  },
  currentUserType: "",
  isPatientLink: false,
  mQuery: false,
  updateUser: {
    id: "",
    name: "",
    user_id: "",
    cpf: "",
    email: "",
    corporationId: "",
    gender: "",
    telePhone: {
      id: "",
      country_code: "",
      state_code: "",
      number: "",
      primary: true,
      areaCode: "",
    },
    isDependentUser: false,
    profilePic: ""
  },
};

const authReducer = (
  state = initialState,
  action: AuthActionTypes
): AuthState => {
  switch (action.type) {
    case USER:
      return updateObject(state, { user: action.payload });
    case MQUERY:
      return updateObject(state, { mQuery: action.payload });
    case UPDATE_USER_PROFILE:
      return updateObject(state, { currentUserType: action.payload.currentUserType, user: action.payload.user, updateUser: action.payload.updateUser });
    case OPERATOR_LOGIN:
      return updateObject(state, { currentUserType: "operator", user: action.payload.user });
    case LOGIN_PATIENT:
      return updateObject(state, { currentUserType: "patient", user: action.payload.user, isPatientLink: action.payload.isPatientLink });
    case RESET_PATIENT:
      return updateObject(state, { currentUserType: "operator", user: action.payload.user, updateUser: { ...initialState.updateUser } });
    case PATIENTLINK:
      return updateObject(state, { isPatientLink: action.payload.isPatientLink });
    case RESET:
      return updateObject(state, { currentUserType: "", user: { ...initialState.user }, updateUser: { ...initialState.updateUser } });
    default:
      return state;
  }
};

export default authReducer;
