import { formFieldType } from "../../../components/UI/Formik/types";


export const operatorSignupInputFields: formFieldType[] = [
    {
        id: "email",
        placeholder: "E-mail",
        name: "email",
        type: "text",
        label: "Digite seu e-mail",
        inputType: "input",
        isLabelAfter: true
    },
    {
        id: "cpf",
        placeholder: "CPF",
        name: "cpf",
        type: "tel",
        label: "Digite seu CPF",
        disabled: true,
        inputType: "input",
        mask:[
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ],
        isLabelAfter: true
    }
]

