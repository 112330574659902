import { IonCol } from "@ionic/react";
import React from "react";

interface RightGridProps {
  children?: string | JSX.Element | JSX.Element[] | React.ReactNode;
  colSize?: string;
  className?: string;
}

const RightGrid: React.FC<RightGridProps> = ({ children, colSize = "6", className = "" }) => {
  return <IonCol sizeMd={colSize} size="12" className={className}>{children}</IonCol>;
};

export default RightGrid;
