import { IonCol, IonImg, IonLabel, IonModal, IonRow, IonText, useIonToast } from "@ionic/react";
import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LOGIN_PATIENT } from "../../Redux/action";
import { RootState } from "../../Redux/reducer";
import Grid from "../../components/Grid";
import Layout from "../../components/Layout";
import { Button } from "../../components/UI/Button";
import CardBox from "../../components/UI/CardBox";
import authService from "../../services/auth-service";
import schedulingService from "../../services/scheduling-service";
import storeService from "../../services/store.service";
import { SchedulingItemType } from "../scheduling/types";
import { IMG } from "../../styles/assets";
import { isEmpty } from "lodash";
import { FutureAppointmetmentsList } from "./FutureAppointmetmentsList";
import { CustomList } from "../../components/UI/List";
import { corporation, isDesktop, navigateToRoute, useDidMountHook } from "../../utils/helper";
import platform from "../../utils/platform";
import { PresupportError, checkconnection } from "../../components/PresupportError";
import { useNavigate } from "react-router";
import ironFit, { liveSupport } from "@irontrainers/palibrary/dist/clients/iron-fit-patient";
import { usePatientAuth } from "@irontrainers/palibrary/dist/lib/auth/usePatientAuth";
import SelectSearch from "../../components/UI/Input/SelectSearch";
import copy from "copy-to-clipboard";

const Home = () => {
  const { user, mQuery, updateUser } = useSelector((state: RootState) => state.auth);

  const [loading, setLoading] = useState<boolean>(false);
  const [tokenLoading, setTokenLoading] = useState<boolean>(false);
  const [schedulingLoading, setSchedulingLoading] = useState<boolean>(false);
  const [futureData, setFutureData] = useState<SchedulingItemType[]>([]);
  const [specialities, setSpecialities] = useState<string[]>([]);
  const [isPresupportError, setIsPresupportError] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { startOnline, clearPatient, supportStartForecast } = liveSupport.useLivePatientSync();
  const [patientId, patient, , , signInWithCpf] = usePatientAuth(user, corporation);

  const query = new URLSearchParams(location.search);

  const dispatch = useDispatch();
  const [present] = useIonToast();
  const showTerm = true;
  const navigate = useNavigate()

  const forecastMinutes = (time: number | undefined) => Math.round((time || 0) / 60 / 1000);

  useDidMountHook(() => {
    const searchQuery = query.get("user");
    if (searchQuery) {
      setLoading(true);
      setTokenLoading(true);
      const data = atob(searchQuery);
      const queryUserData = JSON.parse(data);
      if (queryUserData.isPatientlink) {
        authService
          .patientLogin(queryUserData)
          .then(async (response: AxiosResponse) => {
            const { data: { result: { user } } } = response;
            setLoading(false);
            const users = { ...user, patientLink: searchQuery ? searchQuery : "" };
            await storeService.set("user", users);
            await storeService.set("isPatientLink", queryUserData.isPatientlink ? true : false);
            await storeService.set("patient_token", user.token);
            await storeService.set("currentUserType", "patient");
            dispatch({ type: LOGIN_PATIENT, payload: { user: {...users, profilePic: user?.avatar?.url || user?.avatar, isPatientLink: queryUserData.isPatientlink ? true : false } } });
            signInWithCpf();
            setTokenLoading(false);
            if (showTerm) navigateToRoute(navigate, "/support", false)
            else await gotoawating();
          })
          .catch((error: AxiosError) => {
            console.error("[Error]: get patient login", error);
            setLoading(false);
            setTokenLoading(false);
            present({ message: error.message || "Algo deu errado!", duration: 2000 });
          });
      }
    } else {
      signInWithCpf();
      getSpecialities();
      getFutureAppointments();
    }
  });

  useEffect(() => {
    if (!patientId) return;
    if (!patient) return;
    clearPatient();
    if (Object.keys(patient).length === 0) return;
    startOnline(patientId, patient)
  }, [patientId, patient]);

  const getSpecialities = () => {
    setSchedulingLoading(true);
    schedulingService
      .getSpecialities(user?.corporationId)
      .then(async (resp: AxiosResponse) => {
        const { data: { data, message } } = resp;
        if (message === "success") {
          const specialitiesRes: string[] = data.reduce((p: string[], n: { name: string }) => [...p, n.name], []);
          setSpecialities(specialitiesRes);
        }
        setSchedulingLoading(false);
      })
      .catch((error: AxiosError) => {
        setSchedulingLoading(false);
        console.error("[Error]: get specialities", error);
      });
  };

  const getFutureAppointments = () => {
    schedulingService
      .getFutureAppointmentList(user?.user_id)
      .then((res: AxiosResponse) => {
        setFutureData(res?.data?.data);
      })
      .catch((error: AxiosError) => {
        console.error("[Error]: get future appointment list", error);
      });
  };

  const handleStartClick = async () => {
    if (isDesktop || platform.isMobileweb) {
      try {
        if(await checkconnection(setIsPresupportError)) setIsOpen(true);
      } catch (err) {
        setIsPresupportError(true);
      }
    }
  };

  const gotoawating = useCallback(async () => {
    try {
      await ironFit.liveSupport().startPatientAwaitingSupport(user?.id, "video");
      navigateToRoute(navigate, "/support", false)
    } catch (error) {
      clearPatient();
      startOnline(patientId, patient);
    }
  }, [user]);

  const paCard = (
    <CardBox
      className="text-center w-full !max-w-[95%] !md:max-w-sm m-[0 auto] !p-4 !md:p-6"
      title={"Pronto Atendimento Digital 24h"}
      cardHeaderClass="text-ternary text-xl font-semibold pt-0"
      btnView={
        <Button
          text="Seja atendido agora"
          imgSrc={IMG.paWhiteIcon}
          imgSlot="start"
          className="light-theme"
          handleClick={handleStartClick}
          color="secondary"
          id="pa-call-btn"
        />
      }
      cardFooter= {"Médico clínico e pediatra de plantão. Tempo estimado de espera"}
      cardFooterSubText={` ${forecastMinutes(supportStartForecast)} minutos.`}
    />
  );

  const specialityCard = (
    <CardBox
      id="speciality-card"
      className="text-center w-full !max-w-[95%] [&>ion-button]:z-20 [&>ion-button]:relative !md:max-w-sm m-[0 auto] custom-contain overflow-visible !p-4 !md:p-6"
      title={"Escolha um Especialista"}
      cardHeaderClass="text-xl text-secondary font-semibold pt-0"
      btnView={<SelectSearch label="Agendar vídeo consulta" specialities={specialities} loading={schedulingLoading} />}
      cardFooter={ "Agende uma consulta com o especialista médico que você desejar"}
    />
  );

  return (
    <Layout loading={loading || tokenLoading} routeName="home">
      <Grid className={"[&>ion-row]:w-full home-wrapper border-gray-500 border-colo bg-grad overflow-x-hidden bg-cover pb-0 items-start	"}>
        {!tokenLoading && <IonRow className="md:px-0 mx-auto w-full max-w-[1320px]">
          {(isEmpty(futureData) && mQuery) ? (
            <IonCol size="12" sizeMd="4" className="min-h-[77.4vh] items-end flex p-0 static">
              <IonImg src={IMG.homeScreenImg} className="absolute bottom-0 max-w-[30%]"/>
            </IonCol>
          ) : null}
          
          <IonCol size="12" sizeMd="8" className="!max-w-[790px] ml-auto">
            {user?.name && <IonLabel className="relative text-white text-center w-full block order-2 md:order-none text-lg font-bold mb-3 after:content-[''] after:w-28 after:h-[2px] after:bg-white after:absolute after:left-1/2 after:-bottom-[10px] after:transform after:-translate-x-[50%]">
              Olá, {user.name}
            </IonLabel>}

            <IonRow>
              <IonCol size="12" sizeMd="6" className="order-3 md:order-none">{paCard}</IonCol>
              <IonCol size="12" sizeMd="6" className="relative z-10 contain-none order-4 md:order-none">{specialityCard}</IonCol>
            </IonRow>
          </IonCol>
          {!isEmpty(futureData) ? (
            <IonCol size="12" sizeMd="4" className="lg:static lg:before:bg-ternary lg:before:h-full lg:before:w-[50vw] xl:before:w-[35vw] lg:before:right-0 lg:before:top-0 lg:before:absolute lg:before:flex order-1 md:order-none home-background">
              <CustomList className="bg-transparent">
                <IonText className="text-lg text-white mb-5 justify-center hidden md:flex">
                  <span className="w-8 mr-3">{IMG.calendarWhiteIcon}</span>
                  Próximas consultas
                </IonText>
                {futureData.map((fd: SchedulingItemType) => (
                  <FutureAppointmetmentsList futureData={fd} key={fd.id} />
                ))}
              </CustomList>
            </IonCol>
          ) : null}
        </IonRow>}
      </Grid>

      <PresupportError setIsPresupportError={setIsPresupportError} isPresupportError={isPresupportError} />

      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
        className="camera-modal"
      >
        <CardBox
          className="p-3!"
          title="Pronto Atendimento Digital 24h"
          cardHeaderClass="text-center text-xl font-bold text-primary"
          btnView={
            <Button
              color="secondary"
              text="Copiar link da vídeo consulta"
              handleClick={async () => {
                const data = { corporation, cpf: user?.cpf ? user.cpf : updateUser?.cpf, isPatientlink: true, userId: user?.user_id};
                const encoded = await btoa(JSON.stringify(data));
                copy(`${window.location.href}?user=${encoded}`);
                setIsOpen(false);
              }}
              imgSrc={IMG.copyWhiteIcon}
              imgClass="ml-2"
              id="copy-pa-link-btn"
            />}
          />
      </IonModal>
    </Layout>
  );
};

export default Home;
