import { IonCol, IonLabel, IonRow } from "@ionic/react";
import Grid from "../../../components/Grid";
import LeftGrid from "../../../components/Grid/LeftGrid";
import RightGrid from "../../../components/Grid/RightGrid";
import Layout from "../../../components/Layout";
import CustomFormik from "../../../components/UI/Formik";
import { IMG } from "../../../styles/assets";
import { useState } from "react";
import { patientSignupInputFields as formInputFields, validationSchema, patientSignupInputSelfRegisterFields } from "./formFields";
import { navigateToRoute, useDidMountHook } from "../../../utils/helper";
import storeService from "../../../services/store.service";
import moment from "moment";
import { useNavigate } from "react-router";

export const PatientSignup = () => {
  const [signupPatient, setSignupPatient] = useState<any>();
  const navigate = useNavigate();

  useDidMountHook(async () => {
    const patient_signup = await storeService.get("patient_signup");
    setSignupPatient(patient_signup);
  });

  const submitSignup = async (values: { name?: string; birthdate?: string | Date; email?: string; celular?: string }, setSubmitting: (submitting: boolean) => void) => {
    try {
      setSubmitting(true);
      await storeService.set("patient_signup", { ...signupPatient, name: values.name, email: values.email, phone: values.celular, countryCode: "+55", birthdate: values.birthdate });
      setSubmitting(false);
      navigateToRoute(navigate, "/patient/register", true);

    } catch (err) {
      console.error("[Error]: patient signup", err);
      setSubmitting(false);
    }
  };

  const initialValues = {
    name: signupPatient?.name || "",
    birthdate: moment(new Date(signupPatient?.birthdate)).utc().format("DD/MM/YYYY"),
    celular: "",
    email: signupPatient?.email || "",
  };

  const handleBackClick = () => {
    storeService.removeKey("patient_signup");
    navigateToRoute(navigate, "/patient/login", true);
  }

  return (
    <Layout routeName="patientsignup">
      <Grid className="patient-signup [&>ion-row]:w-full !items-start !py-6 !px-0 [&>ion-row>ion-img]:ml-3 [&>ion-row>ion-img]:mt-0 md:[&>ion-row>ion-img]:hidden" handleBackClick={handleBackClick} backIcon={true}>
        <IonRow className="md:px-0 mx-auto w-full max-w-[1320px]">
          <LeftGrid
            backIcon={true}
            onBackClick={handleBackClick}
            src={IMG.userInfoImg} />
          <RightGrid className="p-4 lg:p-0 mx-auto md:mt-10">
            <IonLabel className="font-bold text-2xl text-secondary block max-w-xs">
              Por favor, complete seu cadastro
            </IonLabel>
            <IonLabel className="max-w-xs text-[#4f4f4f] font-normal text-xl mb-4 block">
              Preencha os dados de cadastro
            </IonLabel>
            <IonRow>
              <IonCol size="12" className="lg:[&>form>.input-wrapper]:w-1/2 [&>form>.input-wrapper:first-child>ion-icon]:!top-10  [&>form>.input-wrapper>ion-input]:!pr-4     lg:[&>form>ion-button]:float-right lg:[&>form>ion-button]:w-1/2 lg:[&>form>ion-button]:pl-10 lg:[&>form>ion-button]:-mt-24">
                <CustomFormik
                  handleSubmit={submitSignup}
                  formInputFields={signupPatient?.name ? formInputFields : patientSignupInputSelfRegisterFields}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  btnName="Continuar"
                />
              </IonCol>
            </IonRow>
          </RightGrid>
        </IonRow>
      </Grid>
    </Layout>
  );
};
