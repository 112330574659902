import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { BASE_URL as baseURL } from "../config/api.config";
import storeService from "../services/store.service";
import { corporation } from "./helper";

abstract class HttpClient {
    protected readonly http: AxiosInstance;

    public constructor() {
        this.http = axios.create({ baseURL });
        this._initializeRequestInterceptor();
        this._initializeResponseInterceptor();
    }

    private requestmodifier = async (config: any) => {
        if (config?.data) config.data["corporation"] = corporation;
        else if (config.url && !config.url.includes("slug=")) {
            config["url"] += config.url.includes("?") ? `&slug=${corporation}` : `?slug=${corporation}`;
        }
        const currentUserType = await storeService.get("currentUserType");
        if(currentUserType !=="") {
            config.headers["Authorization"] =currentUserType === "patient" ? await storeService.get("patient_token") : await storeService.get("operator_token")
        }
        return config;
    };

    private _initializeRequestInterceptor = () => {
        this.http.interceptors.request.use(async (config) =>
            this.requestmodifier(config)
        );
        axios.interceptors.request.use(async (config: AxiosRequestConfig) =>
            this.requestmodifier(config)
        );
    };

    private _initializeResponseInterceptor = () => {
        this.http.interceptors.response.use(
            this._handleResponse,
            this._handleError
        );
    };

    private _handleResponse = (data: AxiosResponse) => data;

    protected _handleError = (error: AxiosError) => {
        if (error.response) {
            if (error.response.status === 401 && error.message !== "Patient with already running support" && !error.response?.request?.responseURL.includes("/auth") && !error.response?.request.responseURL.includes("verify/token")) {
                storeService.logout();
            }
        }
        return Promise.reject(error);
    };
}

export default HttpClient;
