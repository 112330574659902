import { formFieldType } from "../../components/UI/Formik/types";
import { biologicalSexOptions, genderOptions } from "../patient/signup/formFields";
import { Phone } from "../profile/type";

interface FormValues {
    name: string;
    birthdate: string;
    cpf: string;
    email?: string;
    password?: string;
  }
  
  export const initialValues: FormValues = {
    name: "",
    birthdate: "",
    cpf: "",
    email: "",
    password: "", 
  };

  export interface payloadInterface {
    parent_id: string;
    corporation: string;
    birthdate: string;
    name: string;
    email?: string;
    phone?: string | Phone[];
    countryCode?: string;
    userName?: string;
    gender?: string;
    sex?: string;
    cpf?: string;
  }


export const registerMajorDependentField: formFieldType[] = [
    {
        mask: [
            /\d/,
            /\d/,
            /\d/,
            ".",
            /\d/,
            /\d/,
            /\d/,
            ".",
            /\d/,
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
        ],
        id: "cpf",
        placeholder: "000.000.000-00",
        name: "cpf",
        type: "tel",
        label: "Digite o cpf do dependente",
        inputType: "input",
        isLabelAfter: true,
        labelClassName: "text-ternary",
    },
    {
        id: "name",
        placeholder: "Nome do dependente",
        name: "name",
        type: "text",
        label: "Digite o nome do dependente",
        disabled: true,
        inputType: "input",
        isLabelAfter: true,
        labelClassName: "text-ternary",
    },
    {
        id: "birthdate",
        placeholder: "dd/mm/yyyy",
        name: "birthdate",
        type: "tel",
        label: "Digite a data de nascimento",
        disabled: true,
        inputType: "input",
        isLabelAfter: true,
        labelClassName: "text-ternary",
        mask: [
            /\d/,
            /\d/,
            "/",
            /\d/,
            /\d/,
            "/",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ],
    }
]

export const registerMinorDependentField1 : formFieldType[] = [
    {
        id: "name",
        placeholder: "Nome do dependente",
        name: "name",
        type: "text",
        label: "Digite o nome do dependente",
        disabled: true,
        inputType: "input",
        isLabelAfter: true,
        labelClassName: "text-ternary",
    },
    {
        id: "celular",
        name: "celular",
        type: "text",
        label: "Digite o número do seu celular com DDD",
        inputType: "phone-input",
        isLabelAfter: true,
        placeholder: "",
        labelClassName: "text-ternary mb-2 inline-block"
    },
    {
        id: "birthdate",
        placeholder: "dd/mm/yyyy",
        name: "birthdate",
        type: "tel",
        label: "Digite a data de nascimento",
        disabled: true,
        inputType: "input",
        isLabelAfter: true,
        labelClassName: "text-ternary",
        mask: [
            /\d/,
            /\d/,
            "/",
            /\d/,
            /\d/,
            "/",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ],
    },
    {
        id: "email",
        placeholder: "E-mail",
        name: "email",
        type: "text",
        label: "Digite o seu e-mail",
        inputType: "input",
        isLabelAfter: true,
        labelClassName: "text-ternary"
    }
]

export const registerMinorDependentField2: formFieldType[] = [
    {
        id: "name",
        name: "name",
        type: "text",
        placeholder: "Como gosta de ser chamado",
        inputType: "input",
        label: "Como gosta de ser chamado?",
        isLabelAfter: true,
        labelClassName: "text-ternary",
    },
    {
        id: "biologicalSex",
        name: "biologicalSex",
        type: "select",
        placeholder: "Selecione o sexo biológico",
        inputType: "select",
        label: "Sexo biológico",
        isLabelAfter: true,
        options: biologicalSexOptions,
    },
    {
        id: "gender",
        name: "gender",
        type: "select",
        placeholder: "Selecione o gênero",
        inputType: "select",
        label: "Gênero",
        isLabelAfter: true,
        options: genderOptions
    }
]
