import React, { useState } from "react";
import { Form, Formik } from "formik";
import Input from "../Input";
import { Button } from "../Button";
import { formFieldType, formikInitialValue } from "./types";
import { SelectInput } from "../Input/SelectInput";
import { CustomPhoneInput } from "../Input/PhoneInput";
import { IonText, useIonToast } from "@ionic/react";
import { isEmpty } from "lodash";
import Modal from "../Modal";
import authService from "../../../services/auth-service";
import storeService from "../../../services/store.service";
import { navigateToRoute } from "../../../utils/helper";
import { useNavigate } from "react-router";
import { AxiosResponse } from "axios";

interface FormikProps {
  formInputFields: formFieldType[];
  handleSubmit: (
    values: formikInitialValue,
    setSubmitting: (submitting: boolean) => void
  ) => void;
  initialValues: formikInitialValue;
  validationSchema: object;
  btnName: string;
  isLabel?: boolean;
  btnIcon?: string;
}

const CustomFormik: React.FC<FormikProps> = ({ formInputFields, handleSubmit, initialValues, validationSchema, btnName, isLabel = false, btnIcon = ""}) => {
  const [toggle, setToggle] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string | unknown>("");
  const [present] = useIonToast();
  const navigate = useNavigate();

  const forgotPasswordHandler = (val: string | unknown, errors: string | undefined, setFieldError: (fiedlName: string, errorMessage: string) => void) => {
    if (isEmpty(val)) setFieldError("email", "Email inválido, tente novamente")
    if (!isEmpty(val) && isEmpty(errors)) {
      setIsOpen(true);
      setValue(val);
    }
  }

  const navigateToLoginTwoFactor = async () => {
    setIsOpen(false);
    authService.operatorByEmail(value)
      .then(async (response: AxiosResponse) => {
        const { data: { data: { cpf, id, phone } } } = response;
        if (!cpf || !id) {
          present({ message: "O usuário não está disponível no banco de dados. Por favor, entre em contato com o administrador.", duration: 2000 });
        } else {
          await storeService.set("operator_signup", { cpf: cpf.replace(/\D+/g, ""), userType: "operator", id, phone });
          navigateToRoute(navigate, "/two-factor-auth", true)
        }
      });
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        // validateOnMount={true}
        onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
      >
        {({ isValid, isSubmitting, values, errors, handleChange, handleSubmit, setFieldValue, setFieldError }) => (
          <Form className="w-full">
            {formInputFields.map((field: formFieldType) => {
              return (
                <React.Fragment key={field.id}>
                  {field.inputType === "select" ? (
                    <SelectInput
                      field={field}
                      value={values[field.id]}
                      setValue={setFieldValue}
                    />
                  ) : (field.inputType === "phone-input" ?
                    (
                      <CustomPhoneInput
                        value={values[field.id]}
                        setValue={setFieldValue}
                        field={field}
                        errors={errors.celular}
                        errorText={errors.celular}
                      />
                    ) : (
                      <Input
                        key={field.name}
                        mask={field.mask}
                        type={toggle ? "text" : field.type}
                        placeholder={field.placeholder}
                        name={field.name}
                        onChange={handleChange}
                        value={values[field.id]}
                        label={field.label}
                        labelClassName={field.labelClassName}
                        isLabelAfter={field.isLabelAfter}
                        max={field.max}
                        pattern={field.pattern}
                        errors={errors[field.id]}
                        errorText={errors[field.id]}
                        errorclass="text-red-500 text-xs font-normal"
                        icon={field.type === "password" && toggle ? field.hidden : field.visible}
                        readonly={field.inputType === "readonly"}
                        iconClick={() => setToggle(!toggle)}
                        editIcon={true}
                      />
                    ))}
                </React.Fragment>
              );
            })}
            <Button
              className="mt-5"
              fill="solid"
              text={btnName}
              id="submit-btn"
              handleClick={handleSubmit}
              disabled={!isValid}
              imgSrc={btnIcon}
              imgSlot="start"
              imgClass="mr-2"
            />

            {isLabel && <IonText className="color-primary lg:w-1/2 block text-center md:ml-auto lg:pl-10 cursor-pointer text-primary mt-5 lg:mt-0" onClick={() => forgotPasswordHandler(values.email, errors["email"], setFieldError)}>Esqueci minha senha</IonText>}
          </Form>
        )}

      </Formik>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        text="Esqueci minha senha"
        subTexts="Enviaremos um código de validação para que você possa escolher sua nova senha."
        btnSimText="Ok"
        btnCancelText="Cancelar"
        btnCancelClick={() => {
          setIsOpen(false)
          setValue("");
        }}
        handleClick={() => {
          setIsOpen(false);
          navigateToLoginTwoFactor();
        }}
        btnClass="flex flex-row-reverse mt-8"
        btnCancelColor="secondary"
        btnCancelFill="outline"
        className="[&>div>div>ion-button]:mx-1 [&>div>div>ion-button]:my-0"
      />
    </>
  );
};

export default CustomFormik;
