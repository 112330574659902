import copy from "copy-to-clipboard";
import { User } from "../../Redux/auth/types";
import { APS_URL } from "../../config/api.config";
import { corporation, navigateToRoute } from "../../utils/helper";
import { NavigateFunction } from "react-router";
import { RESET_SCHEDULING } from "../../Redux/action";

export const getAccessToken = async (user: User, appointment: { id: string }, setOnCopySuccess: (copySuceess: boolean) => void) => {
    const userToken = btoa(`4011045|${user?.cpf}|1111|${user?.name}|${user?.email}||1111|1111|||1111|1111|APS`
    );
    copy(
        `${APS_URL}/patient-appointments?name=${userToken}&&appointment_id=${appointment?.id}&corporation=${corporation}&state=pt&redirect_url=https://www.avus.com.br/`
    );
    setOnCopySuccess(true);
    setTimeout(() => {
        setOnCopySuccess(false);
    }, 3000);
};

export const handleSchedulingBack = (navigate: NavigateFunction, dispatch: any) => {
    dispatch({ type: RESET_SCHEDULING, payload: {} });
    navigateToRoute(navigate, "/home", true)
}
