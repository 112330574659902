import moment from "moment";
import API_CONFIG from "../config/api.config";
import HttpClient from "../utils/HttpClient";
import { format } from "../utils/helper";
import { AppointmentData } from "../pages/scheduling/types";

interface getStaffBySpeciality {
  speciality?: string;
  corporation: string;
}

class SchedulingService extends HttpClient {
  public async getFutureAppointmentList(user_id: string) {
    const timezone = moment.tz.guess();
    let currentDateAPI: Date | string = new Date();
    currentDateAPI.setHours(23, 59, 59, 999);
    currentDateAPI = currentDateAPI.toUTCString();
    const data: { user_id: string; currentDateAPI: string; timezone: string } =
      { user_id, currentDateAPI, timezone };
    const URL = format(API_CONFIG.getFutureAppointmentList, [
      data?.user_id,
      data?.currentDateAPI,
      data?.timezone,
    ]);
    return this.http.get(URL);
  }

  public async getSpecialities(corporationId?: string) {
    const URL = format(API_CONFIG.getSpecialities, [corporationId as string]);
    return this.http.get(URL);
  }

  public async getStaffBySpeciality(data: getStaffBySpeciality) {
    const URL = API_CONFIG.getStaffBySpeciality;
    return this.http.post(URL, data);
  }

  public async getSlotByStaff(userId: string, data: {
    timezone: string,
    agenda_date: string,
    agenda_start_time: string,
    agenda_end_time: string,
    speciality?: string,
    staff_id: string,
  }) {
    return this.http.get(format(API_CONFIG.getSlotByStaff, [userId]), {
      params: data,
    });
  }

  public getAvailableDateWithProffessional(data: string) {
    return this.http.get(API_CONFIG.getAvailableDateWithProffessional + data);
  }

  // booking appointment
  public bookAppointment(userId: string, data: AppointmentData) {
    return this.http.post(format(API_CONFIG.bookAppointment, [userId]), data)
  }
}

export default new SchedulingService();
