import { IonSelect, IonSelectOption } from "@ionic/react";
import { formFieldType } from "../Formik/types";

export const SelectInput = ({ field, setValue, value }: { field: formFieldType; value: unknown; setValue: (field: string, val: string) => void }) => {
  return (
    <IonSelect
      className={"flex h-12 w-[100%] bg-[#f4f5f7] items-center justify-center self-stretch rounded-xl border-none outline-none px-3 font-bold mb-5"}
      name={field.name}
      placeholder={field.placeholder}
      value={value}
      onIonChange={(e: { detail: { value: string } }) => setValue(field.id, e.detail.value)}
      interface={"popover"}
      interfaceOptions="popover"
    >
      {field.options?.map((option: { value: string; label: string }) => (
        <IonSelectOption value={option.value} key={option.value}>
          {option.label}
        </IonSelectOption>
      ))}
    </IonSelect>
  );
};
