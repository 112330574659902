import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import { RootState } from "../Redux/reducer";

export const AuthRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, currentUserType } = useSelector((state: RootState) => state.auth);

  if ((currentUserType !== "" && currentUserType !== "operator") && user?.name !== "") {
    return <Navigate to="/home" replace />;
  }
  return <>{children}</>;
};

export default AuthRoute;
