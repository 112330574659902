import { IonCol, IonModal, IonRow, IonText, useIonToast } from "@ionic/react";
import React, { useRef } from "react";
import { takePicture } from "../../helper/platform";
import * as IMG from "../../styles/svg/pic-upload-svg";

interface PicUploadProps {
  setIsShowModal: (isOpen: boolean) => void;
  isShowModal?: boolean;
  uploadPicture: (data: any) => void;
  getProfileData ?: boolean;
}

const PicUpload: React.FC<PicUploadProps> = ({ setIsShowModal, isShowModal, uploadPicture, getProfileData = false }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [present] = useIonToast();
  const presentToast = (message: string, duration: number) =>
    present({ message, duration });

  const handleInputRefClick = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      inputRef.current.click();
    }
  };

  const handleInputRef = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      if (file?.size > 26214400) {
        presentToast("o limite de tamanho do arquivo é de 25 MB", 5000);
      } else {
        if (
          file?.type === "application/pdf" ||
          file?.type === "image/jpeg" ||
          file?.type === "image/png" ||
          file?.type === "image/jpg"
        ) {
          if (file?.type === "application/pdf" && getProfileData) {
            presentToast("Formato de arquivo inválido", 5000);
          } else {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = async () => {
              if (!getProfileData) {
                uploadPicture(reader.result);
                return;
              }
              UploadFile(reader.result);
            };
          }
        } else {
          presentToast("Formato de arquivo inválido", 5000);
        }
      }
    }
  };

  const UploadFile = async (file?: string | ArrayBuffer | null | undefined) => {
    const compressFile = await reduce_image_file_size(file);
    if (
      compressFile &&
      !compressFile.toString().includes("image") &&
      !compressFile.toString().includes("pdf")
    ) {
      presentToast("Formato de arquivo inválido", 5000);
    } else {
      uploadPicture(compressFile);
    }
  };

  const reduce_image_file_size = async (
    base64Str: any,
    MAX_WIDTH = 1000,
    MAX_HEIGHT = 1000
  ) => {
    let finalstr: string;
    if (base64Str && !base64Str.startsWith("data:image")) {
      finalstr = "data:image/jpeg;base64," + base64Str;
    } else {
      finalstr = base64Str;
    }
    const resized_base64 = await new Promise((resolve) => {
      const img = new Image();
      img.src = finalstr;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx?.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL()); // this will return base64 image results after resize
      };
    });
    return resized_base64;
  };

  const openCameraMobile = async () => {
    const file = await takePicture();
    UploadFile(file);
  };

  const uploadOptions = [
    {
      id: "camera",
      label: "Câmera",
      onClick: openCameraMobile,
      src: IMG.cameraIcon(),
      className: "border-b border-gray flex justify-center",
    },
    {
      id: "photos",
      label: "Fotos",
      onClick: handleInputRefClick,
      src: IMG.photoIcon(),
      className: "border-b border-gray flex justify-center",
    },
    {
      id: "document",
      label: "Documento",
      onClick: handleInputRefClick,
      src: IMG.documentIcon(),
      className: "border-b border-gray flex justify-center",
    },
    {
      id: "fechar",
      label: "Fechar",
      className: "ion-text-center",
      txtClassName: "text-primary",
    },
  ];

  const uploadOptionsData = getProfileData ? uploadOptions.filter((data) => data.id != "document") : uploadOptions;

  return (
    <div>
      <input
        id="file-upload-input"
        type="file"
        ref={inputRef}
        onChange={handleInputRef}
        style={{ display: "none" }}
      />
      <IonModal
        isOpen={isShowModal}
        className="custom-modal-wrapper"
        onDidDismiss={() => {
          setIsShowModal(false);
          setIsShowModal(false);
        }}
      >
        <IonRow>
          {uploadOptionsData.map((op) => (
            <IonCol
              size="12"
              className={`cursor-pointer py-3 ${op.className}`}
              onClick={() => {
                op.onClick && op.onClick();
                setIsShowModal(false);
              }}
              key={op.id}
            >
              {op.src}
              <IonText
                className={`ms-2 text-sm font-normal min-w-[84px] cursor-pointer ${op.txtClassName}`}
              >
                {op.label}
              </IonText>
            </IonCol>
          ))}
        </IonRow>
      </IonModal>
    </div>
  );
};

export default PicUpload;
