import { IonGrid, IonImg, IonRow } from "@ionic/react";
import React from "react";
import { IMG } from "../../styles/assets";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/reducer";

interface GridProps {
  children?: string | JSX.Element | JSX.Element[] | React.ReactNode;
  className?: string;
  backIcon?: boolean;
  handleBackClick?: () => void;
}

const Grid: React.FC<GridProps> = ({
  children,
  className,
  backIcon = false,
  handleBackClick,
}) => {
  const { mQuery } = useSelector((state: RootState) => state.auth);

  return (
    <IonGrid className={`${className ? className : ""} flex ${!mQuery ? "" : "items-start pt-8"} justify-center min-h-full`}>
      <IonRow>
        {backIcon ? (
          <IonImg src={IMG.backIcon} onClick={handleBackClick} className="mt-8"/>
        ) : null}
        {children}
      </IonRow>
    </IonGrid>
  );
};

export default Grid;
