import { IonCol, IonLabel, IonRow } from "@ionic/react";
import moment from "moment";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { CREATE_DEPENDENT } from "../../Redux/action";
import CustomFormik from "../../components/UI/Formik";
import { IMG } from "../../styles/assets";
import { registerMinorDependentField1 } from "./formFields";

const RegisterMinorDependentsFormOne = () => {
  const dispatch = useDispatch();

  const validationSchema= Yup.object().shape({
    name: Yup.string().required("Nome completo obrigatório"),
    birthdate: Yup.string().required("A data de nascimento é obrigatória")
      .test("birthdate", "A data de nascimento não pode ser uma data futura.",(value) => {
        const todayDate = moment(new Date(), "DD/MM/YYYY").format("YYYY-MM-DD")
        const valueDate = moment(value, "DD/MM/YYYY").format("YYYY-MM-DD")
        return moment(todayDate).diff(moment(valueDate), "days") >= 0;
      })
      .test("birthdate", "A data de nascimento deve ser pelo menos 120 anos atrás",(value) => {
        const todayDate =  moment().subtract(120, "years").toDate()
        const valueDate = moment(value, "DD/MM/YYYY").format("YYYY-MM-DD")
        return moment(todayDate).diff(moment(valueDate), "years") <= 0;
      })
      .test("birthdate", "Adicionar apenas dependentes menores", (value) => {
        const todayDate = moment(new Date(), "DD/MM/YYYY").format("YYYY-MM-DD")
        const valueDate = moment(value, "DD/MM/YYYY").format("YYYY-MM-DD")
        return moment(todayDate).diff(moment(valueDate), "years") < 18;
      }),
    celular: Yup.string().matches( /\b\d{13}\b/, { message: "O número do telefone celular deve ter 11 dígitos" }), //.required("Número de telefone é obrigatório")
    email: Yup.string()
      .email("Email inválido")
      .required("Nome completo obrigatório"),
  })

  return (
    <IonRow className="registerdependent-one">
        <IonCol size="12" className="p-0">
          <div className="max-w-[240px]">
            <div className="mb-3 normal-case text-secondary text-base font-bold flex items-center">
              <span className="w-11 h-11 rounded-[50px] border-[1] border-[#b2e1fe] font-normal relative bg-[#b2e1fe] text-secondary text-[21px] mr-[1.7rem] p-0 flex items-center justify-center">
                18
                <span className="h-5 w-5 absolute -right-2 top-0 bg-white rounded-full">
                  {IMG.IconMinus}
                </span>
              </span>
              Menor de idade
            </div>
          </div>
          <div className="mt-[10px] mb-[30px] mx-0">
            <IonLabel className="font-bold text-2xl text-secondary">
              Insira os dados do <br></br> dependente{" "}
              <span className="text-ternary">menor de idade</span>
            </IonLabel>
          </div>
        </IonCol>
        <CustomFormik
            formInputFields={registerMinorDependentField1}
            initialValues={{
              name: "",
              celular: "",
              birthdate: "",
              email: "",
            }}
            handleSubmit={(values) => {
              dispatch({ type: CREATE_DEPENDENT, payload:{ createDependent: values , isMinorSecondDetails : true} });
            }}
            validationSchema={validationSchema}
            btnName="Continuar"
          />
    </IonRow>
  );
};
export default RegisterMinorDependentsFormOne;
