const appColor = "#46B7F8";

export const clockIcon = (color = appColor) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1016 19.9609C15.9341 19.9609 19.8516 16.0434 19.8516 11.2109C19.8516 6.37845 15.9341 2.46094 11.1016 2.46094C6.26907 2.46094 2.35156 6.37845 2.35156 11.2109C2.35156 16.0434 6.26907 19.9609 11.1016 19.9609Z"
      fill={color}
    />
    <path
      d="M11.1016 5.96094V11.2109L14.6016 12.9609"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const videoIcon = () => (
  <svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3281 21.248C16.851 21.248 21.3281 16.7709 21.3281 11.248C21.3281 5.7252 16.851 1.24805 11.3281 1.24805C5.80528 1.24805 1.32812 5.7252 1.32812 11.248C1.32812 16.7709 5.80528 21.248 11.3281 21.248Z"
      stroke="#094F81"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.32812 7.24805L15.3281 11.248L9.32812 15.248V7.24805Z"
      stroke="#094F81"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const visibleField = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 28 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.08729 10.061C1.47399 10.5562 7.68556 18.5098 14.2663 18.5098C20.8471 18.5098 27.0586 10.5562 27.4454 10.061C27.4494 10.056 27.4527 10.0517 27.4555 10.0482C27.8188 9.57219 27.8188 8.93757 27.4555 8.46161C27.4527 8.45811 27.4494 8.45381 27.4454 8.44872C27.0586 7.95352 20.8471 0 14.2663 0C7.68556 0 1.47399 7.95357 1.08729 8.44872C1.08331 8.45381 1.07996 8.45811 1.07722 8.46161C0.713844 8.93757 0.713844 9.57219 1.07722 10.0482C1.07996 10.0517 1.08331 10.056 1.08729 10.061ZM14.2663 15.8655C10.0135 15.8655 5.51845 11.1587 3.87654 9.25488C5.9222 6.86184 10.3365 2.64425 14.2663 2.64425C18.5192 2.64425 23.0142 7.35102 24.6561 9.25488C22.6105 11.6479 18.1962 15.8655 14.2663 15.8655ZM8.88299 9.25453C8.88299 12.1764 11.292 14.543 14.2663 14.543C17.2406 14.543 19.6496 12.1764 19.6496 9.25453C19.6496 6.33263 17.2406 3.96603 14.2663 3.96603C11.292 3.96603 8.88299 6.33263 8.88299 9.25453ZM11.5746 9.25453C11.5746 7.80019 12.7859 6.61028 14.2663 6.61028C15.7467 6.61028 16.958 7.80019 16.958 9.25453C16.958 10.7089 15.7467 11.8988 14.2663 11.8988C12.7859 11.8988 11.5746 10.7089 11.5746 9.25453Z"
      fill="#ACACAC"
    />
  </svg>
);

export const hiddenField = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.79687 3.0398C10.2087 3.0398 12.3596 4.48576 13.4096 6.77349C13.0341 7.60168 12.506 8.31448 11.876 8.8915L12.7732 9.84868C13.6578 9.0137 14.3578 7.96826 14.7969 6.77349C13.696 3.79333 10.9787 1.6821 7.79687 1.6821C6.98869 1.6821 6.21233 1.81787 5.48051 2.06905L6.53051 3.18915C6.94415 3.1009 7.36415 3.0398 7.79687 3.0398ZM7.11597 3.81369L8.43324 5.21892C8.79597 5.38863 9.08869 5.7009 9.24778 6.08785L10.5651 7.49307C10.616 7.26226 10.6541 7.01787 10.6541 6.7667C10.6605 5.08315 9.37506 3.71866 7.79687 3.71866C7.56142 3.71866 7.33869 3.7526 7.11597 3.81369ZM1.4396 1.59385L3.14506 3.41317C2.10778 4.2821 1.28687 5.43615 0.796875 6.77349C1.89778 9.75364 4.61506 11.8649 7.79687 11.8649C8.76415 11.8649 9.69324 11.668 10.546 11.3082L12.7223 13.6299L13.6196 12.6727L2.33688 0.629883L1.4396 1.59385ZM6.21233 6.68524L7.87324 8.45704C7.84778 8.46383 7.82233 8.47061 7.79687 8.47061C6.91869 8.47061 6.20597 7.7103 6.20597 6.77349C6.20597 6.73954 6.21233 6.71918 6.21233 6.68524V6.68524ZM4.04869 4.37714L5.16233 5.56513C5.01597 5.9385 4.93324 6.34581 4.93324 6.77349C4.93324 8.45704 6.21869 9.82832 7.79687 9.82832C8.19778 9.82832 8.5796 9.74007 8.92324 9.58393L9.54687 10.2492C8.98687 10.4121 8.40142 10.5072 7.79687 10.5072C5.38506 10.5072 3.23415 9.06121 2.18415 6.77349C2.6296 5.80273 3.27869 5.00168 4.04869 4.37714Z"
      fill="#ACACAC"
    />
  </svg>
);

export const checkIcon = (color: string) => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7748 6.02281C15.0718 6.31155 15.0784 6.78638 14.7897 7.08336L8.95619 13.0834C8.67009 13.3776 8.20058 13.3873 7.90263 13.105L4.73613 10.105C4.43544 9.82012 4.42262 9.34542 4.7075 9.04472C4.99238 8.74403 5.46709 8.73122 5.76778 9.0161L8.39682 11.5069L13.7142 6.03773C14.003 5.74075 14.4778 5.73407 14.7748 6.02281Z"
      fill={color}
    />
  </svg>
);

export const paIcon = () => (
  <svg
    width="21"
    height="14"
    viewBox="0 0 21 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2715 1.26074H3.27148C2.16691 1.26074 1.27148 2.11724 1.27148 3.17379V10.826C1.27148 11.8825 2.16691 12.739 3.27148 12.739H11.2715C12.3761 12.739 13.2715 11.8825 13.2715 10.826V3.17379C13.2715 2.11724 12.3761 1.26074 11.2715 1.26074Z"
      stroke="#FAFAFA"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M19.2715 11.7825L13.2715 9.41987V4.57989L19.2715 2.21729V11.7825Z"
      stroke="#FAFAFA"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const copySvgIcon = (color = "white", strokeColor = "#1059B2") => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="5.66113"
      y="1.21875"
      width="13.4215"
      height="15.2539"
      rx="2.25"
      fill={color}
      stroke={strokeColor}
      strokeWidth="1.5"
    />
    <rect
      x="1.31934"
      y="4.46484"
      width="13.4215"
      height="15.2539"
      rx="2.25"
      fill={color}
      stroke={strokeColor}
      strokeWidth="1.5"
    />
  </svg>
);
