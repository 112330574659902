import * as moment from "moment";
import "moment-timezone";
import API_CONFIG from "../config/api.config";
import HttpClient from "../utils/HttpClient";
import { format } from "../utils/helper";

class ComplementoryService extends HttpClient {
    /**
        * API Service for to get history of appointment call and pa call
        * @param userId : user id 
        * @returns : return response
        */
    public getHistory(userId: string) {
        const timezone = moment.tz.guess();
        let currentDateAPI: Date | string = new Date();
        currentDateAPI.setHours(23, 59, 59, 999);
        currentDateAPI = currentDateAPI.toUTCString();
        const queryParams = `?date=${currentDateAPI}&timezone=${timezone}&page=1&limit=1000`;
        return this.http.get(`${format(API_CONFIG.historyAppointment, [userId])}${queryParams}`);
    }

    /**
     * Function for delete appointment (APS server call)
     * @param data 
     * @returns 
     */
    public deleteScheduleAppointment(data: { id: string, user_id: string }) {
        return this.http.delete(format(API_CONFIG.deleteAppointment, [data?.id, data?.user_id]));
    }

    /**
    * API Service for to get history of appointment call and pa call
    * @param username : user name
    * @param roomName : appointment id
    * @param createdById : staff Id
    */

    public getVideoAccessToken(data: { username: string, roomName: string, createdById: string }) {
        return this.http.post(API_CONFIG.getVideoAccessToken, data);
    }

    public getMedicine(userId: string) {
        return this.http.get(format(API_CONFIG.getMedicine, [userId]))
    }

    public exam(userId: string) {
        return this.http.get(format(API_CONFIG.exams, [userId]))
    }

    public deleteExamdata(data: { userId: string, examId: string, attachmentId: string | unknown, type: string }) {
        return this.http.delete(format(`${API_CONFIG.deleteExamResult}/${data.examId}/${data.attachmentId}/type=${data.type}`, [data.userId]));
    }

    public UploadExamResult(data: {
        attachment: string,
        exam_id?: string,
        title?: string,
        userId: string
    }) {
        return this.http.post(format(API_CONFIG.uploadExamResult, [data?.userId]), data);
    }
}

const complementoryService = new ComplementoryService();
export default complementoryService;
