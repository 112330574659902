import * as Yup from "yup";

export const profileValidationSchema = Yup.object().shape({
    height: Yup.number()
      .integer("Somente números são permitidos neste campo")
      .min(10, "altura mínima deve ser 10")
      .max(250, "altura máxima deve ser 250")
      .typeError("Somente números são permitidos neste campo"),
    weight: Yup.number()
      .integer("Somente números são permitidos neste campo")
      .min(1, "peso mínima deve ser 1")
      .max(350, "peso máxima deve ser 350")
      .typeError("Somente números são permitidos neste campo"),
    hipCircumference: Yup.number()
      .integer("Somente números são permitidos neste campo")
      .typeError("Somente números são permitidos neste campo"),
    abdominalCircumference: Yup.number()
      .integer("Somente números são permitidos neste campo")
      .typeError("Somente números são permitidos neste campo"),
  })
  