import { IonCol, IonLabel, IonRow, useIonToast } from "@ionic/react";
import Layout from "../../../components/Layout";
import CustomFormik from "../../../components/UI/Formik";
import { operatorLoginInputFields as formInputFields } from "./formFields";
import * as Yup from "yup";
import Grid from "../../../components/Grid";
import LeftGrid from "../../../components/Grid/LeftGrid";
import RightGrid from "../../../components/Grid/RightGrid";
import { IMG } from "../../../styles/assets";
import authService from "../../../services/auth-service";
import { AxiosResponse } from "axios";
import { useState } from "react";
import storeService from "../../../services/store.service";
import { navigateToRoute } from "../../../utils/helper";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { OPERATOR_LOGIN } from "../../../Redux/action";

const OperatorLogin = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [present] = useIonToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitLogin = (values: { email?: string; password?: string }) => {
    setLoading(true);
    authService
      .operatorLogin(values)
      .then(async (resp: AxiosResponse) => {
        const { data: { data: { user, token } } } = resp;
        await storeService.removeKey("operator_signup");
        storeService.set("operator_token", token);
        storeService.set("operator_login", user);
        await storeService.set("currentUserType", "operator");
        dispatch({ type: OPERATOR_LOGIN, payload: { user } })
        setLoading(false);
        present({ message: "Login realizado com sucesso!", duration: 2000 });
        navigateToRoute(navigate, "/patient/login", true);
      })
      .catch((error) => {
        console.error("[Error]: operator login", error);
        setLoading(false);
        const errors = error?.response?.data;
        const message =
          errors?.message === "User is not valid"
            ? "Os detalhes de login não são válidos. Por favor cheque novamente"
            : errors?.message === "User does not have active account"
              ? "Usuário não tem conta ativa"
              : errors?.message;

        present({ message, duration: 2000 });
      });
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Email inválido").required("E-mail é obrigatório"),
    password: Yup.string().required("A senha é obrigatória"),
  });

  return (
    <Layout loading={loading} routeName="login">
      <Grid className="operator-login [&>ion-row]:w-full items-start [&>ion-row>ion-img]:ml-3 [&>ion-row>ion-img]:mt-0 md:[&>ion-row>ion-img]:hidden !py-6 !px-0" handleBackClick={() => navigateToRoute(navigate, "/", true)} backIcon={true}>
        <IonRow className="md:px-0 mx-auto w-full max-w-[1320px] xl:items-center">
          <LeftGrid src={IMG.passwordImg} backIcon={true} onBackClick={() => navigate("/")}></LeftGrid>
          <RightGrid className="p-4 lg:p-0 mx-auto lg:mt-10">
            <IonLabel className="font-bold text-2xl md:text-3.5xl text-ternary block mb-3">Bem-vindo de volta</IonLabel>
            <IonLabel className="text-blank font-normal text-base md:text-xl pb-6 block lg:max-w-xs">
              Para iniciarmos seu acesso a plataforma, por favor nos informe seu
              e-mail e senha.
            </IonLabel>
            <IonRow>
              <IonCol size="12" className="lg:[&>form>.input-wrapper]:w-1/2 lg:[&>form>ion-button]:float-right [&>form>ion-button]:mt-0 lg:[&>form>ion-button]:w-1/2 lg:[&>form>ion-button]:pl-10 lg:[&>form>ion-button]:-mt-24">
                <CustomFormik
                  handleSubmit={submitLogin}
                  formInputFields={formInputFields}
                  initialValues={{ email: "", password: "" }}
                  validationSchema={validationSchema}
                  isLabel={true}
                  btnName="Entrar"
                />
              </IonCol>
            </IonRow>
          </RightGrid>
        </IonRow>
      </Grid>
    </Layout>
  );
};
export default OperatorLogin;
