import { IonAvatar, IonCheckbox, IonCol, IonGrid, IonImg, IonItem, IonLabel, IonRow, IonText } from "@ionic/react";
import { useState } from "react";
import Layout from "../../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/reducer";
import { IMG } from "../../styles/assets";
import { Details } from "../../Redux/dependent/types";
import { Button } from "../../components/UI/Button";
import { UPDATE_USER_PROFILE } from "../../Redux/action";
import { navigateToRoute } from "../../utils/helper";
import { useNavigate } from "react-router";
import { isEmpty } from "lodash";
import storeService from "../../services/store.service";

const DependentUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.auth);
  const { dependentuser } = useSelector((state: RootState) => state.dependent);
  const [selectedDependant, setSelectedDependant] = useState<Details>();
  
  const dependentUser = async () => {
    // await storeService.set("currentUserType", selectedDependant?.isDependentUser ? "dependent" : "patient")
    const data = selectedDependant;
    const original_user_id = await storeService.get("user_id");
    const original_user = await storeService.get("user");
    await storeService.set("user_id", data?.user_id);
    await storeService.set("user", data);
    await storeService.set("original_user_id", original_user_id);
    await storeService.set("original_user", original_user);

    dispatch({ type: UPDATE_USER_PROFILE, payload: { currentUserType: selectedDependant?.isDependentUser ? "dependent" : "patient", user: {...selectedDependant, profilePic: selectedDependant?.avatar}, updateUser: user } })
    navigateToRoute(navigate, "/home", true)
  };
  
  return (
    <Layout routeName="dependentuser">
      <IonGrid className="dependent-user mx-auto w-full max-w-[1320px] relative" >
      <IonRow className="max-w-xs mx-auto static block">
        <IonCol className="flex md:items-center md:justify-center flex-col static">
            <IonText className="text-[##292929] text-base block text-center mt-2"><span className="ps-2">{user.name}</span></IonText>
            <IonImg src={IMG.backIcon} className="h-6 w-6 my-4 absolute left-0" onClick={() => navigateToRoute(navigate, "/profile", true)} />    
            <IonText className="text-secondary text-2xl font-bold text-center mb-5"><span className="ps-2">Selecione um perfil e acesse a conta</span></IonText>
        </IonCol>
        <IonCol className="w-[calc(100vh-53vh)] overflow-y-auto p-[2px] min-w-fit py-[10px] px-0 mb-4">
          <div className="list overflow-y-auto h-[47vh]">
          {dependentuser?.minor?.map((minordata: Details) => (
            <IonItem
              className={`list-item border border-[#dfdfdf] rounded-[12px] m-0 mb-5 py-2 ${selectedDependant?.id === minordata.id? " !bg-[#b2e1fe] py-2" : " bg-white "}`}
              key={minordata?.id}
              onClick={() => {
                (isEmpty(selectedDependant) || selectedDependant?.id !== minordata?.id ) ? setSelectedDependant(minordata) : setSelectedDependant(undefined)
              }}
            >
              <IonCheckbox
                slot="start"
                color="primary"
                className="checked:border-0"
                checked={selectedDependant?.id === minordata.id ? true : false}
              />
              <IonAvatar>
                <IonImg
                  src={minordata?.avatar !== "" ? minordata?.avatar : IMG.defaultProfile}
                />
              </IonAvatar>
              <IonLabel className="text-[#292929] text-base m-0 ml-[10px]">
                {minordata?.name}
              </IonLabel>
            </IonItem>
            )
          )}
          </div>
        </IonCol>
        <IonCol>
          <Button className="" fill="solid" text="Continuar" id="dependent-btn" disabled={isEmpty(selectedDependant)} handleClick={dependentUser} />
        </IonCol>
        </IonRow>
      </IonGrid>
    </Layout>
  );
};

export default DependentUser;
