import { IonFooter, IonItem, IonLabel, IonMenu, IonMenuToggle, IonRow } from "@ionic/react";
import { menuController } from "@ionic/core";
import { menuItemArray, sideMenuSocialIcons } from "../../utils/layoutHelper";
import Header from "./Header";
import { navigateToRoute } from "../../utils/helper";
import { useNavigate } from "react-router";
import Modal from "../UI/Modal";
import { useState } from "react";
import storeService from "../../services/store.service";
import { RESET, RESET_DEPENDENT, RESET_PATIENT } from "../../Redux/action";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/reducer";

const SideMenu = () => {
  const { currentUserType, isPatientLink } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const navigateTo = (e: { currentTarget: { id: string } }) => {
    if (e.currentTarget.id !== "/logout") {
      navigateToRoute(navigate, e.currentTarget.id, true)
      menuController.close();
    } else{
      setIsOpen(true);
    }
  };

  const logout = async () => {
    if(currentUserType === "patient" || currentUserType === "dependent") {
      if(isPatientLink) {
        window.location.href = "https://www.avus.com.br/";
        dispatch({ type: RESET_PATIENT , payload: { currentUserType: "", user: {}, updateUser: {} }});
        dispatch({ type: RESET_DEPENDENT });
        await storeService.remove();
      } else {
        const operatorUser = await storeService.get("operator_login");
        await storeService.set("currentUserType", "operator");
        await storeService.logoutOperator();
        dispatch({ type: RESET_PATIENT , payload: { currentUserType: "operator", user: operatorUser, updateUser: {} }});
        navigateToRoute(navigate, "/patient/login", true);
      }
    } else {
      navigateToRoute(navigate, "/login", true);
      await storeService.logout();
      dispatch({ type: RESET , payload: { currentUserType: "", user: {}, updateUser: {} }});
    }
    setIsOpen(false);
  };

  return (
    <IonMenu
      side="start"
      menuId="first"
      contentId="main"
      swipeGesture={false}
      className="drawer-menu w-full custom-gradient"
    >
      <Header />
      <div className="menu-list mb-auto mt-8">
        {menuItemArray.map((item, index) => {
          const { route, imgName, display } = item;
          return (
            <IonMenuToggle autoHide={true} key={index}>
              <IonItem
                key={route}
                id={route}
                onClick={navigateTo}
                lines="none"
                className="menu-item bg-transparent pl-5"
              >
                {imgName}
                <IonLabel className="!text-white font-semibold ml-3 !text-lg">{display}</IonLabel>
              </IonItem>
            </IonMenuToggle>
          );
        })}
      </div>
      <IonFooter className="shadow-none	">
        <IonRow className="flex justify-around mx-6 mb-7 items-center">
          {sideMenuSocialIcons.map((item) => (
            <span
              onClick={() => window.open(item.link)}
              key={item.id}
            >
              {item.icon}
            </span>
          ))}
        </IonRow>
      </IonFooter>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        text={(currentUserType === "operator" || location.pathname === "/patient/login") ? " Tem certeza que deseja sair da plataforma do operador?" : "Tem certeza de que deseja sair?"}
        btnSimText={(currentUserType === "operator" || location.pathname === "/patient/login") ? "Sim, Sair" : "Sim"}
        handleClick={logout}
        btnCancelText={(currentUserType === "operator" || location.pathname === "/patient/login") ? "Não, continuar logado" : "Cancelar"}
        btnCancelClick={() => setIsOpen(false)}
        btnSimColor={(currentUserType === "operator" || location.pathname === "/patient/login") ? "danger" : "secondary"}
        // className="[&>div>div>ion-button]:mx-1 [&>div>div>ion-button]:my-0"
        btnClass={`flex flex-col m-auto ${(currentUserType === "operator" || location.pathname === "/patient/login") ? "w-[80%]" : "w-[60%]"}`}
        btnSimFill="outline"
        customTitleColor="text-secondary sm:text-sm md:text-xl"
      />
    </IonMenu>
  );
};

export default SideMenu;
