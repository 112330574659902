import { IonAvatar, IonButton, IonCol, IonGrid, IonImg, IonInput, IonLabel, IonRow, useIonToast } from "@ionic/react";
import { AxiosResponse } from "axios";
import { Formik } from "formik";
import { size } from "lodash";
import moment from "moment";
import "moment-timezone";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { DEPENDENT, UPDATE_USER_PROFILE, USER } from "../../Redux/action";
import { RootState } from "../../Redux/reducer";
import LeftGrid from "../../components/Grid/LeftGrid";
import Layout from "../../components/Layout";
import { Button } from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import profileService from "../../services/profile.service";
import storeService from "../../services/store.service";
import { IMG } from "../../styles/assets";
import { navigateToRoute, useDidMountHook } from "../../utils/helper";
import platform from "../../utils/platform";
import { ProfileData } from "./type";
import PicUpload from "../../components/PicUpload";
import { profileValidationSchema } from "./profile-validation";

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user , updateUser, currentUserType} = useSelector((state: RootState) => state.auth);
  const { dependentHasMinor, dependentHasMajor } = useSelector((state: RootState) => state.dependent);
  const hiddenProfileButton = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [profileData, setProfileData] = useState<ProfileData | null>(null);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [present] = useIonToast();
  const ageData = moment().diff(profileData?.birthdate, "years", false);

  useDidMountHook(() => {
    if(currentUserType !== "dependent") getDependentUser();
    setProfileData(null);
    getProfileData();
  });

  const getDependentUser = () => {
    profileService
      .getDependentUser(user?.user_id)
      .then((resp: AxiosResponse) => {
        const { data: { result, status } } = resp;
        if (status === 200) dispatch({ type: DEPENDENT, payload: { dependentuser: result, dependentHasMinor: size(result?.minor) > 0, dependentHasMajor: size(result?.major) > 0 } });
        else dispatch({ type: DEPENDENT, payload: { dependentuser: [], dependentHasMinor: false, dependentHasMajor: false } });
      })
      .catch((error: { response: { data: { message: string } } }) => {
        console.error("[Error]: get dependent user", error);
      });
  };

  const getProfileData = () => {
    setIsLoading(true);
    profileService
      .getProfileDetails(user?.user_id)
      .then(async (response: AxiosResponse) => {
        const { data: { date } } = response;
        setIsLoading(false);
        setProfileData(date);
        const userDetails = await storeService.get("user");
        const newUser = {
          ...userDetails,
          telePhone: date.phones ? date.phones : [],
          email: date.email ? date.email : "",
          profilePic: date.avatar ?  date.avatar : ""
        };
        storeService.set("user", newUser);
        // dispatch({ type: USER, payload: newUser });
      })
      .catch((err: { response: { data: { message: string } } }) => {
        setIsLoading(false);
        console.error("[Error]: get profile details", err);
      });
  };

  const submitProfileData = (
    values: {
      name: string | undefined;
      email: string | undefined;
      dob: string | undefined;
      weight: string | number;
      height: string | number;
      hipCircumference: string | number;
      abdominalCircumference: number;
      date: string | undefined;
    },
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const data = {
      measurements: {
        weight: values.weight.toString().replace(/\s/g, "") ?? "",
        height: values.height.toString().replace(/\s/g, "") ?? "",
        hipCircumference: values.hipCircumference.toString().replace(/\s/g, "") ?? "",
        abdominalCircumference: values.abdominalCircumference.toString().replace(/\s/g, "") ?? "",
      },
    };

    setIsLoading(true);
    profileService
      .updateProfileDetails(user?.user_id, data)
      .then(async () => {
        setSubmitting(false);
        getProfileData();
        present({ message: "sucesso", duration: 2000 });
      })
      .catch((err: { response: { data: { message: string } } }) => {
        setSubmitting(false);
        present({ message: err.response.data.message , duration: 2000 });
        console.error("[Error]: update profile details", err);
      });
  };

  const redirectToHome = () => {
    navigateToRoute(navigate, "/home", true);
  };

  const handleProfileInput = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      const fileUploaded = event.target.files[0];
      const reader = new FileReader();
      reader.onloadend = async () => {
        setIsLoading(true);
      };
      reader.readAsDataURL(fileUploaded);
    }
  };

  const logoutOfDependent = async () => {
    // await storeService.set("currentUserType", "patient");

    const original_user_id = await storeService.get("original_user_id");
    const original_user = await storeService.get("original_user");
    await storeService.set("user", original_user);
    await storeService.set("user_id", original_user_id);
    await storeService.set("original_user_id", null);
    await storeService.set("original_user", null);


    dispatch({ type: UPDATE_USER_PROFILE, payload: {currentUserType: "patient", user: updateUser, updateUser: {} } });
    navigateToRoute(navigate, "/home", true);
  };

  const AddDependantButton = () => (
    <>
      {!user?.isDependentUser ? (
        <Button
          shape="round"
          text="Cadastrar dependentes"
          imgSrc={IMG.adicionarButton}
          imgSlot="start"
          fill="solid"
          imgClass="mr-2"
          id="add-dependente-btn"
          className="capitalize shadow-none text-white h-11 mt-4 mb-3 text-sm"
          handleClick={() =>
            navigateToRoute(navigate, "/selectdependents", true)
          }
        />
      ) : null}
    </>
  );

  const DependantListButton =  () => (
    <>
      {(dependentHasMinor || dependentHasMajor) && !user?.isDependentUser ? (
        <Button
          shape="round"
          text="Ver dependentes cadastrados"
          color="secondary"
          fill="clear"
          id="view-dependente-btn"
          className="capitalize shadow-none h-11 md:mt-4 mb-3"
          handleClick={() =>
            navigateToRoute(navigate, "/meusdependentes", true)
          }
        />
      ) : null}
    </>
  );

  const uploadPicture = async (attachment: string) => {
    setIsLoading(true);
    const data = { profile: attachment };
    profileService
      .updateUserProfilePic(user?.user_id, data)
      .then(async (res: AxiosResponse) => {
        setIsLoading(false);
        getProfileData();
        const userData = await storeService.get("user");
        await storeService.set("user", {
          ...userData,
          avatar: {
            url: res?.data?.profile.url,
          },
          profilePic: res?.data?.profile.url,
        });
        dispatch({ type: USER, payload: { ...userData, profilePic: res?.data?.profile.url }});
      })
      .catch((error: { response: { data: { message: string } } }) => {
        setIsLoading(false);
        console.error("[Error]: update user profile pic", error);
      });
  };

  const handleProfileUpload = () => {
      setIsShowModal(true);
  };

  const measurementsData: { id: "weight" | "height", title: string, type: string, placeHolder: string }[] = [
    {id: "weight", title: "Peso", type: "tel", placeHolder: "69 kg" },
    {id: "height", title: "Altura", type: "tel", placeHolder: "170 cm" }
  ]

  return (
    <Layout
      routeName="profile"
      handleBackClick={() => navigateToRoute(navigate, "/home", true)}
      loading={isLoading}
    >
      <IonGrid className="p-0">
        <div className="flex relative profile-outer after:content-[''] after:w-1/2 after:h-full after:bg-[#f2f9fe] after:absolute after:right-0 after:top-0">
          <div className="flex-2 pl-0 pt-0 relative bg-white md:w-full max-w-[1320px] mx-auto">
            <div className="relative z-[1] h-[calc(100vh-177px)]">
              <div className="[&>Form]:h-full px-3 md:px-0 md:pl-3 pb-[30] bg-white sm:p-0 update-profile-view h-full">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    name: profileData?.name,
                    email: profileData?.email,
                    dob: profileData?.birthdate,
                    weight: profileData?.measurements?.weight ? profileData?.measurements?.weight : "",
                    height: profileData?.measurements?.height ? profileData?.measurements?.height: "",
                    hipCircumference: profileData?.measurements?.hipCircumference ? profileData?.measurements?.hipCircumference : "",
                    abdominalCircumference: profileData?.measurements?.abdominalCircumference ? profileData?.measurements?.abdominalCircumference : "",
                    date: profileData?.measurements?.date && moment(profileData?.measurements?.date).format("DD/MM/YYYY"),
                  }}
                  validationSchema={profileValidationSchema}
                  onSubmit={(values, { setSubmitting }) =>
                    submitProfileData({ ...values, abdominalCircumference: Number(values.abdominalCircumference),}, setSubmitting)
                  }
                >
                  {({ values, errors, handleChange, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <IonRow className="h-full">
                        <IonCol sizeSm="12" sizeMd="5" size="12">
                          <div className="pt-2 pl-0 md:py-1 md:pr-1 md:pl-0 text-center md:text-left">
                            <LeftGrid
                              name={user?.name}
                              title="Meu perfil"
                              backIcon={true}
                            />

                            <IonLabel className="text-gray-500 text-base font-normal md:text-left text-center md:block md:mb-[10] md:p-0 md:ms-8">
                              Confira seus dados pessoais
                            </IonLabel>

                            <div className="text-left mt-10 md:text-center md:mb-6 md:mt-6 pl-0 md:ml-8">
                              <div className="rightuserphoto flex items-center flex-col md:flex-row">
                                <div className="header-content-body flex md:hidden items-center">
                                  <IonAvatar className="w-16 h-15">
                                    <img
                                      src={profileData?.avatar || IMG.defaultProfile}
                                      onClick={handleProfileUpload}
                                      alt="defaultProfile"
                                      className="w-full h-full object-cover"
                                    />
                                  </IonAvatar>
                                  <input
                                    id="file-upload-input"
                                    type="file"
                                    ref={hiddenProfileButton}
                                    onChange={handleProfileInput}
                                    style={{ display: "none" }}
                                  />
                                    <span onClick={handleProfileUpload} className="w-auto">
                                      <IonImg src={IMG.editIcon} className="max-w-[20px] mt-11 opacity-50"/>
                                    </span>
                                </div>
                                <div className="title-sub-header-txt">
                                  <IonLabel className="hidden text-base text-left pl-[5px] mb-5 md:text-center">
                                    Editar meus dados pessoais
                                  </IonLabel>
                                  <IonLabel className="mt-1 block md:hidden text-base text-left pl-[5px] mb-5 md:text-center">
                                    Edite sua foto
                                  </IonLabel>
                                </div>
                                {dependentHasMinor && user.isDependentUser ? (
                                  <IonButton
                                    expand="full"
                                    fill="outline"
                                    shape="round"
                                    className="h-11 capitalize  text-ternary w-full md:w-[49%] !ml-0 !md:ml-20 md:mb-4 md:hidden w-100 !p-0"
                                    onClick={() => logoutOfDependent()}
                                  >
                                    Voltar para o perfil do titular
                                  </IonButton>
                                ) : ageData > 18 && dependentHasMinor ? (
                                  <>
                                    <IonButton
                                      expand="full"
                                      fill="outline"
                                      shape="round"
                                      className="h-11 capitalize bg-white text-ternary w-full md:w-[49%] md:mb-4 md:hidden md:max-w-[200px] !ml-0 !md:ml-20"
                                      onClick={() => navigateToRoute(navigate, "/dependent-user", true)}
                                    >
                                      Alternar contas
                                    </IonButton>
                                  </>
                                ) : null}
                              </div>
                              <div className="w-full">
                                <span className="border-b border-secondary flex selection: w-full flex-col mt-3 md:mt-6 mr-0 mb-[10px] ml-0 pb-2 md:b-0 md:p-0 md:m-0 md:text-center">
                                  <IonLabel className="text-[#094f81] text-base font-medium text-center md:text-left">
                                    Nome completo
                                  </IonLabel>
                                  <IonLabel className="text-black text-base font-normal text-center  md:text-left mt-1 md:pb-2">
                                    {profileData?.name}
                                  </IonLabel>
                                </span>
                                <div className="text-center md:text-left mt-3">
                                  {profileData?.birthdate && (
                                    <IonLabel className="text-[#094f81]">
                                      Data de Nascimento:
                                      <strong className="text-[#094f81] font-bold text-base text-left">
                                        {moment(profileData?.birthdate).format("DD/MM/YYYY")}
                                      </strong>
                                    </IonLabel>
                                  )}
                                </div>
                              </div>
                            </div>

                            <IonGrid className="edit-grid-view"></IonGrid>
                            <div className="w-100 md:mt-3 max-w-[400px] md:pl-8 mx-auto md:mx-0">
                              <IonLabel className="block relative mb-3 md:mb-2">
                                <IonLabel
                                  color="light"
                                  position="stacked"
                                  className="text-lg font-normal flex items-center !text-[#5f5f5f] !mb-3 md:ml-4 opacity-100"
                                >
                                  Email
                                </IonLabel>
                                <IonInput
                                  className="lg:!bg-[#f4f5f7] !rounded-xl md:bg-white border md:border-0 border-[#acacac] text-left !px-3"
                                  type="text"
                                  placeholder="Digite seu email"
                                  disabled
                                  value={values.email}
                                  name="email"
                                  onIonChange={handleChange}
                                ></IonInput>
                                <IonImg src={IMG.editIcon} className="absolute bottom-4 right-7 z-[2] max-w-[25px] opacity-30"/>
                              </IonLabel>

                              <IonLabel className="block relative mb-3 md:mb-2">
                                <IonLabel
                                  color="light"
                                  position="stacked"
                                  className="text-lg font-normal flex items-center !text-[#5f5f5f] !mb-3 md:ml-4 opacity-100"
                                >
                                  Telefone
                                </IonLabel>
                                <IonInput
                                  className="lg:!bg-[#f4f5f7] !rounded-xl md:bg-white border border-[#acacac] md:border-0 text-left !px-3"
                                  type="tel"
                                  disabled
                                  name="celular"
                                  value={profileData?.phones[0]?.number}
                                  placeholder="11 99685-5487"
                                  onIonChange={handleChange}
                                ></IonInput>
                                <IonImg src={IMG.editIcon} className="absolute bottom-4 right-7 z-[2] max-w-[25px] opacity-30"/>
                              </IonLabel>

                              <div className="hidden md:block">
                                <AddDependantButton />
                                <DependantListButton />
                              </div>
                            </div>
                          </div>
                        </IonCol>

                        <IonCol
                          sizeSm="12"
                          sizeMd="7"
                          className="md:bg-[#f2f9fe] md:pl-12 md:pb-10 md:pt-12 md:-mr-5 md:pr-8 relative md:after:content-[''] md:after:w-full md:after:h-full md:after:bg-[#f2f9fe] md:after:absolute md:after:right-[-100%] md:after:top-0 md:after:z-[-1]"
                        >
                          <div className="header-content-body relative hidden md:flex items-center">
                            <IonAvatar className="w-16 h-16">
                              <img src={profileData?.avatar || IMG.defaultProfile} alt="" className="w-full h-full object-cover" />
                            </IonAvatar>
                            <input
                              id="file-upload-input"
                              type="file"
                              ref={hiddenProfileButton}
                              onChange={handleProfileInput}
                              style={{ display: "none" }}
                            />
                            <span onClick={handleProfileUpload} className="cursor-pointer">
                              <IonImg src={IMG.editIcon} className="absolute bottom-1 left-16 z-[2] h-5 w-5 opacity-40" />
                            </span>
                            {dependentHasMinor && user.isDependentUser? (
                              <IonButton
                                expand="full"
                                fill="outline"
                                shape="round"
                                className="h-11 capitalize bg-white text-secondary w-full md:w-[49%] !ml-20 !md:ml-0 border-1 rounded-[20px] md:mt-0 md:mb-4 mt-11"
                                onClick={() => logoutOfDependent()}
                              >
                                Voltar para o perfil do titular
                              </IonButton>
                            ) : ageData > 18 && dependentHasMinor ? (
                              <>
                                <IonButton
                                  expand="full"
                                  fill="outline"
                                  shape="round"
                                  className="h-11 capitalize bg-white text-secondary w-full md:w-[49%] !ml-20 !md:ml-0 !border-0 rounded-[20px] md:mt-0 md:mb-4"
                                  onClick={() => navigateToRoute(navigate, "/dependent-user", true)}
                                >
                                  Alternar contas
                                </IonButton>
                              </>
                            ) : null}
                          </div>

                          <div className="title-sub-header-txt hidden md:block  ">
                            <IonLabel className="text-[#5f5f5f] text-xl text-left pl-[5px] mb-5 mt-2 block">
                              Edite sua foto
                            </IonLabel>
                          </div>

                          <IonLabel className="text-xl font-normal text-[#5f5f5f] block mr-0 mb-2 ml-0 text-center md:text-start md:mt-6">
                            Confira ou atualize suas medidas
                          </IonLabel>
                          <IonRow>
                            <IonCol size="12" sizeLg="3" sizeMd="4" sizeSm="12" className="!max-w-[55%] mx-auto md:mx-0 [&>.input-wrapper]:mb-0">
                              <IonLabel
                                color="light"
                                position="stacked"
                                className="text-xl font-normal flex items-center !text-[#5f5f5f] !mb-2 ml-4 opacity-100"
                              >
                                Data
                              </IonLabel>
                              <Input
                                name="date"
                                type={"text"}
                                placeholder={"dd/mm/yyyy"}
                                errors={errors.date}
                                errorText={errors.date}
                                value={values.date}
                                onChange={handleChange}
                                className="bg-white relative flex flex-row items-center py-3 px-4 h-12 border border-[#acacac] box-border rounded-xl my-1 mx-0 !ps-3 !text-[#5f5f5f] font-medium text-base input-hide-calendar-icon"
                                disabled={true}
                              />
                            </IonCol>
                            
                            {measurementsData.map((item:  { id: "weight" | "height", title: string, type: string, placeHolder: string })=>  (
                              <IonCol sizeLg="3" sizeMd="4" sizeSm="6" size="6" key={item.id}>
                                <IonLabel
                                  color="light"
                                  position="stacked"
                                  className="text-xl font-normal flex items-center !text-[#5f5f5f] !mb-2 ml-4 opacity-100"
                                >
                                  {item.title}
                                </IonLabel>
                                <Input
                                  placeholder={item.placeHolder}
                                  name={item.id}
                                  type={item.type}
                                  errors={errors[item.id]}
                                  errorText={errors[item.id]}
                                  value={values[item.id]}
                                  onChange={handleChange}
                                  editIcon={true}
                                  className="bg-white relative flex flex-row items-center py-3 px-4 h-12 border border-[#acacac] box-border rounded-xl my-1 mx-0 !ps-3 !text-[#5f5f5f] font-medium text-base input-hide-calendar-icon"
                                />
                              </IonCol>
                            ))}
                          </IonRow>
                          <IonRow className="mt-md-2 items-center">
                            <IonCol size="12" sizeMd="5" className="p-0">
                              <IonRow>
                                <IonCol sizeLg="12" className="[&>.input-wrapper]:mb-0">
                                  <IonLabel className="label text-xl font-normal !text-[#5f5f5f] pl-0">
                                    Medida circunferência abdominal
                                  </IonLabel>
                                  <Input
                                    placeholder="72 cm"
                                    name="abdominalCircumference"
                                    type={"tel"}
                                    errors={errors.abdominalCircumference}
                                    errorText={errors.abdominalCircumference}
                                    value={values.abdominalCircumference}
                                    onChange={handleChange}
                                    editIcon={true}
                                    className="bg-white relative flex flex-row items-center py-3 px-4 h-12 border border-[1.5] border-[#acacac] box-border rounded-xl my-1 mx-0 !ps-3 !text-[#5f5f5f] font-medium text-base input-hide-calendar-icon"
                                  />
                                </IonCol>
                                <IonCol sizeLg="12" className="mt-md-1 [&>.input-wrapper]:mb-0">
                                  <IonLabel className="label text-xl font-normal !text-[#5f5f5f] pl-0">
                                    Medida circunferência do quadril
                                  </IonLabel>
                                  <Input
                                    placeholder="72 cm"
                                    name="hipCircumference"
                                    type={"tel"}
                                    errors={errors.hipCircumference}
                                    errorText={errors.hipCircumference}
                                    value={values.hipCircumference}
                                    onChange={handleChange}
                                    editIcon={true}
                                    className="bg-white relative flex flex-row items-center py-3 px-4 h-12 border border-[1.5] border-[#acacac] box-border rounded-xl my-1 mx-0 !ps-3 !text-[#5f5f5f] font-medium text-base input-hide-calendar-icon"
                                  />
                                </IonCol>
                              </IonRow>
                            </IonCol>
                            <IonCol
                              size="12"
                              sizeMd="6"
                              sizeLg="4"
                              className="flex pb-4 md:pb-0 md:px-4"
                            >
                              <IonRow className="w-full mx-auto md:mx-0">
                                <IonCol size="6" sizeMd="12">
                                  <Button
                                    expand="full"
                                    shape="round"
                                    fill={"solid"}
                                    className="w-100 m-0 text-sm capitalize"
                                    text="Salvar Alterações"
                                    id="profile-save-btn"
                                    handleClick={handleSubmit}
                                  />
                                </IonCol>
                                <IonCol size="6" sizeMd="12">
                                  <Button
                                    fill="outline"
                                    expand="full"
                                    shape="round"
                                    className="w-100 m-0 text-sm capitalize !text-[#094f81] profile-btn"
                                    handleClick={redirectToHome}
                                    id="profile-cancel-btn"
                                    text="Cancelar"
                                  />
                                </IonCol>
                              </IonRow>
                            </IonCol>
                          </IonRow>
                          <div className="text-[#5f5f5f] font-normal px-3 flex items-baseline mt-2 pb-3 md:pb-0">
                            <span className="mr-2 h-6 w-6 relative top-3">
                              {IMG.videoIcon}
                            </span>
                            <div className="text-[15px] text-[#5f5f5f]">
                              Assista vídeo tutorial de como tirar suas medidas
                              de circunferência &nbsp;
                              <span
                                className="mr-[5px] text-[#0073bb] cursor-pointer hover:text-[#29265d]"
                                onClick={() => window.open("https://repository-web.s3.sa-east-1.amazonaws.com/video/ABDOMINAL.mp4")}
                              >
                                abdominal{" "}
                              </span>ou{" "}
                              <span
                                className="mr-[5px] text-[#0073bb] cursor-pointer hover:text-[#29265d]"
                                onClick={() => window.open("https://repository-web.s3.sa-east-1.amazonaws.com/video/QUADRIL.mp4")}>{" "}quadril</span>.
                            </div>
                          </div>
                          <div className="border-t border-t-[#5f5f5f] md:hidden">
                            <AddDependantButton />
                            <DependantListButton />
                          </div>
                        </IonCol>
                      </IonRow>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <PicUpload
          setIsShowModal={setIsShowModal}
          isShowModal={isShowModal}
          uploadPicture={uploadPicture}
          getProfileData={true}
        />
      </IonGrid>
    </Layout>
  );
};

export default Profile;
