import { IonIcon, IonInput, IonLabel, IonText } from "@ionic/react";
import MaskedInput from "react-text-mask";
import { IMG } from "../../../styles/assets";

interface InputProps {
  mask?: Array<string | RegExp> | false;
  label?: string;
  name: string;
  type: "text" | "password" | "search" | "tel" | "date" | "select" | any;
  value: string | any;
  onChange?: (e: any) => void;
  onKeyPress?: (e: any) => void;
  placeholder?: string;
  icon?: string | JSX.Element;
  editIcon?: boolean;
  errors?: object | string | unknown;
  errorText?: string;
  disabled?: boolean;
  max?: string;
  pattern?: string;
  className?: string;
  onFocus?: () => void;
  iconClick?: () => void;
  isLabelAfter?: boolean;
  labelClassName?: string;
  errorclass?: string;
  readonly?: boolean;
}

const Input = ({
  mask,
  className = "",
  label = "",
  name = "",
  value = "",
  type = "text",
  onChange,
  onKeyPress,
  errors,
  placeholder,
  icon = "",
  errorText = "",
  disabled = false,
  max = "",
  pattern = "",
  onFocus,
  iconClick,
  isLabelAfter,
  labelClassName,
  errorclass,
  readonly = false,
  editIcon = false,
}: InputProps) => {
  return (
    <div className="input-wrapper mb-5 w-full text-base block relative">
      {!isLabelAfter ? (
        <IonLabel className={labelClassName}>{label}</IonLabel>
      ) : null}
      {mask ? (
        <MaskedInput
          className={`${className} flex h-12 w-[100%] bg-[#f4f5f7] items-center justify-center self-stretch rounded-xl border-none outline-none ps-4 border font-bold`}
          type={type}
          name={name}
          mask={mask}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
          id={`${name}-input`}
          readOnly={readonly}
        />
      ) : (
        <IonInput
          id={`${name}-input`}
          className={`${className} bg-[#f4f5f7] h-3.2 h-12 b-0 !ps-4 text-base z-[1px] font-bold rounded-xl`}
          value={value}
          type={type}
          name={name}
          label={name === "celular" ? "+55" : undefined}
          placeholder={placeholder}
          onIonInput={onChange}
          onKeyPress={onKeyPress}
          disabled={disabled}
          max={max ? max : undefined}
          pattern={pattern ? pattern : undefined}
          onFocus={onFocus}
          readonly={readonly}
        />
      )}
      {icon &&
        (typeof icon === "string" ? (
          <IonIcon color="dark" src={icon}
            className="absolute top-3 cursor-pointer right-5 z-[2] h-4 w-4 opacity-40"
            onClick={() => (iconClick ? iconClick() : null)}
          />
        ) : (
          <span className="absolute top-3 cursor-pointer right-5 z-[2] h-4 w-4 opacity-40" onClick={() => (iconClick ? iconClick() : null)}>
            {icon}
          </span>
        ))}
      {isLabelAfter ? (
        <IonLabel className={`block mt-1 px-3 ${labelClassName ? labelClassName : "text-white"}`}>
          {label}
        </IonLabel>
      ) : null}
      {errors ? (
        <IonText className={`text-red-500 text-[11px] leading-3 font-normal ${errorclass ? errorclass : ""}`}>{errorText}</IonText>
      ) : null}
      {editIcon && !icon && (type === "date" ? "" : <IonIcon color="dark" className="absolute !top-4 right-4 z-[9] opacity-30" src={IMG.editIcon} />)}
    </div>
  );
};

export default Input;
