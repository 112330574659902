
import API_CONFIG from "../config/api.config";
import { payloadInterface } from "../pages/registerDependents/formFields";
import HttpClient from "../utils/HttpClient";
import { format } from "../utils/helper";

class ProfileService extends HttpClient {
  public async getProfileDetails(userId: string) {
    return this.http.get(format(API_CONFIG.getProfileDetails, [userId]));
  }

  public async updateProfileDetails(userId: string, data: {
    measurements: {
      weight: string;
      height: string;
      hipCircumference: string;
      abdominalCircumference: string;
    }
  }) {
    return this.http.post(format(API_CONFIG.updateProfileDetails, [userId]), data, {
      // headers: {
      //   token: user?.token,
      // },
    });
  }

  public async updateUserProfilePic(userId: string, data: { profile: string | ArrayBuffer | null | undefined }) {
    return this.http.post(format(API_CONFIG.updateUserProfilePic, [userId]), data, {
      // headers: {
      //   token: user?.token,
      // },
    });
  }

  public async createDependentUser(data: payloadInterface) {
    return this.http.post(API_CONFIG.createDependentUser, data, {
      // headers: {
      //   token: user?.token,
      // },
    });
  }

  public async getDependentUser(userId: string) {
    return this.http.get(`${API_CONFIG.getDependentUser}/${userId}`);
  }

  public async getDetailsByCPF(userCpf: string) {
    return this.http.post(API_CONFIG.getDetailsByCPF, { cpf: userCpf }, {
      // headers: {
      //   token: user?.token,
      // },
    });
  }
}

export default new ProfileService();
