import cryptoJs from "crypto-js";
import APP_CONFIG from "../config/app.config";

export class CryptoService {
    private _crypto: typeof cryptoJs;

    private _secret = APP_CONFIG.SECRET;

    constructor() {
        this._crypto = cryptoJs;
    }
}
