const appColor = "#1059B2";

export const PlusIcon = (color = appColor) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1909_31571)">
      <path
        d="M10.7527 19.2719C15.5898 19.2719 19.5112 15.3506 19.5112 10.5134C19.5112 5.6762 15.5898 1.75488 10.7527 1.75488C5.91546 1.75488 1.99414 5.6762 1.99414 10.5134C1.99414 15.3506 5.91546 19.2719 10.7527 19.2719Z"
        stroke={color}
        strokeWidth="1.7517"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7529 7.00977V14.0166"
        stroke={color}
        strokeWidth="1.7517"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.24902 10.5137H14.2558"
        stroke={color}
        strokeWidth="1.7517"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1909_31571">
        <rect
          width="21.0204"
          height="21.0204"
          fill="white"
          transform="translate(0.242188 0.00390625)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const MinuseIcon = (color = appColor) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1909_31584)">
      <path
        d="M10.7527 19.2719C15.5898 19.2719 19.5112 15.3506 19.5112 10.5134C19.5112 5.6762 15.5898 1.75488 10.7527 1.75488C5.91546 1.75488 1.99414 5.6762 1.99414 10.5134C1.99414 15.3506 5.91546 19.2719 10.7527 19.2719Z"
        stroke={color}
        strokeWidth="1.7517"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.24902 10.5137H14.2558"
        stroke={color}
        strokeWidth="1.7517"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1909_31584">
        <rect
          width="21.0204"
          height="21.0204"
          fill="white"
          transform="translate(0.242188 0.00390625)"
        />
      </clipPath>
    </defs>
  </svg>
);
