export const USER = "user/USER"
export const MQUERY = "MQUERY"
export const DEPENDENT = "DEPENDENT"
export const DEPENDENTTYPE = "DEPENDENTTYPE"
export const SCHEDULING = "scheduling/SCHEDULING"
export const RESET_SCHEDULING = "scheduling/SCHEDULING"
export const SPECIALITY = "SPECIALITY"
export const PATIENTLINK = "PATIENTLINK"
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE"
export const CREATE_DEPENDENT = "CREATE_DEPENDENT"
export const OPERATOR_LOGIN = "OPERATOR_LOGIN"
export const LOGIN_PATIENT = "LOGIN_PATIENT"
export const RESET_PATIENT = "RESET_PATIENT"
export const RESET_DEPENDENT = "RESET_DEPENDENT"
export const RESET = "RESET"
export const CHAT_DESIGN = "CHAT_DESIGN";