import { AxiosResponse } from "axios";
import { find } from "lodash";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/reducer";
import Grid from "../../components/Grid";
import LeftGrid from "../../components/Grid/LeftGrid";
import RightGrid from "../../components/Grid/RightGrid";
import Layout from "../../components/Layout";
import Tabs, { tabType } from "../../components/UI/Tabs";
import complementoryService from "../../services/complement-service";
import { IMG } from "../../styles/assets";
import HistoryList from "./HistoryList";
import { HistoryItemType, HistoryItemsType, historyTabType } from "./types";
import { navigateToRoute, useDidMountHook } from "../../utils/helper";
import { useNavigate } from "react-router";
import moment from "moment";

const History = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<historyTabType>("future");
  const [historyData, setHistoryData] = useState<HistoryItemsType>([]);
  const [futureData, setFutureData] = useState<HistoryItemsType>([]);
  const [historyAppointmentdata, setHistoryAppointmentdata] = useState<HistoryItemType>();

  const tabs: tabType[] = [
    { value: "future", label: "Agendadas" },
    { value: "past", label: "Histórico" },
  ];

  useDidMountHook(() => {
    if (user) getHistoryAppointments();
  });

  const getHistoryAppointments = () => {
    setLoading(true);
    complementoryService
      .getHistory(user.user_id)
      .then((res: AxiosResponse) => {
        setLoading(false);
        if (res) {
          const { data: { pastAppointments, futureAppointments } } = res;
          const historyAppointmentdata = find(pastAppointments, (d) => d.staffSpeciality && d.status === "Completed" &&
            moment(new Date(), "DD/MM/YYYY").diff(moment(d.appointment_date, "DD/MM/YYYY"), "days") <= 15);
          setHistoryAppointmentdata(historyAppointmentdata);
          setFutureData(futureAppointments);
          setHistoryData(pastAppointments);
        }
      })
      .catch((err) => {
        console.error("[Error]: get Appointment List ", err);
        setLoading(false)
      });
  };

  return (
    <Layout routeName="history" loading={loading} handleBackClick={() => navigateToRoute(navigate, "/home", true)}>
      <Grid className="[&>ion-row]:w-full pt-0 pt-xl-4 px-0 mx-auto max-w-[1320px]">
        <LeftGrid
          title="Minhas consultas"
          src={IMG.historyImg}
          backIcon={true}
          name={user?.name}
        />
        <RightGrid className="mx-auto p-0">
          <div className="md:max-w-[430px]">
            <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
            <HistoryList
              data={activeTab === "future" ? futureData : historyData}
              tabname={activeTab}
              getHistoryAppointments={getHistoryAppointments}
              historyAppointmentdata={historyAppointmentdata}
              setLoading={setLoading}
              loading={loading}
            />
          </div>
        </RightGrid>
      </Grid>
    </Layout>
  );
};

export default History;
