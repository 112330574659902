import { IonGrid, IonImg, IonRow } from "@ionic/react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "../../Redux/reducer";
import LeftGrid from "../../components/Grid/LeftGrid";
import RightGrid from "../../components/Grid/RightGrid";
import Layout from "../../components/Layout";
import { IMG } from "../../styles/assets";
import { navigateToRoute } from "../../utils/helper";
import RegisterMajorDependents from "./registerMajorDependents";
import RegisterMinorDependentsFormTwo from "./registerMinorDependentsFormTwo";
import RegisterMinorDependentsFormOne from "./registorMinorDependentsFormOne";

const RegisterDependents = () => {
  const { dependentType, isMinorSecondDetails } = useSelector((state: RootState) => state.dependent);
  const navigate = useNavigate();

  return (
    <Layout routeName="registerdependent">
      <IonGrid className="[&>ion-row]:w-full p-0 !items-start">
        <IonRow className="md:px-0 mx-auto w-full max-w-[1320px]">
          <LeftGrid
            title="Cadastro de dependentes"
            src={IMG.userInfoImg}
            backIcon={true}
            onBackClick={() => navigateToRoute(navigate, "/selectdependents", true)}
            ImgClassname="!max-w-full !md:mt-9 block"
          />
          <IonImg src={IMG.backIcon} className="h-6 w-6 my-6 md:hidden" onClick={() => navigateToRoute(navigate, "/selectdependents", true)} />
          <RightGrid className="md:mt-20">
            {dependentType == "major" ? (
              <RegisterMajorDependents />
            ) : isMinorSecondDetails ? (
              <RegisterMinorDependentsFormTwo />
            ) : (
              <RegisterMinorDependentsFormOne />
            )}
          </RightGrid>
        </IonRow>
      </IonGrid>

    </Layout>
  );
};
export default RegisterDependents;
